import {useState} from 'react'

import {useSelector, useDispatch} from 'react-redux';

import {contributorsUpdate} from '../../store/actions/itemAddActions';

import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    tag: {
        backgroundColor: "rgb(50, 133, 168)",
        borderRadius: 8,
        border: 0,
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        margin: 4,
        fontSize: 14,
        padding: 5,
        whiteSpace: "nowrap",
    },
})

const ContributorTag = ({id, name}) => {

    const dispatch = useDispatch()

    const classes = useStyles();

    const [visible, setVisible] = useState(true)

    const contributors = useSelector((state) => state.itemAdd.contributors)

    const onClick = (e) => {
        let array = contributors
        if (array.length > 1) {
            for (let x = 0; x < array.length; x++) {
                console.log(e.target.id)
                if (array[x].id === e.target.id) {
                    array.splice(x,1);
                }
            }
        }
        else {
            array = []
        }

        dispatch(contributorsUpdate(array))
        setVisible(false)
    }

    return (
        <>
        { visible
            ? <span className={classes.tag} id={id} onClick={onClick}>{name}</span>
            : null
        }
        </>
    )
}

export default ContributorTag;