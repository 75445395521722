import {useState, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {createUseStyles} from 'react-jss';

import DateInput from './dateInput';
import { organizationUpdate } from '../../../store/actions/itemAddActions';

import axiosInstance from '../../../axios';

const useStyles = createUseStyles({
    input: {
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "whitesmoke",
        marginTop: 3,
    },
    textArea: {
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 167,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "whitesmoke",
        "&::-webkit-scrollbar": {
            width: 5,
            height: 5,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "whitesmoke",
            "&:hover": {
                backgroundColor: "rgb(207, 205, 204)",
            },
            borderRadius: "10px",
        },
        marginTop: 3,
        resize: "none",
    },
    submitButtonDiv: {
        display: "flex",
        justifyContent: "center",
    },
    submitButton: {
        cursor: "pointer",
        backgroundColor: "rgb(47, 86, 102)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 6,
        color: "white",
        padding: 10,
        marginTop: 12,
        width: "50%",
        "&:hover": {
            backgroundColor: "rgb(83, 156, 176)",
        },
    },
    errorMessage: {
        color: "red",
        fontSize: 10,
    },
});

const NewOrganization = ({toggleAddPopupComponent}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const currentOrgs = useSelector(state => state.itemAdd.organizations)

    const [name, setName] = useState();

    const [altName, setAltName] = useState();

    const [description, setDescription] = useState();

    const [startDate, setStartDate] = useState();

    const [endDate, setEndDate] = useState();

    const [nameError, setNameError] = useState()
    
    const [descriptionError, setDescriptionError] = useState()

    const getStartDate = useCallback((startDate) => {
        setStartDate(startDate)
    });

    const getEndDate = useCallback((endDate) => {
        setEndDate(endDate)
    });

    const onNameChange = e => {
        setName(e.target.value)
        setNameError()
        try {        
            document.getElementById("orgName").style.backgroundColor = "whitesmoke";
        }
        catch{
            return null;
        }
    }

    const onAltNameChange = e => {
        setAltName(e.target.value)
    }
    
    const onDescriptionChange = e => {
        setDescription(e.target.value)
        setDescriptionError()
        try {        
            document.getElementById("orgDescription").style.backgroundColor = "whitesmoke";
        }
        catch{
            return null;
        }
    }

    async function postItem(url, data) {
        axiosInstance.post(url, JSON.stringify(data))
            .then(res => {
                console.log(res)
                const data = res.data
                console.log(data)
                let orgsArray = currentOrgs
                orgsArray.push({name: data.name, id: data.id.toString()})
                dispatch(organizationUpdate(orgsArray))
                toggleAddPopupComponent('organizationsUpdated')
                return;
            })
            .catch(err => {
                console.log(err.response)
                return;
            })
    }

    const submitOrganization = () => {
        let altNameArray = []
        if (altName) {
            altNameArray = altName.split(',')
        }

        for (let i=0; i<altNameArray.length; i++) {
            altNameArray[i] = altNameArray[i].trim()
        }

        console.log(startDate, endDate)

        let dateStartInt = null
        let dateEndInt = null
        
        if (startDate) {
            if (startDate.length === 4) {
                dateStartInt = parseInt(startDate + '0000')
            }
            else if (startDate.length === 7) {
                dateStartInt = parseInt(startDate.replace("-", "") + '00')
            }
            else if (startDate.length === 10) {
                dateStartInt = parseInt(startDate.replace("-", "").replace("-", ""))
            }
        }


        if (endDate) {
            if (endDate.length === 4) {
                dateEndInt = parseInt(endDate + '0000')
            }
            else if (endDate.length === 7) {
                dateEndInt = parseInt(endDate.replace("-", "") + '00')
            }
            else if (endDate.length === 10) {
                dateEndInt = parseInt(endDate.replace("-", "").replace("-", ""))
            }
        }

        if (!name || !description) {
            if (!name) {
                setNameError("Include a name for your organization.")
                try {        
                    document.getElementById("orgName").style.backgroundColor = "rgb(245, 81, 66)";
                }
                catch{
                    return null;
                }
            }
            if (!description) {
                setDescriptionError("Add a description of your organization")
                try {        
                    document.getElementById("orgDescription").style.backgroundColor = "rgb(245, 81, 66)";
                }
                catch{
                    return null;
                } 
            }
            return;
        }

        const postData = {
            name: name,
            altname: altNameArray,
            date_opened: dateStartInt,
            date_closed: dateEndInt,
            description: description,
        }

        const domain = 'https://www.bayfieldhistoricalwebmap.ca'

        postItem(`${domain}/api/organizations/`, postData)
    }

        // async function postItem(url='', data={}) {
        //     const response = await fetch(url, {
        //         method: 'POST',
        //         mode: 'cors',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Authorization': `JWT ${localStorage.getItem('access')}`,
        //         },
        //         body: JSON.stringify(data)
        //     });
        //     const resStatus = response.status
        //     const resJSON = response.json()
        //     return([resStatus, resJSON])
        // }

        // postItem(`${domain}/api/organizations/`, postData)
        //     .then(data => {
        //         if (data[0]===201) {
        //             data[1].then(data => {
        //                 console.log(data)
        //                 let orgsArray = currentOrgs
        //                 orgsArray.push({name: data.name, id: data.id.toString()})
        //                 dispatch(organizationUpdate(orgsArray))
        //                 toggleAddPopupComponent('organizationsUpdated')
        //             })
        //         }
            //     else if (data[0]===401) {
            //         alert("You do not have permission to add a new organization!")
            //     }
            //     else {
            //         data[1].then(data => {
            //             console.log(data)
            //             if (data.description) {
            //                 setDescriptionError("Add a description of the organization.")
            //                 try {        
            //                     document.getElementById("orgDescription").style.backgroundColor = "rgb(245, 81, 66)";
            //                 }
            //                 catch{
            //                     return null;
            //                 }
            //             }
            //             if (data.name) {
            //                 setNameError("Include the official name of the organization.")
            //                 try {        
            //                     document.getElementById("orgName").style.backgroundColor = "rgb(245, 81, 66)";
            //                 }
            //                 catch{
            //                     return null;
            //                 }
            //             }
            //         })
            //     }
            // })

    return (
        <>
            <label for="orgName">Official Name</label>
            <div className={classes.errorMessage}>{nameError}</div>
            <input id="orgName" className={classes.input} value={name} onChange={onNameChange} autocomplete="off"></input>
            <label for="altName">Alternate Name</label>
            <input id="altName" className={classes.input} value={altName} onChange={onAltNameChange} autocomplete="off"></input>
            <DateInput includeRange={false} getStartDate={getStartDate} getEndDate={getEndDate} startDateLabel="Date Opened" endDateLabel="Date Closed" />
            <label for="orgDescription">Organization Description</label>
            <div className={classes.errorMessage}>{descriptionError}</div>
            <textarea className={classes.textArea} id="orgDescription" value={description} rows="9" cols="30" onChange={onDescriptionChange}></textarea>
            <div className={classes.submitButtonDiv}>
                <span className={classes.submitButton} onClick={submitOrganization}>Add Organization</span>
            </div>
        </>
    )
}

export default NewOrganization