import MapItem from './mapItem';
import BaseToggler from './baseToggler';

import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    // legendDiv: {
        
    // },
    legendHeading: {
        fontSize: 14,
        fontWeight: "150",
        color: "black",
    },
    mapItemDiv: {
        marginBottom: 20,
        backgroundColor: "rgba(218, 224, 235, 0.9)",
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10,
        borderRadius: 8,
        padding: 10,
        paddingBottom: 15,
    }
});

const Legend = () => {

    const classes = useStyles();

    return (
        <div className={classes.legendDiv}>
            <div className={classes.mapItemDiv}>
                <p className={classes.legendHeading}>Digitized Historical Maps</p>
                <MapItem name='"Town and Estate of Bayfield" (Town) - 1835' description="Copy (ca. 1941) of a plan of the town of Bayfield and surrounding areas ca. 1835." />
                <MapItem name='"Town and Estate of Bayfield" (Surroundings) - 1835' description="Copy (ca. 1941) of a plan of the town of Bayfield and surrounding areas ca. 1835." />
                <MapItem name="Bayfield Town Plan - 1856" description="Survey of the Village of Bayfield ordered by Malcolm Cameron and completed by T.N. Molesworth (ca. 1856)." />
                {/* <MapItem name="Historical Map of Huron County - 1862" /> */}
                <MapItem name="Belden Town Map - 1879" description="Map of Bayfield that appears in H. Belden and Co.'s Historical Atlas of Huron County (ca. 1879)." />
                <MapItem name="Bayfield Cemetery Plan - 1969" description="Plan that depicts the layout of the Bayfield Cemetery." />
                
            </div>
            <div className={classes.mapItemDiv}>
                <p className={classes.legendHeading}>Base Maps and Imagery</p>
                <BaseToggler />
            </div>
        </div>
    )
}

export default Legend