import { useState, useEffect } from 'react';

import {useSelector, useDispatch} from 'react-redux';

import {collectionChange} from '../../store/actions/queryActions';

import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    input: {
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "white",
    },
    text: {
        color: "white",
        marginBottom: 5,
        display: "flex",
        justifyContent: "center",
    }
});

const CollectionFilter = ({detectCollectionUpdate}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const collection = useSelector((state) => state.query.collection)

    const [ collections, setCollections ] = useState([])

    const [ collectionsLoading, setCollectionsLoading ] = useState(true)

    const onCollectionChange = (e) => {
        dispatch(collectionChange(e.target.value))
        detectCollectionUpdate()
    }

    const domain = 'https://www.bayfieldhistoricalwebmap.ca'

    useEffect( () => {
        fetch(`${domain}/api/collections/`)
        .then(response => response.json())
        .then( (fetchedFeatures) => {
            setCollections(fetchedFeatures)
            setCollectionsLoading(false)
        })
    },[])

    return (
        <>
            { collectionsLoading
                ? <p>Loading Collections...</p>
                : <>
                    <label className={classes.text} htmlFor="collection">Collection</label>
                    <select id="collection" className={classes.input} value={collection} onChange={onCollectionChange}>
                        {collections 
                        ? 
                            <>
                                <option value=''> </option>
                                {collections.map((collectionName)=> {
                                    return(
                                        <option id={collectionName.id} key={collectionName.id} value={collectionName.id}>{collectionName.collection_name}</option>
                                    );
                                })}
                            </>
                        : null }
                    </select>
                </>
            }
        </>
    )
}

export default CollectionFilter