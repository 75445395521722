import { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { toggleBaseMap } from '../../../store/actions/mapActions';

import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    itemHeading: {
        fontSize: 12,
        fontWeight: "150",
        color: "black",
        marginTop: 2,
        cursor: "pointer",
    },
    itemHeadingDiv: {
        cursor: "pointer",
        paddingTop: 7,
    },
});

const BaseToggler = () => {

    const classes = useStyles();

    // const [baseMap, setBaseMap] = useState("Huron County Imagery - 2015")

    const baseMap = useSelector(state => state.map.baseMap)

    const [imagery2015, setImagery2015] = useState(true)

    const [imagery2010, setImagery2010] = useState(false)

    const [imagery2006, setImagery2006] = useState(false)

    const [OSM, setOSM] = useState(false)

    useEffect( () => {
        if (baseMap === "Huron County Imagery - 2015") {
            setImagery2015(true)
            setImagery2010(false)
            setImagery2006(false)
            setOSM(false)
        }
        else if (baseMap === "Huron County Imagery - 2010") {
            setImagery2015(false)
            setImagery2010(true)
            setImagery2006(false)
            setOSM(false)
        }
        else if (baseMap === "Huron County Imagery - 2006") {
            setImagery2015(false)
            setImagery2010(false)
            setImagery2006(true)
            setOSM(false)
        }
        else if (baseMap === "OpenStreetMap") {
            setImagery2015(false)
            setImagery2010(false)
            setImagery2006(false)
            setOSM(true)
        }
    }, [baseMap])

    const dispatch = useDispatch();

    const onClick = ((e) => {
        dispatch(toggleBaseMap(e.target.id))
        // setBaseMap(e.target.id)
    })

    return(
        <div>
            <div className={classes.itemHeadingDiv}>
                <input type="radio" name="baseMaps" id="Huron County Imagery - 2015" defaultChecked={imagery2015} onClick={onClick}/>
                <label className={classes.itemHeading} for="Huron County Imagery - 2015">Huron County Aerial Imagery - 2015</label>
            </div>
            <div className={classes.itemHeadingDiv}>
                <input type="radio" name="baseMaps" id="Huron County Imagery - 2010" defaultChecked={imagery2010} onClick={onClick}/>
                <label className={classes.itemHeading} for="Huron County Imagery - 2010">Huron County Aerial Imagery - 2010</label>
            </div>
            <div className={classes.itemHeadingDiv}>
                <input type="radio" name="baseMaps" id="Huron County Imagery - 2006" defaultChecked={imagery2006} onClick={onClick}/>
                <label className={classes.itemHeading} for="Huron County Imagery - 2006">Huron County Aerial Imagery - 2006</label>
            </div>
            <div className={classes.itemHeadingDiv}>
                <input type="radio" name="baseMaps" id="OpenStreetMap" defaultChecked={OSM} onClick={onClick}/>
                <label className={classes.itemHeading} for="OpenStreetMap">OpenStreetMap</label>
            </div>
        </div>
    )
}

export default BaseToggler