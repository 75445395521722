import React, { useState } from 'react';

import { Link, Redirect } from 'react-router-dom';
import {createUseStyles} from 'react-jss';

import { useSelector, useDispatch } from "react-redux";
import { login } from "../../store/actions/authActions";

import InputField from '../../components/InputField';
import SimpleHeader from '../../headers/SimpleHeader';


const useStyles = createUseStyles({
    myPageDiv: {
        display: "flex",
        justifyContent: "center",
        // height: "68vh",
    },
    myFormDiv: {
        width: 420,
        backgroundColor: "rgb(66, 132, 150)",
        padding: "4px 12px 12px 12px",
        borderRadius: 8,
        display: "flex",
        flexDirection: "column"
    },
    myFormTitle: {
        color: 'white'
    },
    myTitleDiv: {
        justifyContent: 'center',
        align: 'center',
        display: 'flex',
        height: 40,
        fontSize: "1.25rem"
    },
    myButton: {
        width: "40%",
        backgroundColor: "white",
        color: "black",
        border: 0,
        borderRadius: 50,
        fontWeight: 'bold',
        transition: "all 0.2s ease-in-out",
        '&:hover': {
            color: "white",
            backgroundColor: "rgb(66, 132, 150)",
            transition: "all 0.2s ease-in-out"
        },
        cursor: "pointer",
    },
    registerDiv: {
        display: "flex",
        justifyContent: "center",
        paddingBottom: "1rem"
    },
    registerText: {
        color: "white",
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        padding: "0 1rem",
        height: "100%",
        cursor: "pointer",
        fontSize: "0.7rem",
        "&:hover": {
            transition: "all 0.2s ease-in-out",
            fontSize: "1rem"
        }
    },
    myError: {
        color: 'rgb(255, 153, 153)',
        fontStyle: 'italic',
        textAlign: "center",
    },
})

export default function Login() {

    const classes = useStyles();

    const dispatch = useDispatch();

    const errors = useSelector(state => state.auth.errors);

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const inputRefs = React.useRef(
        [React.createRef(), React.createRef()]
    );

    const initialFormData = Object.freeze({
        email: '',
        password: '',
    });

    const [formData, updateFormData] = useState(initialFormData);

    const handleChange = (name, value) => {
        updateFormData({
            ...formData,
            [name]: value.trim(),
        });
    };

    // console.log(formData);

    const handleSubmit = (e) => {
        e.preventDefault();

        //Error checks
        let isValid = true;

        for (let i=0; i < inputRefs.current.length; i++) {
            const valid = inputRefs.current[i].current.validate()
            if (!valid) {
                isValid = false;
            }
        }

        if (!isValid) {
            return
        }

        dispatch(login(formData.email, formData.password))
    }

    if (isAuthenticated === true) {
        return <Redirect to='/' />
    }

    return (
        <div className={classes.fullPageDiv}>
            <div>
                <SimpleHeader />
            </div>
            <div className={classes.myPageDiv}>
                <div className={classes.myFormDiv}>
                    <div className={classes.myTitleDiv}>
                        <h2 className={classes.myFormTitle}>Log In</h2>
                    </div>
                    <br/>
                    {errors === "Invalid login!" ? <p className={classes.myError}>Invalid login credentials! Re-enter your username and/or password.</p> : <br/>}
                    <form onSubmit={handleSubmit}>
                        <InputField 
                            ref={inputRefs.current[0]}
                            name="email"
                            label="Email:"
                            onChange={handleChange}
                            validation={"required|email"}
                        />
                        <InputField 
                            ref={inputRefs.current[1]}
                            name="password"
                            label="Password:"
                            onChange={handleChange}
                            type="password"
                            validation={"required|min:8|max:50"}
                        />
                        <br></br>
                        <div className={classes.myTitleDiv}>
                            <button className={classes.myButton} type="submit" onClick={handleSubmit}>Log In</button>
                        </div>     
                    </form>
                    <br></br>
                    <div className={classes.registerDiv}>
                        <Link to='/register' className={classes.registerText}>Register</Link>
                    </div>
                    <div className={classes.registerDiv}>
                        <Link to='/reset-password' className={classes.registerText}>Forgot your Password?</Link>
                    </div>
                </div>
            </div>
            <br />
            <br />
        </div>
    )
}