import React, { useState } from 'react';

import { Redirect } from 'react-router-dom';
import {createUseStyles} from 'react-jss';

import { useDispatch } from "react-redux";
import { verify } from "../../store/actions/authActions";

import SimpleHeader from '../../headers/SimpleHeader';


const useStyles = createUseStyles({
    myPageDiv: {
        display: "flex",
        justifyContent: "center",
    },
    myFormDiv: {
        width: 420,
        backgroundColor: "rgb(66, 132, 150)",
        padding: "4px 12px 12px 12px",
        borderRadius: 8,
        display: "flex",
        flexDirection: "column"
    },
    myFormTitle: {
        color: 'white'
    },
    myTitleDiv: {
        justifyContent: 'center',
        align: 'center',
        display: 'flex',
        height: 40,
        fontSize: "1.25rem"
    },
    myButton: {
        width: "40%",
        backgroundColor: "white",
        color: "black",
        border: 0,
        borderRadius: 50,
        fontWeight: 'bold',
        transition: "all 0.2s ease-in-out",
        '&:hover': {
            color: "white",
            backgroundColor: "rgb(66, 132, 150)",
            transition: "all 0.2s ease-in-out"
        },
        cursor: "pointer",
    },
    registerDiv: {
        display: "flex",
        justifyContent: "center",
        paddingBottom: "1rem"
    },
    registerText: {
        color: "white",
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        padding: "0 1rem",
        height: "100%",
        cursor: "pointer",
        fontSize: "0.7rem",
        "&:hover": {
            transition: "all 0.2s ease-in-out",
            fontSize: "1rem"
        }
    }
})

export default function Activate({match}) {

    const classes = useStyles();

    const dispatch = useDispatch();

    const [verified, setVerified] = useState(false);

    // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const verify_account = (e) => {
        const uid = match.params.uid;
        const token = match.params.token;

        dispatch(verify(uid, token));
        setVerified(true) 
    }

    if (verified === true) {
        return <Redirect to='/' />
    }

    return (
        <div className={classes.fullPageDiv}>
            <div>
                <SimpleHeader />
            </div>
            <div className={classes.myPageDiv}>
                <div className={classes.myFormDiv}>
                    <div className={classes.myTitleDiv}>
                        <h2 className={classes.myFormTitle}>Activate</h2>
                    </div>
                    <br /><br /><br />
                    <div className={classes.myTitleDiv}>
                        <button className={classes.myButton} type="submit" onClick={verify_account}>Activate</button>
                    </div>
                </div>
            </div>
        </div>
    )
}