import { Link } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { businessZoneUpdate } from '../../../store/actions/mapActions';

import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    locationDiv: {
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 1)",
        marginRight: 0,
        marginLeft: 5,
        marginTop: 10,
        marginBottom: 10,
        padding: 12,
        borderRadius: 12,
    },
    resultHeading: {
        fontSize: 14,
        fontWeight: "150",
        color: "black",
        marginTop: 2,
    },
    resultText: {
        fontSize: 12,
        fontWeight: "150",
        color: "black",
        marginTop: 2,
    },
    addressSearchButton: {
        borderRadius: 5,
        background: "white",
        whiteSpace: "nowrap",
        color: "black",
        fontSize: 12,
        outline: "none",
        border: "none",
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        textDecoration: "none",
        height: "4vh",
        display: "flex",
        alignItems: "center",
        paddingLeft: 5,
        paddingRight: 5,
        "&:hover": {
            fontSize: 14,
            backgroundColor: "rgb(133, 133, 133)",
            color: "white",
        },
        
    },
    addressSearchButtonDiv: {
        display: "flex",
        justifyContent: "space-around"
    },
});

const BusinessResultBox = ({org}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    async function getZones(orgId) {
        const domain = 'https://www.bayfieldhistoricalwebmap.ca'
        const orgLocationRes = await fetch(`${domain}/api/organizations/location/?organization=${orgId}`)
        const orgLocationJson = await orgLocationRes.json()
        const orgLocationArray = orgLocationJson.map(feature => feature.location)
        console.log(orgLocationArray)
        if (orgLocationJson && orgLocationJson.length > 0) {
            return orgLocationArray
        }
        else {
            return [];
        }
    }

    const onClick = e => {
        getZones(e.target.id)
            .then(res => {
                dispatch(businessZoneUpdate(res))
            })
    }

    return(
        <div className={classes.locationDiv} key={org.id}>
            <h3 className={classes.resultHeading}>{org.name}</h3>
            <div className={classes.addressSearchButtonDiv}>
                <button className={classes.addressSearchButton} onClick={onClick} id={org.id}>Highlight Location(s)</button>
                <Link className={classes.addressSearchButton} to={`/org-overview/${org.id}`}>Overview</Link>
            </div>
        </div>
    )

}

export default BusinessResultBox
