import * as authActionTypes from '../actions/authActionTypes';

const initialState = {
    access: localStorage.getItem('access'),
    refresh: localStorage.getItem('refresh'),
    isAuthenticated: null,
    user: null,
    errors: null,
    registeredSuccess: false,
}

const authReducer = (state=initialState, action) => {
    const { type, payload } = action;

    switch(type) {
        case authActionTypes.AUTHENTICATED_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
            }
        case authActionTypes.LOGIN_SUCCESS: 
            localStorage.setItem('access', payload.access);
            localStorage.setItem('refresh', payload.refresh);
            return {
                ...state,
                isAuthenticated: true,
                access: payload.access,
                refresh: payload.refresh,
                errors: null,
            }
        case authActionTypes.REGISTER_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                pending: false,
                registeredSuccess: true,
                errors: null,
            }
        case authActionTypes.REGISTER_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                errors: payload,
                registeredSuccess: false,
            }
        case authActionTypes.USER_LOADED_SUCCESS:
            return {
                ...state,
                user: payload,
            }
        case authActionTypes.AUTHENTICATED_FAIL:
            return {
                ...state,
                isAuthenticated:false,
            }
        case authActionTypes.USER_LOADED_FAIL:
            return {
                ...state,
                user: null,
            }
        case authActionTypes.LOGIN_FAIL:
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            return {
                ...state,
                access: null,
                refresh: null,
                isAuthenticated: false,
                user: null,
                errors: "Invalid login!",
            }
        case authActionTypes.LOGOUT:
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            return {
                ...state,
                access: null,
                refresh: null,
                isAuthenticated: false,
                user: null,
            }
        case authActionTypes.SET_PENDING:
            return {
                ...state,
                registeredSuccess: false,
            }
        case authActionTypes.PASSWORD_RESET_SUCCESS:
            return {
                ...state
            }
        case authActionTypes.PASSWORD_RESET_FAIL:
            return {
                ...state
            }            
        case authActionTypes.PASSWORD_RESET_CONFIRM_SUCCESS:
            return {
                ...state
            }
        case authActionTypes.PASSWORD_RESET_CONFIRM_FAIL:
            return {
                ...state
            }
        case authActionTypes.ACTIVATION_SUCCESS:
            return {
                ...state
            }
        case authActionTypes.ACTIVATION_FAIL:
            return {
                ...state
            }            
        default:
            return state;
    }
}

export default authReducer;

// const authStart = (state, action) => {
//     return updateObject(state, {
//         error: null,
//         loading: true
//     });
// }

// const authSuccess = (state, action) => {
//     return updateObject(state, {
//         access_token: action.access_token,
//         refresh_token: action.refresh_token,
//         error: null,
//         loading: false
//     })
// }

// const authFail = (state, action) => {
//     return updateObject(state, {
//         error: action.error,
//         loading: false
//     })
// }

// const authLogout = (state, action) => {
//     return updateObject(state, {
//         access_token: null,
//         refresh_token: null,
//     })
// }

// const authReducer = (state=initialState, action) => {
//     const { type, payload } = action;

//     switch(type) {
//         case authActionTypes.AUTH_START: return authStart(state, action);
//         case authActionTypes.AUTH_SUCCESS: return authSuccess(state, action);
//         case authActionTypes.AUTH_FAIL: return authFail(state, action);
//         case authActionTypes.AUTH_LOGOUT: return authLogout(state, action);
//         default:
//             return state;
//     }
// }

// export default authReducer;