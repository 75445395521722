 import { createUseStyles } from "react-jss";

// import PinchZoomPan from "react-image-zoom-pan";

import ItemDetail from './itemDetail';

const useStyles = createUseStyles({
    popupModal: {
        position: "fixed",
        overflow: "hidden",
        zIndex: 998,
        display: "block",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,0.4)",
    },
    popup: {
        backgroundColor: "#fefefe",
        borderRadius: 10,
        padding: 10,
        marginLeft: "5%",
        // marginRight: "5%",
        marginTop: "10vh",
        marginBottom: "10vh",
        width: "90%",
        // height: "45%",
        display: "flex",
        flexDirection: "column",
        alignItems: "top",
        justifyContent: "center",
    },
    closeButtonDivDiv: {
        display: "flex",
        justifyContent: "flex-end",
    },
    closeButtonDiv: {
        width: 25,
        height: 25,
        top: 10,
        right: 10,
        cursor: "pointer",
        "&:hover": {
            transform: "scale(1.5)",
            transition: "0.2s",
        },
    },
    contentDiv: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        // overflowY: "scroll",
        // overflowX: "hidden",
        // "&::-webkit-scrollbar": {
        //     width: 5,
        // },
        // "&::-webkit-scrollbar-track": {
        //     borderRadius: "10px",
        // },
        // "&::-webkit-scrollbar-thumb": {
        //     background: "rgb(191, 191, 191)",
        //     opacity: 0,
        //     borderRadius: "10px",
        // },
    },
})

const ItemOverviewPopup = ({id, onCloseClick}) => {
    const classes = useStyles();

    return(
        <div className={classes.popupModal}>
            <div className={classes.popup}>
                <div className={classes.closeButtonDivDiv}>
                    <div onClick={onCloseClick} id="none" className={classes.closeButtonDiv}>
                        <svg id="none" onClick={onCloseClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="rgb(242, 73, 51)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line id="none" x1="18" y1="6" x2="6" y2="18"></line><line id="none" x1="6" y1="6" x2="18" y2="18"></line></svg>
                    </div>
                </div>
                <div className={classes.contentDiv}>
                    <ItemDetail id={id} />
                </div>
            </div>
        </div>
    )
}

export default ItemOverviewPopup