import { useParams } from "react-router"

import PublicHeader from "../headers/PublicHeader";
import ZoneOverview from "../components/zoneOverview"

const ZoneOverviewWrapper = () => {
    const { id } = useParams();

    return(
        <>
            <PublicHeader />
            <ZoneOverview id={id} />
        </>
    )
}

export default ZoneOverviewWrapper