import { useState, useEffect } from 'react';

import {useSelector} from 'react-redux';

import { Link } from 'react-router-dom';

import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    searchBarTopDiv: {
        width: "100%",
        marginBottom: 5,
    },
    searchBar: {
        width: "100%",
        height: "100%",
        marginLeft: "2%",
        marginRight: "2%",
        display: "grid",
        gridTemplateColumns: "72% 10% 10%",
        gridColumnGap: "2%",
    },
    input: {
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: 8,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "white",
    },
    searchBarButton: {
        cursor: "pointer",
        backgroundColor: "rgb(47, 86, 102)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 6,
        "&:hover": {
            backgroundColor: "rgb(83, 156, 176)",
        },
    },
    searchIcon: {
        height: "3.5vh",
    },
    headerButtonLink: {
        borderRadius: 6,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgb(47, 86, 102)",
        whiteSpace: "nowrap",
        color: "white",
        fontSize: 15,
        outline: "none",
        border: "none",
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "rgb(63, 121, 145)",
        },
    },
})

const SearchBar = ({searchUpdate, searchTerm}) => {

    const classes = useStyles();

    const [newSearchTerm, setNewSearchTerm] = useState('')

    const auth = useSelector(state => state.auth.isAuthenticated)

    const searchFunction = () => {
        // const search = document.getElementById("search").value
        searchUpdate(newSearchTerm)
    }

    const onChange = e => {
        setNewSearchTerm(e.target.value)
    }

    useEffect(() => {
        setNewSearchTerm(searchTerm)
    }, [searchTerm])

    const onClick = () => {
        searchFunction()
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            searchFunction()
        }
    }

    return (
        <div className={classes.searchBarTopDiv}>
            <div className={classes.searchBar}>
                <input id="search" className={classes.input} autoComplete="off" onChange={onChange} onKeyDown={onKeyDown} value={newSearchTerm} />
                <div className={classes.searchBarButton} onClick={onClick}>
                    <svg onClick={onClick} className={classes.searchIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><path fill="white" d="M63.22,59.45,47.3,43.53a26.7,26.7,0,1,0-3.77,3.77L59.45,63.22a2.67,2.67,0,0,0,3.77-3.77ZM26.67,48A21.34,21.34,0,1,1,48,26.67,21.36,21.36,0,0,1,26.67,48Z"/></svg>
                </div>
                { auth ?
                    <Link to="/add-item" className={classes.headerButtonLink}>Add Item</Link>
                    : <Link to="/login" className={classes.headerButtonLink}>Log In</Link>
                }
            </div>
        </div>
    )

}

export default SearchBar;