import React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import disableScroll from 'disable-scroll'
import BHSLogo from './logo.svg';
import { Link } from 'react-router-dom';
import {createUseStyles} from 'react-jss';

import { useSelector, useDispatch } from "react-redux";
import { zoneUpdate } from "../store/actions/mapActions";
import { logout } from "../store/actions/authActions";
import  { connect } from 'react-redux';

const useStyles = createUseStyles({
    headerDiv: {
        position: "sticky",
        display: "grid",
        gridTemplateColumns: "15% 70% 15%",
        // backgroundColor: "rgba(176, 54, 5, 0.8)",
        backgroundColor: "rgb(66, 132, 150)",
        height: "12vh",
        padding: "0.2rem",
        paddingLeft: "2rem",
        paddingRight: "2rem",
        alignItems: "center",
        justifyContent: "space-around",
        zIndex: 10,
        marginBottom: 0
    },
    headerMenuDiv: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "7vh",
        width: "100%",
    },
    headerMenu: {
        display: "flex",
        alignItems: "center",
        listStyle: "none",
        justifyContent: "space-around",
        textAlign: "center",
        marginRight: 22,
        width: "100%",
    },
    headerItem: {
        height: "3 rem",
        "&:hover": {
            fontSize: "1.5 rem",
            transition: "all 0.2s ease-in-out",
        },
    },
    headerLinks: {
        color: "white",
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        padding: "0 1rem",
        height: "100%",
        cursor: "pointer",
        "&:hover": {
            transition: "all 0.2s ease-in-out",
            fontSize: "1.5em"
        }
    },
    headerButtonDiv: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    headerButtonLink: {
        borderRadius: 50,
        background: "white",
        whiteSpace: "nowrap",
        padding: "10px 22px",
        color: "black",
        fontSize: 15,
        fontWeight: "bold",
        outline: "none",
        border: "none",
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        textDecoration: "none",
        // "&:hover": {
        //     transition: "all 0.2s ease-in-out",
        //     background: "rgba(176, 54, 5, 0.8)",
        //     color: "whitesmoke"
        // }
    },
    headerLogo: {
        height: "3.5rem",
    },
    headerLogoDiv: {
        position: "relative",
    },
    registerLink: {
        color: "white",
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        padding: "0 1rem",
        height: "100%",
        cursor: "pointer",
        fontSize: "0.75rem"
    },
});

const PublicHeader = ({logout}) => {

    const classes = useStyles();

    const auth = useSelector((state) => state.auth.isAuthenticated);

    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(zoneUpdate(null))
    }

    const history = useHistory();

    useEffect(() => {
        return history.listen((location) => {
            disableScroll.options = {
                    authorizedInInputs: [32, 37, 38, 39, 40],
                    disableKeys: true,
                    disableScroll: false,
                    disableWheel: true,
                    keyboardKeys: [32, 33, 34, 35, 36, 37, 38, 39, 40],
                }
            // if (location.pathname === '/add-item') {
            //     disableScroll.on();
            // }
            if (location.pathname !== '/add-item') {
                disableScroll.off();
            }
        }) 
     },[history])

    return (
        <div className={classes.headerDiv} id="top">
            <div className={classes.headerLogoDiv}>
                <Link to="/"><img className={classes.headerLogo} src={BHSLogo} alt="Bayfield Historical"/></Link>
            </div>
            <div className={classes.headerMenuDiv}>
                <ul className={classes.headerMenu}>
                    <li className={classes.headerItem}>
                        <Link to="/map" className={classes.headerLinks} onClick={onClick}>Map</Link>
                    </li>
                    <li className={classes.headerItem}>
                        <Link to="/collection" className={classes.headerLinks}>Collection</Link>
                    </li>
                    <li className={classes.headerItem}>
                        <a href='http://www.bayfieldhistorical.ca' className={classes.headerLinks}>Main Website</a>
                    </li>
                    <li className={classes.headerItem}>
                        <a href="https://www.canadahelps.org/en/charities/the-bayfield-historical-society/" className={classes.headerLinks}>Donate</a>
                    </li>
                    <li className={classes.headerItem}>
                    <a target="_blank" href="https://www.youtube.com/watch?v=jjZhFrXhwbM&list=PLqDq10LRffrlq4AsroUmxMG0hHix9QXgH" className={classes.headerLinks}>Tutorial</a>
                    </li>
                </ul>
            </div>
            <div className={classes.headerButtonDiv}>
                {auth ? 
                    <Link to="/" className={classes.headerButtonLink} onClick={logout}>Log Out</Link>
                :
                <>
                    <Link to="/login" className={classes.headerButtonLink}>Log In</Link>
                    {/* <Link to="/register" className={classes.registerLink}>Register</Link> */}
                </>    
                } 
            </div>
        </div>
    )
}

export default connect(null, {logout})(PublicHeader);