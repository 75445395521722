import { useState, useEffect } from 'react';

import {useSelector, useDispatch} from 'react-redux';

import {collectionUpdate} from '../../../store/actions/itemAddActions';

import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    input: {
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "white",
        marginTop: 3,
    },
});

const ItemCollection = ({refresh}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const collection = useSelector((state) => state.itemAdd.collection)

    const [ collections, setCollections ] = useState([])

    const [ collectionsLoading, setCollectionsLoading ] = useState(true)

    const onCollectionChange = (e) => {
        dispatch(collectionUpdate(e.target.value))
    }

    const domain = 'https://www.bayfieldhistoricalwebmap.ca'

    useEffect( () => {
        if (refresh === 'collectionUpdated') {
            fetch(`${domain}/api/collections/`)
            .then(response => response.json())
            .then( (fetchedFeatures) => {
                setCollections(fetchedFeatures)
                setCollectionsLoading(false)
            })
        }
    },[refresh])

    useEffect( () => {
        fetch(`${domain}/api/collections/`)
        .then(response => response.json())
        .then( (fetchedFeatures) => {
            setCollections(fetchedFeatures)
            setCollectionsLoading(false)
        })
    },[])

    return (
        <>
            { collectionsLoading
                ? <p>Loading Collections...</p>
                : <>
                    <label htmlFor="collection">Collection</label>
                    <select id="collection" className={classes.input} value={collection ? collection : ''} onChange={onCollectionChange}>
                        {collections 
                        ? 
                            <>
                                <option value=''> </option>
                                {collections.map((collectionName)=> {
                                    return(
                                        <option value={collectionName.id} id={collectionName.id} key={collectionName.id}>{collectionName.collection_name}</option>
                                    );
                                })}
                            </>
                        : null }
                    </select>
                </>
            }
        </>
    )
}

export default ItemCollection