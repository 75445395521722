import {useState, useCallback} from 'react';

import { useSelector, useDispatch } from 'react-redux'

import { contributorsUpdate, peopleUpdate, creatorUpdate } from '../../../store/actions/itemAddActions';

import {createUseStyles} from 'react-jss';

import axiosInstance from '../../../axios';

import DateInput from './dateInput';
import PeopleInput from './peopleInput';
import SingleImageInput from './singleImageInput';

const useStyles = createUseStyles({
    input: {
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "whitesmoke",
        marginTop: 3,
    },
    textArea: {
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 167,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "whitesmoke",
        "&::-webkit-scrollbar": {
            width: 5,
            height: 5,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "whitesmoke",
            "&:hover": {
                backgroundColor: "rgb(207, 205, 204)",
            },
            borderRadius: "10px",
        },
        marginTop: 3,
        resize: "none",
    },
    submitButtonDiv: {
        display: "flex",
        justifyContent: "center",
    },
    submitButton: {
        cursor: "pointer",
        backgroundColor: "rgb(47, 86, 102)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 6,
        color: "white",
        padding: 10,
        marginTop: 12,
        width: "50%",
        "&:hover": {
            backgroundColor: "rgb(83, 156, 176)",
        },
    },
    errorMessage: {
        color: "red",
        fontSize: 10,
    },
});

const NewPerson = ({toggleAddPopupComponent, peopleOptions, peopleLoading, togglePeopleLoading, role}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const currentPeople = useSelector(state => state.itemAdd.people)

    const currentContributors = useSelector(state => state.itemAdd.contributors)

    const [firstName, setFirstName] = useState();
    const [firstNameError, setFirstNameError] = useState();

    const onFirstNameChange = e => {
        setFirstName(e.target.value)
        setFirstNameError()
        try {        
            document.getElementById("firstName").style.backgroundColor = "whitesmoke";
        }
        catch{
            return null;
        }
    }

    const [middleName, setMiddleName] = useState();

    const onMiddleNameChange = e => {
        setMiddleName(e.target.value)
    }

    const [lastName, setLastName] = useState();
    const [lastNameError, setLastNameError] = useState();

    const onLastNameChange = e => {
        setLastName(e.target.value)
        setLastNameError()
        try {        
            document.getElementById("lastName").style.backgroundColor = "whitesmoke";
        }
        catch{
            return null;
        }
    }

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const getStartDate = useCallback((startDate) => {
        setStartDate(startDate)
    });

    const getEndDate = useCallback((endDate) => {
        setEndDate(endDate)
    });

    const [image, setImage] = useState();

    const getImage = useCallback((image) => {
        setImage(image)
    })

    const [sex, setSex] = useState();

    const onSexChange = e => {
        setSex(e.target.value)
    }

    const [description, setDescription] = useState();

    const onDescriptionChange = e => {
        setDescription(e.target.value)
    }

    const [parents, setParents] = useState([]);

    const getPeople = useCallback((people) => {
        setParents(people)
    });

    const [spouses, setSpouses] = useState([])

    const getSpouses = useCallback((people) => {
        setSpouses(people)
    });

    const [grave, setGrave] = useState();

    const onGraveChange = e => {
        setGrave(e.target.value)
    }

    const [ancestry, setAncestry] = useState();

    const onAncestryChange = e => {
        setAncestry(e.target.value)
    }
    
    //Add onchange for people, etc.

    async function postItem(url, data, parentIds) {
        const domain = 'https://www.bayfieldhistoricalwebmap.ca'
        axiosInstance.post(url, JSON.stringify(data))
            .then(res => {
                console.log(res)
                const data = res.data
                console.log(data)
                const graveData = {
                    entry: grave,
                    person: data.id,
                }
                if (grave) {
                    axiosInstance.post(`${domain}/api/graves/`, graveData)
                        .then(data => {
                            console.log(data)
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
                if (image) {
                    let formData = new FormData()
                    formData.append('image', image)
                    formData.append('person', data.id)
                    axiosInstance.defaults.headers['Content-Type'] = 'multipart/form-data'
                    axiosInstance.post(`${domain}/api/people/images/create/`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
                        .then(data => {
                            console.log(data)
                            toggleAddPopupComponent('peopleUpdated')
                            togglePeopleLoading()
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
                if (parentIds) {
                    parentIds.map(id => {
                        const parentData = {
                            person1: id,
                            person2: data.id,
                            relationship_type: 'parent',
                            description: '',
                        }
                        axiosInstance.post(`${domain}/api/people/relationships/`, parentData)
                            .then(data => {
                                console.log(data)
                            })
                            .catch(err => {
                                console.log(err)
                            })
                        return null;
                    })
                }
                let peopleArray = []
                // console.log(role)
                const newName = data.middle_name ? data.first_name + ' ' + data.middle_name + ' ' + data.surname : data.first_name + ' ' + data.surname
                if (role==='creator') {
                    dispatch(creatorUpdate({name: newName, id: data.id.toString()}))
                }
                else if (role==='contributor') {
                    peopleArray = currentContributors
                    peopleArray.push({name: newName, id: data.id.toString()})
                    console.log(peopleArray)
                    dispatch(contributorsUpdate(peopleArray))
                }
                else if (role==='person') {
                    peopleArray = currentPeople
                    peopleArray.push({name: newName, id: data.id.toString()})
                    dispatch(peopleUpdate(peopleArray))
                }
                
                toggleAddPopupComponent('peopleUpdated')
                togglePeopleLoading()
            })
            .catch(err => {
                console.log(err.response)
                return;
            })
    }

    const submitPerson = () => {

        // let formData = null
        //Format dates
        let dateStartInt = null
        let dateEndInt = null
        
        if (startDate) {
            if (startDate.length === 4) {
                dateStartInt = parseInt(startDate + '0000')
            }
            else if (startDate.length === 7) {
                dateStartInt = parseInt(startDate.replace("-", "") + '00')
            }
            else if (startDate.length === 10) {
                dateStartInt = parseInt(startDate.replace("-", "").replace("-", ""))
            }
        }


        if (endDate) {
            if (endDate.length === 4) {
                dateEndInt = parseInt(endDate + '0000')
            }
            else if (endDate.length === 7) {
                dateEndInt = parseInt(endDate.replace("-", "") + '00')
            }
            else if (endDate.length === 10) {
                dateEndInt = parseInt(endDate.replace("-", "").replace("-", ""))
            }
        }

        let parentIds = []
        for (let i=0; i<parents.length; i++) {
            parentIds.push(parents[i].id)
        }

        let spousesIds = []
        for (let i=0; i<spouses.length; i++) {
            spousesIds.push(spouses[i].id)
        }

        if (!firstName || !lastName) {
            if (!firstName) {
                setFirstNameError("Include a first name.")
                try {        
                    document.getElementById("firstName").style.backgroundColor = "rgb(245, 81, 66)";
                }
                catch{
                    return null;
                }
            }
            if (!lastName) {
                setLastNameError("Include a surname.")
                try {        
                    document.getElementById("lastName").style.backgroundColor = "rgb(245, 81, 66)";
                }
                catch{
                    return null;
                }
            }
            return;
        }

        const postData = {
            first_name: firstName,
            middle_name: middleName,
            surname: lastName,
            date_of_birth: dateStartInt,
            date_of_death: dateEndInt,
            sex: sex,
            biography: description,
            spouses: spousesIds,
            family_tree: ancestry,
        }

        // async function postItem(url='', data={}) {
        //     const response = await fetch(url, {
        //         method: 'POST',
        //         mode: 'cors',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Authorization': `JWT ${localStorage.getItem('access')}`,
        //         },
        //         body: JSON.stringify(data)
        //     });
        //     const resStatus = response.status
        //     const resJSON = response.json()
        //     return([resStatus, resJSON])
        // }

        const domain = 'https://www.bayfieldhistoricalwebmap.ca'
        
        postItem(`${domain}/api/people/`, postData, parentIds)
            // .then(data => {
            //     if (data[0]===201) {
            //         data[1].then(data => {
            //             console.log(data)
            //             const graveData = {
            //                 entry: grave,
            //                 person: data.id,
            //             }
            //             if (grave) {
            //                 postItem(`${domain}/api/graves/`, graveData)
            //                     .then(data => {
            //                         console.log(data)
            //                     })
            //                     .catch(err => {
            //                         console.log(err)
            //                     })
            //             }
            //             if (image) {
            //                 formData = new FormData()
            //                 formData.append('image', image)
            //                 formData.append('person', data.id)
            //                 axios.post(`${domain}/api/people/images/create/`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
            //                     .then(data => {
            //                         console.log(data)
            //                         toggleAddPopupComponent('peopleUpdated')
            //                         togglePeopleLoading()
            //                     })
            //                     .catch(err => {
            //                         console.log(err)
            //                     })
            //             }
            //             if (parentIds) {
            //                 parentIds.map(id => {
            //                     const parentData = {
            //                         person1: id,
            //                         person2: data.id,
            //                         relationship_type: 'parent',
            //                         description: '',
            //                     }
            //                     postItem(`${domain}/api/people/relationships/`, parentData)
            //                         .then(data => {
            //                             console.log(data)
                                        
            //                         })
            //                         .catch(err => {
            //                             console.log(err)
            //                         })
            //                     return null;
            //                 })
            //             }
            //             let peopleArray = []
            //             console.log(role)
            //             const newName = data.middle_name ? data.first_name + ' ' + data.middle_name + ' ' + data.surname : data.first_name + ' ' + data.surname
            //             if (role==='creator') {
            //                 dispatch(creatorUpdate({name: newName, id: data.id.toString()}))
            //             }
            //             else if (role==='contributor') {
            //                 peopleArray = currentContributors
            //                 peopleArray.push({name: newName, id: data.id.toString()})
            //                 console.log(peopleArray)
            //                 dispatch(contributorsUpdate(peopleArray))
            //             }
            //             else if (role==='person') {
            //                 peopleArray = currentPeople
            //                 peopleArray.push({name: newName, id: data.id.toString()})
            //                 dispatch(peopleUpdate(peopleArray))
            //             }
                        
            //             toggleAddPopupComponent('peopleUpdated')
            //             togglePeopleLoading()
                    // })
            //     }
            //     else if (data[0]===401) {
            //         alert("You do not have permission to add a new person!")
            //     }
            //     else {
            //         data[1].then(data => {
            //             console.log(data)
            //             if (data.first_name) {
            //                 setFirstNameError("Include a first name.")
            //                 try {        
            //                     document.getElementById("firstName").style.backgroundColor = "rgb(245, 81, 66)";
            //                 }
            //                 catch{
            //                     return null;
            //                 }
            //             }
            //             if (data.surname) {
            //                 setLastNameError("Include a surname.")
            //                 try {        
            //                     document.getElementById("lastName").style.backgroundColor = "rgb(245, 81, 66)";
            //                 }
            //                 catch{
            //                     return null;
            //                 }
            //             }
            //         })
            //     }
            // })
            
    }

    return (
        <>
            <p>Image</p>
            <SingleImageInput getImage={getImage} />
            <label for="firstName">First Name</label>
            <div className={classes.errorMessage}>{firstNameError}</div>
            <input id="firstName" className={classes.input} value={firstName} onChange={onFirstNameChange} autocomplete="off"></input>
            <label for="middleName">Middle Name</label>
            <input id="middleName" className={classes.input} value={middleName} onChange={onMiddleNameChange} autocomplete="off"></input>
            <label for="lastName">Last Name</label>
            <div className={classes.errorMessage}>{lastNameError}</div>
            <input id="lastName" className={classes.input} value={lastName} onChange={onLastNameChange} autocomplete="off"></input>
            <DateInput includeRange={false} getStartDate={getStartDate} getEndDate={getEndDate} startDateLabel="Date of Birth" endDateLabel="Date of Death" />
            <label for="sex">Sex</label>
            <select className={classes.input} id="sex" onChange={onSexChange} value={sex}>
                <option value=''> </option>
                <option value="female">Female</option>
                <option value="male">Male</option>
                <option value="other">Other</option>
            </select>
            <label for="biography">Biography</label>
            <textarea className={classes.textArea} id="biography" value={description} rows="9" cols="30" onChange={onDescriptionChange}></textarea>
            <PeopleInput peopleOptions={peopleOptions} peopleLoading={peopleLoading} getPeople={getPeople} peopleInputLabel="Parents" />
            <PeopleInput peopleOptions={peopleOptions} peopleLoading={peopleLoading} getPeople={getSpouses} peopleInputLabel="Spouses" />
            <label for="gravestoneLink">Gravestone Link</label>
            <input id="gravestoneLink" className={classes.input} value={grave} onChange={onGraveChange} autocomplete="off"></input>
            <label for="ancestryLink">Ancestry.ca Family Tree</label>
            <input id="ancestryLink" className={classes.input} value={ancestry} onChange={onAncestryChange} autocomplete="off"></input>
            <div className={classes.submitButtonDiv}>
                <span className={classes.submitButton} onClick={submitPerson}>Add Person</span>
            </div>
        </>
    )
}

export default NewPerson