import {useState, useEffect, useCallback} from 'react';

import ItemDisplay from "./searchbar/itemDisplay";
import ImagePopup from './imagePopup';

import { Redirect } from 'react-router';

import {SpinningCircles} from 'react-loading-icons';

import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    allDiv: {
        // position: "absolute",
        width: "100%",
    },
    loadingIconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // height: "100%",
        height: "50vh",
    },
})


const ItemDetail = ({id}) => {

    const classes = useStyles();

    const [item, setItem] = useState();
    const [loading, setLoading] = useState(true);

    useEffect( () => {
        const domain = 'https://www.bayfieldhistoricalwebmap.ca'
        fetch(`${domain}/api/item/${id}/`)
        .then(response => {
            console.log(response)
            if (response.status !== 200) {
                setItem("failed")
                return;
            }
            response.json().then( (fetchedFeatures) => {
                setItem(fetchedFeatures)
                setLoading(false)
                console.log(fetchedFeatures)
            })
        })
        .catch(() => {
            setItem(null)
            return(<Redirect to="/" />)
        })

    },[id])

    const [popupImage, setPopupImage] = useState();

    const togglePopup = useCallback((e) => {
        setPopupImage(e.target.getAttribute('src'))
    })

    const onCloseClick = useCallback(() => {
        setPopupImage()
    })

    return(
        <div className={classes.allDiv}>
            { popupImage ?
                <ImagePopup popupImage={popupImage} onCloseClick={onCloseClick} />
                : null
            }
            {loading === false && item ?
                <>
                    <ItemDisplay item={item} togglePopup={togglePopup} />
                </>
                : item === 'failed' ?
                <Redirect to="/" />
                : loading === true ?
                <div className={classes.loadingIconContainer}>
                    <SpinningCircles fill="rgb(66, 132, 150)" />
                </div>
                : null
            }
        </div>
    )
}

export default ItemDetail