import { useState, useEffect, useCallback } from "react"

import { useParams } from "react-router"
import { Link } from "react-router-dom"

import GeoJSON from 'ol/format/GeoJSON'

import ImagePopup from "../imagePopup"
import ItemDisplayContainer from "../itemDisplayContainer"

import PersonDisplay from "./personDisplay"

import { createUseStyles } from "react-jss"
import ParentDisplay from "./parentDisplay"
import SiblingDisplay from "./siblingDisplay"

const useStyles = createUseStyles({
    personOverviewDiv: {
        display: "block",
        height: "82vh",
        width: "100%",
    },
    overviewContainer: {
        height: "35%",
        display: "grid",
        gridTemplateColumns: "70% 30%",
        borderRadius: 10,
        backgroundColor: "rgb(113, 172, 191)",
        color: "white",
        margin: 8,
        padding: 10,
    },
    overviewInfoDiv: {
        display: "flex",
        flexDirection: "column",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            width: 5,
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "rgb(191, 191, 191)",
            opacity: 0,
            borderRadius: "10px",
        },
    },
    overviewSmallFont: {
        fontSize: 12,
        marginBottom: 3,
    },
    overviewTitleFont: {
        fontSize: 16,
        marginBottom: 3,
    },
    menuDiv: {
        display: "flex",
        justifyContent: "space-around",
        width: "100%",
        height: "8%",
    },
    menuItem: {
        width: "33%",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        backgroundColor: "rgb(113, 172, 191)",
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        marginLeft: 3,
        marginRight: 3,
        color: "white",
        "&:hover": {
            backgroundColor: "rgb(66, 132, 150)",
            fontSize: 18,
        }
    },
    menuItemSelected: {
        composes: "$menuItem",
        backgroundColor: "rgb(47, 86, 102)",
        marginLeft: 8,
    },
    menuItemRight: {
        composes: "$menuItem",
        marginRight: 8,
    },
    itemViewDiv: {
        backgroundColor: "rgb(113, 172, 191)",
        marginLeft: 8,
        marginRight: 8,
        marginBottom: 8,
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
        height: "65%",
    },
    buttonDiv: {
        height: "11%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        color: "white",
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
    },
    buttonContainerDiv: {
        zIndex: 996,
        width: "50%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgb(66, 132, 150)",
        transition: "all 0.2s ease-in-out",
        cursor: "pointer",
        borderBottom: "solid",
        borderBottomColor: "rgb(66, 132, 150)",
        "&:hover": {
            backgroundColor: "rgb(83, 156, 176)",
            borderBottomColor: "rgb(83, 156, 176)",
            fontSize: 20,
        }
    },
    buttonContainerLeft: {
        composes: "$buttonContainerDiv",
        borderBottomLeftRadius: 8,
    },
    buttonContainerRight: {
        composes: "$buttonContainerDiv",
        borderBottomRightRadius: 8,
    },
    collectionContainer: {
        display: "flex",
        flexDirection: "column",
        height: "94%",
        backgroundColor: "rgb(113, 172, 191)",
        borderTop: "solid",
        borderWidth: 6,
        borderColor: "rgb(113, 172, 191)",
    },
    menuContainer: {
        display: "flex",
        flexDirection: "column",
        height: "95%",
        backgroundColor: "rgb(113, 172, 191)",
        borderTop: "solid",
        borderWidth: 6,
        borderColor: "rgb(113, 172, 191)",
        padding: 10,
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
        marginBottom: 8,
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            width: 5,
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "rgb(191, 191, 191)",
            opacity: 0,
            borderRadius: "10px",
        },
    },
    overviewDiv: {
        display: "grid",
        gridTemplateColumns: "50% 45%",
        overflowX: "hidden",
    },
    familyTreeDiv: {
        display: "grid",
        gridTemplateRows: "30vh 40vh 30vh",
        overflowX: "hidden",
        overflowY: "hidden",
    },
    imageRow: {
        height: "100%",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
            width: 5,
        },
        "&::-webkit-scrollbar-thumb": {
            background: "rgba(200, 200, 200, 0.8)",
            borderRadius: "10px",
        },
    },
    imageMiddleRow: {
        height: "100%",
        display: "grid",
        gridTemplateColumns: "33% 33% 33%"
    },
    imageDiv: {
        display: "flex",
        justifyContent: "space-around",
        overflowX: "hidden",
        overflowY: "hidden",
        // "&::-webkit-scrollbar": {
        //     width: 5,
        // },
        // "&::-webkit-scrollbar-thumb": {
        //     background: "grey",
        //     borderRadius: "10px",
        // },
        alignItems: "center",
    },
    table: {
        borderCollapse: "collapse",
        width: "100%",
        tableLayout: "fixed",
        "& > :nth-child(2n)": {
            backgroundColor: "rgb(230, 230, 230)",
        },
        "& > :nth-child(2n-1)": {
            backgroundColor: "rgb(210, 210, 210)",
        }
    },
    tableCells: {
        paddingRight: 5,
        paddingLeft: 5,
    },
    tableLeftCells: {
        composes: "$tableCells",
        width: 80,
    },
    font: {
        fontSize: 12,
        color: "black",
    },
    fontBold: {
        composes: "$font",
        fontWeight: 500,
    },
    fontLink: {
        composes: "$font",
        textDecoration: "underline",
        cursor: "pointer",
    },
    tagDiv: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
    },
    scanViewer: {
        display: "grid",
        gridTemplateColumns: "10% 80% 10%",
        width: "100%",
        height: "60%",
    },
    progression: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        transition: "all 0.2s ease-in-out",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgb(210, 210, 210)",
        }
    },
    progLeft: {
        composes: "$progression",
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
    },
    progRight: {
        composes: "$progression",
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
    },
    svgLeft: {
        fontSize: 15,
        transform: "rotate(180deg)",
    },
    svgRight: {
        fontSize: 20,
    },
})

const PersonOverview = () => {

    const classes = useStyles();

    const { id } = useParams();

    const [person, setPerson] = useState()

    const [page, setPage] = useState(1)
    const [pages, setPages] = useState()

    const [items, setItems] = useState([])
    const [itemsLoading, setItemsLoading] = useState(true)

    const [menu, setMenu] = useState("items")

    const [spouses, setSpouses] = useState([])

    const domain = 'https://www.bayfieldhistoricalwebmap.ca'

    async function getPerson(id) {
        
        const person_res = await fetch(`${domain}/api/person/${id}/`)
        const person_json = await person_res.json()
        return person_json
    }

    async function getSpouses(person) {
        if (person && person.spouses.length > 0) {
            const spouses_res = await fetch(`${domain}/api/people/?people=${person.spouses}`)
            const spouses_json = await spouses_res.json()
            return spouses_json
        }
        else {
            return []
        }
    }

    useEffect(() => {
        getPerson(id)
            .then(res => {
                setPerson(res)
                getSpouses(res)
                    .then(res => {
                        console.log(res)
                        setSpouses(res)
                    })
            })
    }, [id])

    useEffect(() => {
        fetch(`${domain}/api/items/?page=${page}&page_size=5&people_or=${[id]}&visibility=published`)
            .then(response => {
                if (response.status !== 200) {
                    return;
                }
                response.json().then( (fetchedFeatures) => {
                    if (fetchedFeatures.results.length > 0) {
                        const features = fetchedFeatures.results.filter(item => item.visibility === "published")
                        setItems(features)
                        setPages(Math.ceil(fetchedFeatures.count/10))
                        setItemsLoading(false)
                    }
                    else {
                        setItems([])
                        setPages(1)
                        setItemsLoading(false)
                    }
                })
                .catch(() => {
                    setItems(null)
                    setItemsLoading(false)
                })
            })
    }, [page, id])

    const onClick = (e) => {
        if (e.target.id === 'previous'){
            setPage(page-1)
        }
        else if (e.target.id === 'next'){
            setPage(page+1)
        }
        setItemsLoading(true)
    }

    const [landOwnership, setLandOwnership] = useState([])

    async function getLocations(id) {
        const res = await fetch(`${domain}/api/lots/ownership/?person=${id}`)
        const res_json = await res.json()
        const wktOptions = {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        }
        for (let i=0; i<res_json.length; i++) {
            const location_res = await fetch(`${domain}/api/locations/?lot=${res_json[i].lot}`)
            const location_json = await location_res.json()
            const parsedFeatures = new GeoJSON().readFeatures(location_json, wktOptions)
            res_json[i].locations = parsedFeatures
        }
        return res_json
    }

    useEffect(() => {
        getLocations(id)
            .then(res => {
                setLandOwnership(res)
            })
    }, [id])

    const [employees, setEmployees] = useState([])

    async function getEmployees(id) {
        const res = await fetch(`${domain}/api/employment/?person=${id}`)
        const res_json = await res.json()
        for (let i=0; i<res_json.length; i++) {
            if (res_json[i].organization) {
                const organization_res = await fetch(`${domain}/api/organization/${res_json[i].organization}/`)
                const organization_json = await organization_res.json()
                res_json[i].organization = organization_json
            }
        }
        return res_json
    }

    useEffect(() => {
        getEmployees(id)
            .then(res => {
                setEmployees(res)
            })
    }, [id])

    const onMenuClick = (e) => {
        document.getElementById(menu).style.backgroundColor = "rgb(113, 172, 191)";
        setMenu(e.target.id)
        document.getElementById(e.target.id).style.backgroundColor = "rgb(47, 86, 102)";
    }

    const [popupImage, setPopupImage] = useState();

    const togglePopup = useCallback((e) => {
        setPopupImage(e.target.getAttribute('src'))
    })

    const onCloseClick = useCallback(() => {
        setPopupImage()
    })

    const [spouseNum, setSpouseNum] = useState(0)

    const onSpouseClick = e => {
        if (e.target.id === 'previous' && spouseNum > 0) {
            setSpouseNum(spouseNum-1)
        }
        else if (e.target.id === 'next' && spouseNum < (spouses.length-1)){
            setSpouseNum(spouseNum+1)
        }
    }

    return(
        <div>
            { popupImage ?
                <ImagePopup popupImage={popupImage} onCloseClick={onCloseClick} />
                : null
            }
            <div className={classes.overviewDiv}>
                <div className={classes.personOverviewDiv}>
                    <div className={classes.overviewContainer}>
                        <div className={classes.overviewInfoDiv}>
                            <span className={classes.overviewTitleFont}>
                                {person && person.middle_name
                                    ? person.first_name + ' ' + person.middle_name + ' ' + person.surname
                                    : person 
                                    ? person.first_name + ' ' + person.surname
                                    : null
                                }
                            </span>
                            {person && person.date_of_birth && person.date_of_death ?
                                <span className={classes.overviewSmallFont}>{person.date_of_birth.toString().substr(0,4) + ' - ' + person.date_of_death.toString().substr(0,4)}</span>
                                : person && person.date_of_birth ? 
                                <span className={classes.overviewSmallFont}>{person.date_of_birth.toString().substr(0,4) + ' - '}</span>
                                : null
                            }
                            <br />
                            {person && person.biography ?
                                <span className={classes.overviewSmallFont}>{person.biography}</span>
                                : <span className={classes.overviewSmallFont}>No biography for this person.</span>
                            }
                        </div>
                    </div>
                    <div className={classes.menuDiv}>
                        <span className={classes.menuItemSelected} onClick={onMenuClick} id="items">Items</span>
                        <span className={classes.menuItem} onClick={onMenuClick} id="land">Land Ownership</span>
                        <span className={classes.menuItemRight} onClick={onMenuClick} id="orgs">Employment</span>
                    </div>
                    <div className={classes.itemViewDiv}>
                    { menu === "items" ?
                            <>
                                <div className={classes.collectionContainer}>
                                    <ItemDisplayContainer loading={itemsLoading} items={items} togglePopup={togglePopup} />
                                </div>
                                <div className={classes.buttonDiv}>
                                { page > 1
                                    ? <span className={classes.buttonContainerLeft} id='previous' onClick={onClick}>Previous</span>
                                    : <span className={classes.buttonContainerLeft}></span>
                                }
                                { page < pages
                                    ? <span className={classes.buttonContainerRight} id='next' onClick={onClick}>Next</span>
                                    : <span className={classes.buttonContainerRight}></span>
                                }
                                </div>
                            </>
                        : menu === "land" ? 
                        <div className={classes.menuContainer}>
                            <table className={classes.table}>
                                <tr>
                                    <td className={classes.tableLeftCells}>
                                        <div className={classes.tagDiv}>
                                            <p className={classes.fontBold}>Lot</p>
                                        </div>
                                    </td>
                                    <td className={classes.tableCells}>
                                        <div className={classes.tagDiv}>
                                            <p className={classes.fontBold}>Addresses</p>
                                        </div>
                                    </td>
                                    <td className={classes.tableCells}>
                                        <div className={classes.tagDiv}>
                                            <p className={classes.fontBold}>Date</p>
                                        </div>
                                    </td>
                                    <td className={classes.tableCells}>
                                        <div className={classes.tagDiv}>
                                            <p className={classes.fontBold}>Source</p>
                                        </div>
                                    </td>
                                </tr>
                                {landOwnership ? landOwnership.map(landOwnership => {
                                    return(
                                        <tr>
                                            <td className={classes.tableLeftCells}>
                                                <div className={classes.tagDiv}>
                                                    <p className={classes.font}>{landOwnership.lot}</p>
                                                </div>
                                            </td>
                                            <td className={classes.tableCells}>
                                                {landOwnership.locations ? landOwnership.locations.map(location => {
                                                    return(
                                                        <div className={classes.tagDiv}>
                                                            <Link className={classes.fontLink} to={`/zone-overview/${location.values_.pk}/`}>{location.values_.name}</Link>
                                                        </div>
                                                    )
                                                }): null}
                                            </td>
                                            <td className={classes.tableCells}>
                                                <div className={classes.tagDiv}>
                                                    <p className={classes.font}>{landOwnership.date.toString().substr(0,4)}</p>
                                                </div>
                                            </td>
                                            <td className={classes.tableCells}>
                                                <div className={classes.tagDiv}>
                                                    <p className={classes.font}>{landOwnership.source}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }) : null}
                            </table>
                        </div>
                        : menu === "orgs" ?
                        <div className={classes.menuContainer}>
                            <table className={classes.table}>
                                <tr>
                                    <td className={classes.tableCells}>
                                        <div className={classes.tagDiv}>
                                            <p className={classes.fontBold}>Organization</p>
                                        </div>
                                    </td>
                                    <td className={classes.tableCells}>
                                        <div className={classes.tagDiv}>
                                            <p className={classes.fontBold}>Date</p>
                                        </div>
                                    </td>
                                </tr>
                                {employees ? employees.map(employee => {
                                    return(
                                        <tr>
                                            <td className={classes.tableCells}>
                                                {employee.person ?
                                                    <div className={classes.tagDiv}>
                                                        <Link className={classes.fontLink} to={`/org-overview/${employee.organization.id}/`}>{employee.organization.name}</Link>
                                                    </div>
                                                : null}
                                            </td>
                                            <td className={classes.tableCells}>
                                                <div className={classes.tagDiv}>
                                                    <p className={classes.font}>{employee.date_start && employee.date_end ? employee.date_start.toString().substr(0,4) + ' - ' + employee.date_end.toString().substr(0,4) : employee.date_start ? employee.date_start.toString().substr(0,4) + ' - ' : null}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }) : null}
                            </table>
                        </div>
                        : null
                    }
                    </div>
                </div>
                <div className={classes.familyTreeDiv}>
                {/* Family Tree */}
                {person ?
                        <>
                            <div className={classes.imageRow}>
                                <ParentDisplay type={'parents'} />
                            </div>
                            <div className={classes.imageMiddleRow}>
                                <div className={classes.imageDiv}>
                                    <SiblingDisplay />
                                </div>
                                <div className={classes.imageDiv}>
                                    <PersonDisplay person={person} />
                                </div>
                                <div className={classes.imageDiv}>
                                    {spouses.length > 0 ? 
                                        <div className={classes.scanViewer}>
                                            <div id="previous" onClick={onSpouseClick} className={classes.progLeft}>
                                                { spouseNum > 0 ? 
                                                    <svg id="previous" onClick={onSpouseClick} className={classes.svgLeft} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                        viewBox="0 0 407.436 407.436">
                                                        <polygon fill="black" points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 "/>
                                                    </svg>
                                                : null}
                                            </div>
                                            <div className={classes.imageDiv}>
                                                <PersonDisplay person={spouses[spouseNum]} />
                                            </div>
                                            <div id="next" onClick={onSpouseClick} className={classes.progRight}>
                                                { spouseNum < spouses.length-1 ? 
                                                    <svg id="next" onClick={onSpouseClick} className={classes.svgRight} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                        viewBox="0 0 407.436 407.436">
                                                        <polygon fill="black" points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 "/>
                                                    </svg>
                                                : null}
                                            </div>
                                        </div>
                                        : <span>No spouse</span>
                                    }
                                </div>
                            </div>
                            <div className={classes.imageRow}>
                                <ParentDisplay type={'children'} />
                            </div>
                        </>
                        : null
                    }
                </div>
            </div>
        </div>
    )
}

export default PersonOverview