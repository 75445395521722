import { useEffect, useState } from "react";

import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
    popupDiv: {
        display: "flex",
        flexDirection: "column",
        padding: 8,
        width: "100%",
    },
    imageDiv: {
        width: "100%",
    },
    image: {
        width: "100%",

    },
    watermarkDiv: {
        position: "relative",
        display: "flex",
        justifyContent: "right",
        zIndex: 5,
        width: "94%",
        top: -55,
        paddingLeft: "3%",
        paddingRight: "3%",
    },
    watermarkText: {
        fontSize: 20,
        color: "black",
        flex: 1,
        textAlign: "right",
        whiteSpace: "nowrap",
        opacity: 0.8,
    },
    input: {
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "whitesmoke",
        marginBottom: 10,
        fontSize: 14,
    },
    inputText: {
        fontSize: 14,
    },
})

const PhotoEditPopup = ({editImage, newImage, watermark}) => {
    const classes = useStyles();

    useEffect(() => {
        if (editImage) {
            document.getElementById("watermark").style.color = editImage.color;

            let height = document.getElementById("image").offsetHeight
            height = height - 10

            if (editImage.position === 'br') {
                document.getElementById("watermarkDiv").style.top = "-55px";
                document.getElementById("watermark").style.textAlign = "right";
            }
            else if (editImage.position === 'bl') {
                document.getElementById("watermarkDiv").style.top = "-55px";
                document.getElementById("watermark").style.textAlign = "left";
            }
            else if (editImage.position === 'tr') {
                document.getElementById("watermarkDiv").style.top = `-${height}px`;
                document.getElementById("watermark").style.textAlign = "right";
            }
            else if (editImage.position === 'tl') {
                document.getElementById("watermarkDiv").style.top = `-${height}px`;
                document.getElementById("watermark").style.textAlign = "left";
            }
        }
    }, [editImage])
    

    console.log(editImage)

    const onColorChange = (e) => {
        document.getElementById("watermark").style.color = e.target.value;
        editImage.color = e.target.value
        newImage(editImage)
        
    }

    const onPositionChange = (e) => {
        let height = document.getElementById("image").offsetHeight
        height = height - 10
        if (e.target.value === 'br') {
            document.getElementById("watermarkDiv").style.top = "-55px";
            document.getElementById("watermark").style.textAlign = "right";
            editImage.position='br'
            newImage(editImage)
        }
        else if (e.target.value === 'bl') {
            document.getElementById("watermarkDiv").style.top = "-55px";
            document.getElementById("watermark").style.textAlign = "left";
            editImage.position='bl'
            newImage(editImage)
        }
        else if (e.target.value === 'tr') {
            document.getElementById("watermarkDiv").style.top = `-${height}px`;
            document.getElementById("watermark").style.textAlign = "right";
            editImage.position='tr'
            newImage(editImage)
        }
        else if (e.target.value === 'tl') {
            document.getElementById("watermarkDiv").style.top = `-${height}px`;
            document.getElementById("watermark").style.textAlign = "left";
            editImage.position='tl'
            newImage(editImage)
        }
    }

    return(
        <div className={classes.popupDiv}>
            <span>Edit your watermark</span>
            <div className={classes.imageDiv}>
                <img id='image' className={classes.image} src={editImage.url} />
                <div id='watermarkDiv' className={classes.watermarkDiv}>
                    <span id='watermark' className={classes.watermarkText}>{watermark}</span>
                </div>
            </div>
            <label className={classes.inputText} for="color">Color</label>
            <select id="color" value={editImage.color} className={classes.input} onChange={onColorChange}>
                <option value="black">Black</option>
                <option value="white">White</option>
            </select>
            <label className={classes.inputText} for="position">Position</label>
            <select id="position" value={editImage.position} className={classes.input} onChange={onPositionChange}>
                <option value="br">Bottom Right</option>
                <option value="bl">Bottom Left</option>
                <option value="tr">Top Right</option>
                <option value="tl">Top Left</option>
            </select>
        </div>
    )
}

export default PhotoEditPopup