import axios from 'axios'

import {store} from './store/store.js'

import { changePending, errorUpdate } from './store/actions/itemAddActions';

const axiosInstance = axios.create({
    baseURL: 'https://www.bayfieldhistoricalwebmap.ca',
    timeout: 20000,
    headers: {
        'Authorization': "JWT " + localStorage.getItem('access'),
        'Content-Type': 'application/json',
        'accept': 'application/json'
    }
});

const {dispatch} = store;

axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;
      
      if (error.response.status === 401 && error.response.statusText === "Unauthorized" && !originalRequest._retry) {
		  originalRequest._retry = true;
          const refresh_token = localStorage.getItem('refresh');

        //   axiosInstance.headers = {
        //         'Authorization': "JWT " + localStorage.getItem('access'),
        //         'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
        //         'accept': 'application/json'
        //   }

          return axiosInstance
              .post('/auth/jwt/refresh/', {refresh: refresh_token})
              .then((response) => {

                  localStorage.setItem('access', response.data.access);

                  axiosInstance.defaults.headers['Authorization'] = "JWT " + response.data.access;
                  originalRequest.headers['Authorization'] = "JWT " + response.data.access;

                  return axiosInstance(originalRequest);
              })
              .catch(err => {
                  console.log(err.response)
                  if (err.response.status === 400) {
                    console.log(err.response.data)
                    if (err.response.data.archival_identifier || err.response.data.externalRecordURL) {
                        console.log(err.response.data)
                        dispatch(errorUpdate(err.response.data))
                        dispatch(changePending(false))
                    }
                  }
              });
      }
      return Promise.reject(error);
  }
);
export default axiosInstance;


// import axios from 'axios';

// const baseURL = `${process.env.REACT_APP_API_URL}`;

// const axiosInstance = axios.create({
//     baseURL: baseURL,
//     timeout: 1000,
//     headers: {
//         Authorization: localStorage.getItem('access')
//             ? 'JWT ' + localStorage.getItem('access')
//             : null,
//         'Content-Type': 'application/json',
//         accept: 'application/json',
//     },
// });

// axiosInstance.interceptors.response.use(
// 	(response) => {
// 		return response;
// 	},
// 	async function (error) {
// 		const originalRequest = error.config;

// 		if (typeof error.response === 'undefined') {
// 			alert(
// 				'A server/network error occurred. ' +
// 					'Looks like CORS might be the problem. ' +
// 					'Sorry about this - we will get it fixed shortly.'
// 			);
// 			return Promise.reject(error);
// 		}

// 		if (
// 			error.response.status === 401 &&
// 			originalRequest.url === baseURL + '/auth/jwt/refresh/'
// 		) {
// 			window.location.href = '/login';
// 			return Promise.reject(error);
// 		}

// 		if (
// 			error.response.status === 401
// 		) {
// 			const refreshToken = localStorage.getItem('refresh');

// 			if (refreshToken) {
// 				const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
// 				const now = Math.ceil(Date.now() / 1000);

// 				if (tokenParts.exp > now) {
// 					const config = {
// 						headers: {
// 							'Content-Type': 'application/json',
// 							'Accept': 'application/json',
// 						}
// 					};

// 					const body = JSON.stringify({ 'refresh': localStorage.getItem('refresh') })

// 					return axiosInstance
// 						.post('/auth/jwt/refresh/', body, config)
// 						.then(response => {
// 							localStorage.setItem('access', response.data.access);
// 							localStorage.setItem('refresh', response.data.refresh);

// 							axiosInstance.defaults.headers['Authorization'] =
// 								'JWT ' + response.data.access;
// 							originalRequest.headers['Authorization'] =
// 								'JWT ' + response.data.access;
// 							return axiosInstance(originalRequest);
// 						}).catch(error=> {
// 							console.log(error);
// 						})		
// 				} else {
// 					console.log('Refresh token is expired', tokenParts.exp, now);
// 					window.location.href = '/login';
// 				}
// 			} else {
// 				console.log('Refresh token not available.');
// 				window.location.href = '/login';
// 			}
// 		}
// 		return Promise.reject(error);
// 	}
// );

// export default axiosInstance;