import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {createUseStyles} from 'react-jss';
import { subjectsUpdate } from '../../../store/actions/itemAddActions';

import axiosInstance from '../../../axios';

const useStyles = createUseStyles({
    input: {
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "whitesmoke",
        marginTop: 3,
    },
    textArea: {
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 167,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "whitesmoke",
        "&::-webkit-scrollbar": {
            width: 5,
            height: 5,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "whitesmoke",
            "&:hover": {
                backgroundColor: "rgb(207, 205, 204)",
            },
            borderRadius: "10px",
        },
        marginTop: 3,
        resize: "none",
    },
    submitButtonDiv: {
        display: "flex",
        justifyContent: "center",
    },
    submitButton: {
        cursor: "pointer",
        backgroundColor: "rgb(47, 86, 102)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 6,
        color: "white",
        padding: 10,
        marginTop: 12,
        width: "50%",
        "&:hover": {
            backgroundColor: "rgb(83, 156, 176)",
        },
    },
    errorMessage: {
        color: "red",
        fontSize: 10,
    },
});

const NewSubject = ({toggleAddPopupComponent}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const currentSubjects = useSelector(state => state.itemAdd.subjects)

    const [name, setName] = useState();

    const [description, setDescription] = useState();

    const [nameError, setNameError] = useState()
    
    const [descriptionError, setDescriptionError] = useState()

    const onNameChange = e => {
        setName(e.target.value)
        setNameError()
        try {        
            document.getElementById("subjectName").style.backgroundColor = "whitesmoke";
        }
        catch{
            return null;
        }
    }
    
    const onDescriptionChange = e => {
        setDescription(e.target.value)
        setDescriptionError()
        try {        
            document.getElementById("subjectDescription").style.backgroundColor = "whitesmoke";
        }
        catch{
            return null;
        }
    }

    const [ subjects, setSubjects ] = useState([])

    const [ subjectsLoading, setSubjectsLoading ] = useState(true)

    const domain = 'https://www.bayfieldhistoricalwebmap.ca'

    useEffect( () => {
        fetch(`${domain}/api/subjects/`)
        .then(response => response.json())
        .then( (fetchedFeatures) => {
            setSubjects(fetchedFeatures)
            setSubjectsLoading(false)
        })

    },[])

    const [parentSubject, setParentSubject] = useState();

    const onSubjectChange = e => {
        setParentSubject(e.target.value)
    }

    async function postItem(url, data) {
        axiosInstance.post(url, JSON.stringify(data))
            .then(res => {
                const data = res.data
                let subjectsArray = currentSubjects
                subjectsArray.push({name: data.subject_name, id: data.id.toString()})
                dispatch(subjectsUpdate(subjectsArray))
                toggleAddPopupComponent('subjectsUpdated')
                return;
            })
            .catch(err => {
                console.log(err.response)
                return;
            })
    }

    const submitSubject = () => {

        if (!name || !description) {
            if (!name) {
                setNameError("Include a name for your subject.")
                try {        
                    document.getElementById("subjectName").style.backgroundColor = "rgb(245, 81, 66)";
                }
                catch{
                    return null;
                }
            }
            if (!description) {
                setDescriptionError("Add a description of your subject.")
                try {        
                    document.getElementById("subjectDescription").style.backgroundColor = "rgb(245, 81, 66)";
                }
                catch{
                    return null;
                }
            }
            return;
        }

        const postData = {
            subject_name: name,
            subject_description: description,
            subject_parent: parentSubject,
        }
        
        postItem(`${domain}/api/subjects/`, postData)
            // .then(data => {
            //     if (data[0]===201) {
            //         data[1].then(data => {
            //             console.log(data)
            //             let subjectsArray = currentSubjects
            //             subjectsArray.push({name: data.subject_name, id: data.id.toString()})
            //             dispatch(subjectsUpdate(subjectsArray))
            //             toggleAddPopupComponent('subjectsUpdated')
            //         })
            //     }
            //     else if (data[0]===401) {
            //         alert("You do not have permission to add a new subject!")
            //     }
            //     else {
            //         data[1].then(data => {
            //             console.log(data)
            //             if (data.subject_name) {
            //                 setNameError("Include a name for your subject.")
            //                 try {        
            //                     document.getElementById("subjectName").style.backgroundColor = "rgb(245, 81, 66)";
            //                 }
            //                 catch{
            //                     return null;
            //                 }
            //             }
            //             if (data.subject_description) {
            //                 setDescriptionError("Add a description of your subject.")
            //                 try {        
            //                     document.getElementById("subjectDescription").style.backgroundColor = "rgb(245, 81, 66)";
            //                 }
            //                 catch{
            //                     return null;
            //                 }
            //             }
            //         })
            //     }
            // })
    }

    return (
        <>
            <label for="subjectName">Subject Name</label>
            <div className={classes.errorMessage}>{nameError}</div>
            <input id="subjectName" className={classes.input} value={name} onChange={onNameChange} autocomplete="off"></input>
            <label for="subjectDescription">Subject Description</label>
            <div className={classes.errorMessage}>{descriptionError}</div>
            <textarea className={classes.textArea} id="subjectDescription" value={description} rows="9" cols="30" onChange={onDescriptionChange}></textarea>
            { subjectsLoading
                ? <p>Loading Subjects...</p>
                : <>
                    <label for="subjects">Parent Subject</label>
                    <select id="subjects" className={classes.input} value={parentSubject} onChange={onSubjectChange}>
                        {subjects
                        ? 
                            <>
                                <option value=''> </option>
                                {subjects.map((subject)=> {
                                    return(
                                        <option value={subject.id}>{subject.subject_name}</option>
                                    );
                                })}
                            </>
                        : null }
                    </select>
                </>
            }
            <div className={classes.submitButtonDiv}>
                <span className={classes.submitButton} onClick={submitSubject}>Add Subject</span>
            </div>
        </>
    )
}

export default NewSubject