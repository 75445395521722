import * as mapActionTypes from '../actions/mapActionTypes';

const initialState = {
    zone: null,
    businessZones: [],
    bayfield1856MapVisibility: false,
    bayfield1856MapOpacity: 1,
    belden1879MapVisibility: false,
    belden1879MapOpacity: 1,
    huronCounty1862MapVisibility: false,
    huronCounty1862MapOpacity: 1,
    townPlan1835Visibility: false,
    townPlan1835Opacity: 1,
    surroundingsPlan1835Visibility: false,
    surroundingsPlan1835Opacity: 1,
    cemeteryPlanVisibility: false,
    cemeteryPlanOpacity: 1,
    baseMap: "Huron County Imagery - 2015",
    legendOpen: true,
    collectionDisplayOpen: false,
    timeSliderOpen: false,
    addressSearchOpen: true,
    orgSearchOpen: false,
}

const mapReducer = (state=initialState, action) => {
    const { type, payload } = action;

    switch(type) {
        case mapActionTypes.ZONE_UPDATE:
            return {
                ...state,
                zone: payload,
            }
        case mapActionTypes.BUSINESS_ZONE_UPDATE:
            return {
                ...state,
                businessZones: payload,
            }
        case mapActionTypes.TOGGLE_1856:
            return {
                ...state,
                bayfield1856MapVisibility: payload,
            }
        case mapActionTypes.TOGGLE_1879:
            return {
                ...state,
                belden1879MapVisibility: payload,
            }
        case mapActionTypes.TOGGLE_1862:
            return {
                ...state,
                huronCounty1862MapVisibility: payload,
            }
        case mapActionTypes.TOGGLE_1835TOWN:
            return {
                ...state,
                townPlan1835Visibility: payload,
            }
        case mapActionTypes.TOGGLE_1835SURROUNDINGS:
            return {
                ...state,
                surroundingsPlan1835Visibility: payload,
            }
        case mapActionTypes.TOGGLE_CEMETERY:
            return {
                ...state,
                cemeteryPlanVisibility: payload,
            }
        case mapActionTypes.OPACITY_1856:
            return {
                ...state,
                bayfield1856MapOpacity: payload,
            }
        case mapActionTypes.OPACITY_1879:
            return {
                ...state,
                belden1879MapOpacity: payload,
            }
        case mapActionTypes.OPACITY_1862:
            return {
                ...state,
                huronCounty1862MapOpacity: payload,
            }
        case mapActionTypes.OPACITY_1835TOWN:
            return {
                ...state,
                townPlan1835Opacity: payload,
            }
        case mapActionTypes.OPACITY_1835SURROUNDINGS:
            return {
                ...state,
                surroundingsPlan1835Opacity: payload,
            }
        case mapActionTypes.OPACITY_CEMETERY:
            return {
                ...state,
                cemeteryPlanOpacity: payload,
            }
        case mapActionTypes.TOGGLE_BASEMAP:
            return {
                ...state,
                baseMap: payload,
            }
        case mapActionTypes.TOGGLE_LEGEND:
            return {
                ...state,
                legendOpen: payload,
            }
        case mapActionTypes.TOGGLE_COLLECTIONDISPLAY:
            return {
                ...state,
                collectionDisplayOpen: payload,
            }
        case mapActionTypes.TOGGLE_TIMESLIDER:
            return {
                ...state,
                timeSliderOpen: payload,
            }
        case mapActionTypes.TOGGLE_ADDRESSSEARCH:
            return {
                ...state,
                addressSearchOpen: payload,
            }
        case mapActionTypes.TOGGLE_ORGSEARCH:
            return {
                ...state,
                orgSearchOpen: payload,
            }
        default:
            return state
    }
}

export default mapReducer;