import React, { useEffect, useState } from 'react';

import { Link, Redirect } from 'react-router-dom';
import {createUseStyles} from 'react-jss';

import { useSelector, useDispatch } from "react-redux";
import { register, setRegisteredSuccess } from "../../store/actions/authActions";

import InputField from '../../components/InputField';
import SimpleHeader from '../../headers/SimpleHeader';


const useStyles = createUseStyles({
    myPageDiv: {
        display: "flex",
        justifyContent: "center",
    },
    myFormDiv: {
        width: 420,
        backgroundColor: "rgb(66, 132, 150)",
        padding: "4px 12px 12px 12px",
        borderRadius: 8,
        display: "flex",
        flexDirection: "column"
    },
    myFormTitle: {
        size: "1.5rem",
        color: 'white'
    },
    myTitleDiv: {
        justifyContent: 'center',
        align: 'center',
        display: 'flex',
        height: 40,
        fontSize: "1.25rem"
    },
    myButton: {
        width: "40%",
        backgroundColor: "white",
        color: "black",
        border: 0,
        borderRadius: 50,
        fontWeight: 'bold',
        transition: "all 0.2s ease-in-out",
        '&:hover': {
            color: "white",
            backgroundColor: "rgb(66, 132, 150)",
            transition: "all 0.2s ease-in-out",
        },
        cursor: "pointer",
    },
    fullPageDiv: {
        margin: 0,
        padding: 0
    },
    loginDiv: {
        display: "flex",
        justifyContent: "center",
        paddingBottom: "1rem"
    },
    loginText: {
        color: "white",
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        padding: "0 1rem",
        height: "100%",
        cursor: "pointer",
        fontSize: "0.7rem",
        "&:hover": {
            transition: "all 0.2s ease-in-out",
            fontSize: "1rem"
        }
    },
    registerText: {
        color: "white",
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        padding: "2rem",
        fontSize: "1rem",
    }
})

export default function Register() {

    const classes = useStyles();

    // const dispatch = useDispatch();

    // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    // const registeredSuccess = useSelector(state => state.auth.registeredSuccess);

    // const inputRefs = React.useRef(
    //     [React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef()]
    // );

    // const initialFormData = Object.freeze({
    //     email: '',
    //     first_name: '',
    //     last_name: '',
    //     password1: '',
    //     password2: '',
    // });


    // const [formData, updateFormData] = useState(initialFormData);

    // const [accountCreated, setAccountCreated] = useState(false);

    // const handleChange = (name, value) => {
    //     updateFormData({
    //         ...formData,
    //         [name]: value.trim(),
    //     });
    // };

    // // console.log(formData);

    // const handleSubmit = (e) => {
    //     e.preventDefault();

    //     //Error checks
    //     let isValid = true;

    //     for (let i=0; i < inputRefs.current.length; i++) {
    //         const valid = inputRefs.current[i].current.validate()
    //         if (!valid) {
    //             isValid = false;
    //         }
    //     }

    //     if (!isValid) {
    //         return
    //     }

    //     // dispatch(setPending(true))

    //     dispatch(register(formData.email, formData.first_name, formData.last_name, formData.password1, formData.password2))
    // }

    // useEffect(() => {
    //     if (registeredSuccess) {
    //         setAccountCreated(true)
    //         dispatch(setRegisteredSuccess(false))
    //     }
    // }, [registeredSuccess])

    // if (isAuthenticated) {
    //     return <Redirect to='/' />
    // }

    // if (accountCreated) {
    //     return <Redirect to='/login' />
    // }

    return (
        <div className={classes.fullPageDiv}>
            <SimpleHeader />
            <div className={classes.myPageDiv}>
                <div className={classes.myFormDiv}>
                    <div className={classes.myTitleDiv}>
                        <h2 className={classes.myFormTitle}>Register</h2>
                    </div>
                    <br></br>
                    {/* <form onSubmit={handleSubmit}>
                        <InputField 
                            ref={inputRefs.current[0]}
                            name="email"
                            label="Email: "
                            onChange={handleChange}
                            validation={"required|email"}
                        />
                        <InputField
                            ref={inputRefs.current[1]}
                            name="first_name"
                            label="First Name: "
                            onChange={handleChange}
                            validation={"required"}
                        />
                        <InputField 
                            ref={inputRefs.current[2]}
                            name="last_name"
                            label="Last Name: "
                            onChange={handleChange}
                            validation={"required"}
                        />
                        <InputField 
                            ref={inputRefs.current[3]}
                            name="password1"
                            label="Password: "
                            onChange={handleChange}
                            type="password"
                            validation={"required|min:8|max:50|password"}
                            id="passwordFirst"
                        />
                        <InputField 
                            ref={inputRefs.current[4]}
                            name="password2"
                            label="Repeat Password: "
                            onChange={handleChange}
                            type="password"
                            validation={"required|min:8|max:50|passwordmatch"}
                        />
                        <br /><br />
                        <div className={classes.myTitleDiv}>
                            <button className={classes.myButton} type="submit" onClick={handleSubmit}>Sign Up</button>
                        </div>
                    </form>
                    <br></br> */}
                    <div className={classes.registerText}>
                        If you are an employee or volunteer, please contact Ben Woodward to make you an account. If you are a community member and would like to add a photograph or postcard to the map, please email the Bayfield Historical Society.
                    </div>
                    <div className={classes.loginDiv}>
                        <Link to='/login' className={classes.loginText}>Log In</Link>
                    </div>
                </div>
            </div>
            <br />
            <br />
        </div>
    );
};