import { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import {createUseStyles} from 'react-jss';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = createUseStyles({
    myFormLabel: {
        color: 'white',
    },
    myInput: {
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
    },
    myError: {
        color: 'rgb(255, 153, 153)',
        fontStyle: 'italic'
    },
    myInputDiv: {
        padding: '4px 4px 4px 4px'
    },
    myLabelDiv: {
        display: "flex"
    }
})

const InputField = forwardRef((props, ref) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const [value, setValue] = useState("");

    const [error, setError] = useState("");

    const newError = useSelector(state => state.auth.errors);

    useEffect(() => {
        if (newError && props.name==="email" && newError.email) {
            setError("This email is already in use!")
        }
    }, [newError])

    const validate = () => {
        if (props.validation) {
            const rules = props.validation.split("|");

            for(let i=0; i < rules.length; i++) {
                const current = rules[i];

                if (current === "required") {
                    if (!value) {
                        setError("This field is required");
                        return false;
                    }
                }

                if (current === "email") {
                    if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
                        setError("Invalid email");
                        return false;
                    }
                }

                if (current === "password") {
                    if(!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/.test(value)) {
                        setError("Password must contain at least one capital letter, lowercase letter, and number.");
                        return false;
                    }
                }

                if (current === "passwordmatch") {
                    if (value !== document.getElementById("passwordFirst").value) {
                        setError("Passwords do not match");
                        return false;
                    }
                }

                //Check if email already exists!!!

                //Check if password is properly formatted
                
                const pair = current.split(":")
                switch(pair[0]) {
                    case "min":
                        if (value.length < pair[1]) {
                            setError(`This field must be at least ${pair[1]} characters long`);
                            return false;
                        }
                        break;
                    case "max":
                        if (value.length > pair[1]) {
                            setError(`This field must be no longer than ${pair[1]} characters long`);
                            return false;
                        }
                        break;
                    default:
                        break;
                }
            }
        }

        return true;
    }

    const handleChange = (e) => {
        setValue(e.target.value)
        setError("")
        props.onChange(e.target.name, e.target.value)
    }

    useImperativeHandle(ref, () => {
        return{
            validate: () => validate()
        }
    })

    return(
        <div className={classes.myInputDiv}>
            <div className={classes.myLabelDiv}>
                {props.label && (
                    <p className={classes.myFormLabel}>{props.label}</p>
                )}
                {error && (
                    <p className={classes.myError}>{error}</p>
                )}
            </div>
            <input
                className={classes.myInput}
                placeholder={props.placeholder}
                name={props.name}
                onChange={(e) => handleChange(e)}
                type={props.type}
                value={props.value ? props.value : value}
                autoComplete={props.autoComplete}
                id={props.id}
            />
        </div>
    )
})

InputField.defaultProps = {
    placeholder: "",
    name: "",
    type: "text",
    value: "",
    autoComplete: "off",
    validation: "",
    id: ""
}


export default InputField;