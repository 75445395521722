import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Redirector from './components/redirector'

import Map from './pages/map';
import Collection from './pages/collectionFixed';
import People from './pages/people';
import Register from './pages/users/register';
import Login from './pages/users/login';
import ResetPassword from './pages/users/resetpassword';
import ResetPasswordConfirm from './pages/users/resetpasswordconfirm';
import Activate from './pages/users/activate';
import AddItem from './pages/addItem';
import ZoneOverviewWrapper from './pages/zoneOverviewWrapper';
import ItemOverviewWrapper from './pages/itemOverviewWrapper';
import PersonOverviewWrapper from './pages/personOverviewWrapper';
import OrgOverviewWrapper from './pages/orgOverviewWrapper';

import { useSelector, useDispatch } from 'react-redux';
import { checkAuthenticated, load_user } from './store/actions/authActions';

const App = () => {
  // const access = localStorage.getItem('access')

  const dispatch = useDispatch();

  const access = useSelector((state) => state.auth.access);

  const authenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (access){
      dispatch(checkAuthenticated());
    }
    if (authenticated === true) {
      dispatch(load_user());
    }
  });
  
  return (
    <BrowserRouter>
      <React.StrictMode>
        <Switch>
          <Route exact path="/" component={Map} />
          <Route exact path="/map" component={Map} />
          <Route exact path="/zone-overview/:id" component={ZoneOverviewWrapper} />
          <Route exact path="/person-overview/:id" component={PersonOverviewWrapper} />
          <Route exact path="/org-overview/:id" component={OrgOverviewWrapper} />
          <Route exact path="/collection" component={Collection} />
          <Route exact path="/add-item" component={AddItem} />
          <Route exact path="/itemview/:id" component={ItemOverviewWrapper} />
          <Route exact path="/people" component={People} />
          <Route exact path="/register" component={Register}/>
          <Route exact path="/login" component={Login}/>
          <Route exact path="/reset-password" component={ResetPassword}/>
          <Route exact path="/password/reset/confirm/:uid/:token" component={ResetPasswordConfirm}/>
          <Route exact path="/activate/:uid/:token" component={Activate}/>
        </Switch>
      </React.StrictMode>
    </BrowserRouter>
  );
};

export default App;