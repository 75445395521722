import React, { useState } from 'react';
// import axiosInstance from '../../axios';
import { Redirect } from 'react-router-dom';
import {createUseStyles} from 'react-jss';

import { useDispatch } from "react-redux";
import { reset_password_confirm } from "../../store/actions/authActions";

import InputField from '../../components/InputField';
import SimpleHeader from '../../headers/SimpleHeader';

const useStyles = createUseStyles({
    myPageDiv: {
        display: "flex",
        justifyContent: "center",
    },
    myFormDiv: {
        width: 420,
        backgroundColor: "rgb(66, 132, 150)",
        padding: "4px 12px 12px 12px",
        borderRadius: 8,
        display: "flex",
        flexDirection: "column"
    },
    myFormTitle: {
        color: 'white'
    },
    myTitleDiv: {
        justifyContent: 'center',
        align: 'center',
        display: 'flex',
        height: 40,
        fontSize: "1.25rem"
    },
    myButton: {
        width: "40%",
        backgroundColor: "white",
        color: "black",
        border: 0,
        borderRadius: 50,
        fontWeight: 'bold',
        transition: "all 0.2s ease-in-out",
        '&:hover': {
            color: "white",
            backgroundColor: "rgb(66, 132, 150)",
            transition: "all 0.2s ease-in-out"
        },
        cursor: "pointer",
    },
    registerDiv: {
        display: "flex",
        justifyContent: "center",
        paddingBottom: "1rem"
    },
    registerText: {
        color: "white",
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        padding: "0 1rem",
        height: "100%",
        cursor: "pointer",
        fontSize: "0.7rem",
        "&:hover": {
            transition: "all 0.2s ease-in-out",
            fontSize: "1rem"
        }
    }
})

export default function ResetPasswordConfirm ({match}) {

    const classes = useStyles();

    const dispatch = useDispatch();

    const [requestSent, setRequestSent] = useState(false);

    const [formData, updateFormData] = useState ({
        new_password: '',
        re_new_password: '',
    });

    // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const inputRefs = React.useRef(
        [React.createRef(), React.createRef()]
    );

    const handleChange = (name, value) => {
        updateFormData({
            ...formData,
            [name]: value.trim(),
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const uid = match.params.uid;
        const token = match.params.token;
        // console.log([uid, token, formData.new_password, formData.re_new_password]);

        
        //Error checks
        let isValid = true;

        for (let i=0; i < inputRefs.current.length; i++) {
            const valid = inputRefs.current[i].current.validate()
            if (!valid) {
                isValid = false;
            }
        }

        if (!isValid) {
            return
        }

        dispatch(reset_password_confirm(uid, token, formData.new_password, formData.re_new_password));
        setRequestSent(true);
    }

    if (requestSent) {
        return <Redirect to='/' />
    }

    return (
        <div className={classes.fullPageDiv}>
            <div>
                <SimpleHeader />
            </div>
            <div className={classes.myPageDiv}>
                <div className={classes.myFormDiv}>
                    <div className={classes.myTitleDiv}>
                        <h2 className={classes.myFormTitle}>Request Password Reset</h2>
                    </div>
                    <br />
                    <form onSubmit={e => handleSubmit(e)}>
                        <InputField 
                            ref={inputRefs.current[0]}
                            name="new_password"
                            label="New Password:"
                            onChange={handleChange}
                            type="password"
                            validation={"required|min:8|max:50|password"}
                            id="passwordFirst"
                        />
                        <InputField 
                            ref={inputRefs.current[1]}
                            name="re_new_password"
                            label="Confirm New Password:"
                            onChange={handleChange}
                            type="password"
                            validation={"required|min:8|max:50|passwordmatch"}
                        />
                        <br /><br />
                        <div className={classes.myTitleDiv}>
                            <button className={classes.myButton} type="submit">Reset Password</button>
                        </div>     
                    </form>
                </div>
            </div>
        </div>
    )
}