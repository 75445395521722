import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { toggleMenu } from '../../store/actions/mapActions';

import AddressSearch from './addressSearch/addressSearch';
import Legend from './legend/legend';
import CollectionDisplay from './collectionDisplay/collectionDisplay';
// import TimeSlider from './timeSlider/timeSlider';
import OrgSearch from './businessSearch/businessSearch';


import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    sideBarButtonDiv: {
        padding: "7px 7px 7px 7px",
        backgroundColor: "rgb(66, 132, 150)",
        borderRadius: 15,
        marginBottom: 10,
    },
    sideBarButtonLabelDiv: {
        display: "flex",
        justifyContent: "left",
        cursor: "pointer",
        height: "9vh",
        "&:hover": {
            "& span": {
                fontSize: "1.3em",
                transition: "all 0.2s ease-in-out",
            }
        }
    },
    sideBarButtonDropdownArrow: {
        marginTop: 12,
        height: "5vh",
    },
    sideBarButtonDropdownArrowColor: {
        fill: "white",
    },
    sideBarButtonText: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        color: "white",
        fontSize: 16,
        fontWeight: "200",
        marginLeft: 15,
    }
});

const SideBarButton = ({name, features, component, togglePopup, toggleZoneOverview, toggleItemOverview, open}) => {
    // const [open, setOpen] = useState(false);

    const dispatch = useDispatch();

    const [transform, setTransform] = useState(open ? "rotate(180, 24, 24)" : "rotate(0, 24, 24)")

    const classes = useStyles();

    const onClick = () => {
        // if (open) {
        //     setTransform("rotate(0, 24, 24)")
        // }
        // else {
        //     setTransform("rotate(180, 24, 24)")
        // }
        dispatch(toggleMenu(component,!open))
    }

    useEffect(() => {
        if (open) {
            setTransform("rotate(180, 24, 24)")
        }
        else {
            setTransform("rotate(0, 24, 24)")
        }
    }, [open])

    return(
        <div className={classes.sideBarButtonDiv}>
            <div className={classes.sideBarButtonLabelDiv} onClick={onClick}>
                <svg className={classes.sideBarButtonDropdownArrow} viewBox="0 0 48 48"  xmlns="http://www.w3.org/2000/svg"><path transform={transform} className={classes.sideBarButtonDropdownArrowColor} d="M14 20l10 10 10-10z"/></svg>
                <span className={classes.sideBarButtonText}>{name}</span>
            </div>
            <div>
                { open===true && component==="AddressSearch" ? <AddressSearch features={features} toggleZoneOverview={toggleZoneOverview} /> : open===true && component==="Legend" ? <Legend /> : open===true && component==="CollectionDisplay" ? <CollectionDisplay features={features} togglePopup={togglePopup} toggleItemOverview={toggleItemOverview} /> : open===true && component==="Business Search" ? <OrgSearch /> : null }
            </div>
        </div>
    )
}

export default SideBarButton