import React, {useState, useRef, useEffect} from 'react';

import { Map, View } from 'ol'
import VectorLayer from 'ol/layer/Vector'
import TileLayer from 'ol/layer/Tile'
import VectorSource from 'ol/source/Vector'
import { TileArcGISRest } from 'ol/source'
import {Attribution, defaults as defaultControls} from 'ol/control';
import {Fill, Stroke, Style } from 'ol/style';
import GeoJSON from 'ol/format/GeoJSON'

// import {useSelector, useDispatch} from 'react-redux';

// import { zoneUpdate } from '../../store/actions/mapActions';
// import { zoneNameUpdate } from '../../store/actions/itemAddActions'

function OverviewMap({id}) {
    
    const [features, setFeatures] = useState([])
    const [ map, setMap ] = useState()
    const [ featuresLayer, setFeaturesLayer ] = useState()
    // const [ locationNameHover, setLocationNameHover ] = useState('Hover over a location')

    const domain = 'https://www.bayfieldhistoricalwebmap.ca'

    useEffect(() => {
        fetch(`${domain}/api/locations/?search=${id}`)
            .then(response => response.json())
            .then( (fetchedFeatures) => {
                const wktOptions = {
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:3857'
                }
                const parsedFeatures = new GeoJSON().readFeatures(fetchedFeatures, wktOptions)
    
                setFeatures(parsedFeatures)
            })
    }, [id])

    // let mapZone = useSelector((state) => state.map.zone)
    // let zoneName = useSelector((state) => state.itemAdd.zonename)

    // const dispatch = useDispatch();

    const mapElement = useRef()

    const mapRef = useRef()
    mapRef.current = map

    //Declare Layers
    const huronCountyImagery = new TileLayer({
        source: new TileArcGISRest({
            url: 'https://gis.huroncounty.ca/hurongis/rest/services/Imagery/Huron_Imagery_2015/ImageServer',
        })
    })

    //Initialize map
    useEffect( () => {
        const initialFeaturesLayer = new VectorLayer({
            source: new VectorSource(),
        })

        var attribution = new Attribution({
            collapsible: false,
        });

        const initialMap = new Map({
            target: mapElement.current,
            layers: [ 
                huronCountyImagery,
                initialFeaturesLayer
            ],
            view: new View({
                projection: 'EPSG:3857',
                center: [-9094456.127456, 5397978.160185],
                zoom: 15,
                minZoom: 14,
            }),
            controls: defaultControls({attribution: false, rotate: false, zoom: false}).extend([attribution]),
        })

        setMap(initialMap)
        setFeaturesLayer(initialFeaturesLayer)
    },[])

    useEffect(() => {
        let style = new Style({
            fill: new Fill({
                color: 'rgba(245, 24, 0, 0.5)',
            }),
                stroke: new Stroke({
                color: 'rgba(245, 24, 0, 0.8)',
                width: 3,
            }),
        });
        if (features.length && mapRef) {
            featuresLayer.setSource(
                new VectorSource({
                    features: features
                })
            )
            featuresLayer.setStyle(style)

            featuresLayer.setZIndex(997)
        }
    }, [features, featuresLayer])

    useEffect(() => {
        if (features && features.length > 0) {
            console.log(features[0].getGeometry().extent_)
            mapRef.current.getView().fit(features[0].getGeometry().extent_, {padding: [100, 100, 100, 100]})
        }
    }, [features])

    return (
        <div id="formMap">          
            <div ref={mapElement} className="overview-map map" id="map"></div>
        </div>
    )
}

export default OverviewMap