import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { collectionUpdate } from '../../../store/actions/itemAddActions';

import {createUseStyles} from 'react-jss';

import axiosInstance from '../../../axios';

const useStyles = createUseStyles({
    input: {
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "whitesmoke",
        marginTop: 3,
    },
    textArea: {
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 167,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "whitesmoke",
        "&::-webkit-scrollbar": {
            width: 5,
            height: 5,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "whitesmoke",
            "&:hover": {
                backgroundColor: "rgb(207, 205, 204)",
            },
            borderRadius: "10px",
        },
        marginTop: 3,
        resize: "none",
    },
    submitButtonDiv: {
        display: "flex",
        justifyContent: "center",
    },
    submitButton: {
        cursor: "pointer",
        backgroundColor: "rgb(47, 86, 102)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 6,
        color: "white",
        padding: 10,
        marginTop: 12,
        width: "50%",
        "&:hover": {
            backgroundColor: "rgb(83, 156, 176)",
        },
    },
    errorMessage: {
        color: "red",
        fontSize: 10,
    },
});

const NewCollection = ({toggleAddPopupComponent}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const currentCollection = useSelector(state => state.itemAdd.collection)

    const [name, setName] = useState();

    const [description, setDescription] = useState();

    const [hierarchy, setHierarchy] = useState();

    const onNameChange = e => {
        setNameError()
        setName(e.target.value)
        try {        
            document.getElementById("collectionName").style.backgroundColor = "whitesmoke";
        }
        catch{
            return null;
        }
    }
    
    const onDescriptionChange = e => {
        setDescriptionError()
        setDescription(e.target.value)
        try {        
            document.getElementById("collectionDescription").style.backgroundColor = "whitesmoke";
        }
        catch{
            return null;
        }
    }

    const onHierarchyChange = e => {
        setHierarchy(e.target.value)
    }

    const [ collections, setCollections ] = useState([])

    const [ collectionsLoading, setCollectionsLoading ] = useState(true)

    const [nameError, setNameError] = useState()
    
    const [descriptionError, setDescriptionError] = useState()

    const domain = 'https://www.bayfieldhistoricalwebmap.ca'

    useEffect( () => {
        fetch(`${domain}/api/collections/`)
        .then(response => response.json())
        .then( (fetchedFeatures) => {
            setCollections(fetchedFeatures)
            setCollectionsLoading(false)
        })

    },[])

    const [parentCollection, setParentCollection] = useState();

    const onCollectionChange = e => {
        setParentCollection(e.target.value)
    }

    async function postItem(url, data) {
        axiosInstance.post(url, JSON.stringify(data))
            .then(res => {
                console.log(res)
                dispatch(collectionUpdate(res.data.id))
                toggleAddPopupComponent('collectionUpdated')
                return;
            })
            .catch(err => {
                console.log(err.response)
                return;
            })
    }

    const submitCollection = () => {
        if (!name || !description) {
            if (!name) {
                setNameError("Include a name for your collection.")
                try {        
                    document.getElementById("collectionName").style.backgroundColor = "rgb(245, 81, 66)";
                }
                catch{
                    return null;
                }
            }
            if (!description) {
                setDescriptionError("Add a description of your collection")
                try {        
                    document.getElementById("collectionDescription").style.backgroundColor = "rgb(245, 81, 66)";
                }
                catch{
                    return null;
                } 
            }
            return;
        }

        const postData = {
            collection_name: name,
            collection_description: description,
            hierarchy: hierarchy,
            parent_collection: parentCollection,
        }
        
        postItem(`${domain}/api/collections/`, postData)
    }

    //                     //Have it close and use a callback to refresh the collections
    //                 })
    //             }
    //             else if (data[0]===401) {
    //                 alert("You do not have permission to add a new collection!")
    //             }
    //             else {
    //                 data[1].then(data => {
    //                     console.log(data)
    //                     if (data.collection_description) {
    //                         setDescriptionError("Add a description of your collection.")
    //                         try {        
    //                             document.getElementById("collectionDescription").style.backgroundColor = "rgb(245, 81, 66)";
    //                         }
    //                         catch{
    //                             return null;
    //                         }
    //                     }
    //                     if (data.collection_name) {
    //                         setNameError("Include a name for your collection.")
    //                         try {        
    //                             document.getElementById("collectionName").style.backgroundColor = "rgb(245, 81, 66)";
    //                         }
    //                         catch{
    //                             return null;
    //                         }
    //                     }
    //                 })
    //             }
    //         })
    

    return (
        <>
            <label for="collectionName">Collection Name</label>
            <div className={classes.errorMessage}>{nameError}</div>
            <input id="collectionName" className={classes.input} value={name} onChange={onNameChange} autocomplete="off"></input>
            <label for="collectionDescription">Collection Description</label>
            <div className={classes.errorMessage}>{descriptionError}</div>
            <textarea className={classes.textArea} id="collectionDescription" value={description} rows="9" cols="30" onChange={onDescriptionChange}></textarea>
            <label for="hierarchy">Collection Hierarchy</label>
            <select id='hierarchy' className={classes.input} value={hierarchy} onChange={onHierarchyChange}>
                <option value=''></option>
                <option value='fond'>Fond</option>
                <option value='series'>Series</option>
                <option value='subseries'>Subseries</option>
                <option value='file'>File</option>
            </select>
            { collectionsLoading
                ? <p>Loading Collections...</p>
                : <>
                    <label for="collection">Parent Collection</label>
                    <select id="collection" className={classes.input} value={parentCollection} onChange={onCollectionChange}>
                        {collections 
                        ? 
                            <>
                                <option value=''> </option>
                                {collections.map((collectionName)=> {
                                    return(
                                        <option value={collectionName.id}>{collectionName.collection_name}</option>
                                    );
                                })}
                            </>
                        : null }
                    </select>
                </>
            }
            <div className={classes.submitButtonDiv}>
                <span className={classes.submitButton} onClick={submitCollection}>Add Collection</span>
            </div>
        </>
    )
}

export default NewCollection