import {useSelector, useDispatch} from 'react-redux';

import {archivalIdentifierUpdate} from '../../../store/actions/itemAddActions';

import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    input: {
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "white",
        marginTop: 3,
    },
    errorMessage: {
        color: "red",
        fontSize: 10,
    },
});

const ItemIdentifier = ({error, idErrorChange}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const archivalIdentifier = useSelector((state) => state.itemAdd.archivalIdentifier)

    const onArchivalIdentifierChange = (e) => {
        dispatch(archivalIdentifierUpdate(e.target.value))
        if (e.target.value && error) {
            idErrorChange();
        }
    }

    return (
        <>
            <label htmlFor="archivalIdentifier">Identifier</label>
            { error
                ? <div className={classes.errorMessage}>{error}</div>
                : null
            }
            <input id="archivalIdentifier" className={classes.input} value={archivalIdentifier ? archivalIdentifier : ''} onChange={onArchivalIdentifierChange} autoComplete="off"></input>
        </>
    )
}

export default ItemIdentifier