import { combineReducers } from "redux";

import authReducer from './authReducer';
import mapReducer from "./mapReducer";
import itemAddReducer from "./itemAddReducer";
import queryReducer from "./queryReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    map: mapReducer,
    itemAdd: itemAddReducer,
    query: queryReducer,
});

export default rootReducer;