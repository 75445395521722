import {useEffect, useState} from 'react';

import { useSelector } from 'react-redux';

import {createUseStyles} from 'react-jss';

import {SpinningCircles} from 'react-loading-icons';

import Item from './item';
import TimeSlider from '../timeSlider/timeSlider';

const useStyles = createUseStyles({
    searchBarDiv: {
        display: "flex",
        justifyContent: "center",
        marginBottom: 5,
    },
    searchBar: {
        border: "none",
        borderRadius: 5,
        height: 30,
        width: "90vw",
        padding: 2,
        marginBottom: 5,
        "&:focus": {
            border: 10,
            borderColor: "grey",
            outline: "none",
        }
    },
    locationResults: {
        overflowY: "scroll",
        overflowX: "none",
        height: "52vh",
        "&::-webkit-scrollbar": {
            width: 5,
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "rgba(255, 255, 255, 0)",
            opacity: 0,
            borderRadius: "10px",
        },
        backgroundColor: "rgba(176, 54, 5, 0)",
        opacity: 1,
        padding: 3,
        borderRadius: 15,
        width: "90vw",
        // marginBottom: 8,
    },
    locationResultsContainer: {
        display: "flex",
        justifyContent: "center",
    },
    locationDiv: {
        alignItems: "center",
        backgroundColor: "white",
        marginRight: 0,
        marginLeft: 5,
        marginTop: 5,
        marginBottom: 5,
        padding: 12,
        borderRadius: 12,
    },
    resultHeading: {
        fontSize: 14,
        fontWeight: "150",
        color: "black",
        marginTop: 2,
    },
    resultText: {
        fontSize: 12,
        fontWeight: "150",
        color: "black",
        marginTop: 2,
    },
    buttonDiv: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        color: "white",
        marginBottom: 5,
        marginTop: 5,
    },
    buttonContainerDiv: {
        width: "50%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgb(66, 132, 150)",
        transition: "all 0.2s ease-in-out",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgb(83, 156, 176)",
        }
    },
    btnContainerLeft: {
        composes: "$buttonContainerDiv",
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
    },
    btnContainerRight: {
        composes: "$buttonContainerDiv",
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
    },
    text: {
        color: "white",
    },
    textCenter: {
        composes: "$text",
        textAlign: "center",
    },
    loadingIconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
});

const CollectionDisplay = ({features, togglePopup, toggleItemOverview}) => {
    

    const location = useSelector((state) => state.map.zone);

    const timeStart = useSelector((state) => state.query.timeStart)
    const timeEnd = useSelector((state) => state.query.timeEnd)

    const [zoneId, setZoneId] = useState();
    const [prevZoneId, setPrevZoneId] = useState();
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState();
    const [loading, setLoading] = useState(true);

    const zone = features.find((zone) => zone.values_.name === location)

    useEffect(() => {
        setPrevZoneId(zoneId)
        try {
            if (zone.values_.pk != prevZoneId) {
                setZoneId(zone.values_.pk)
                setPage(1)
                // setTimeout((() => setLoading(false)), 2000)
                setLoading(false)
            }
        }
        catch {
            return null;
        }
    }, [location])

    const onClick = (e) => {
        if (e.target.id === 'previous'){
            setPage(page-1)
        }
        else if (e.target.id === 'next'){
            setPage(page+1)
        }
        setLoading(true)
    }

    const domain = 'https://www.bayfieldhistoricalwebmap.ca'

    useEffect(() => {
        setLoading(true)
        if (zoneId) {
            fetch(`${domain}/api/items/?page=${page}&page_size=5&location=${zoneId}&date=${timeStart.toString() + timeEnd.toString()}&visibility=published`)
                .then(response => response.json())
                .then( (fetchedFeatures) => {
                    if (fetchedFeatures.results.length > 0) {
                        const features = fetchedFeatures.results.filter(item => item.visibility === "published")
                        setItems(features)
                        setPages(Math.ceil(fetchedFeatures.count/5))
                        setLoading(false)
                    }
                    else {
                        setItems([])
                        setPages(1)
                        setLoading(false)
                    }
                })
        }
    }, [zoneId, page, timeStart, timeEnd])

    const classes = useStyles();

    // const [loaded, setLoaded] = useState(false)

    // const checkLoaded = useCallback(() => {
    //     setLoaded(true)
    // })

    return(
        <div>
            {/* <div className={classes.buttonDiv}>
                { page > 1
                    ? <span className={classes.btnContainerLeft} id='previous' onClick={onClick}>Previous</span>
                    : <span className={classes.btnContainerLeft}></span>
                }
                { page < pages
                    ? <span className={classes.btnContainerRight} id='next' onClick={onClick}>Next</span>
                    : <span className={classes.btnContainerRight}></span>
                }
            </div> */}
            <TimeSlider />
            <div className={classes.locationResultsContainer}>
                <div className={classes.locationResults}>
                    {!zoneId ?
                            <div className={classes.loadingIconContainer}>
                                <span className={classes.textCenter}>Click on an area or lot on the map to view historic items from that location.</span>
                            </div>
                        : !loading && items && items.length > 0 ?
                            items.map((item) => {
                                return (
                                    <div className={classes.locationDiv} key={item.id}>
                                        <Item loading={loading} item={item} togglePopup={togglePopup} toggleItemOverview={toggleItemOverview} />
                                    </div>
                                );
                            })
                        : loading ?
                            <div className={classes.loadingIconContainer}>
                                <SpinningCircles fill="white" />
                            </div>
                        : 
                            <div className={classes.loadingIconContainer}>
                                <span className={classes.textCenter}>No items associated with this zone.</span>
                            </div>
                    }
                </div>
            </div>
            <div className={classes.buttonDiv}>
                { page > 1
                    ? <div className={classes.btnContainerLeft} id='previous' onClick={onClick}>Previous</div>
                    : <div className={classes.btnContainerLeft}></div>
                }
                { page < pages
                    ? <div className={classes.btnContainerRight} id='next' onClick={onClick}>Next</div>
                    : <div className={classes.btnContainerRight}></div>
                }
            </div>
        </div>
    )
}

export default CollectionDisplay;