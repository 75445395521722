import PublicHeader from '../headers/PublicHeader';

// import Tree from 'react-d3-tree';

const People= () => {

    // const orgChart = {
    //     name: 'CEO',
    //     children: [
    //       {
    //         name: 'Manager',
    //         attributes: {
    //           department: 'Production',
    //         },
    //         children: [
    //           {
    //             name: 'Foreman',
    //             attributes: {
    //               department: 'Fabrication',
    //             },
    //             children: [
    //               {
    //                 name: 'Worker',
    //               },
    //             ],
    //           },
    //           {
    //             name: 'Foreman',
    //             attributes: {
    //               department: 'Assembly',
    //             },
    //             children: [
    //               {
    //                 name: 'Worker',
    //               },
    //             ],
    //           },
    //         ],
    //       },
    //     ],
    //   };

    return (
        <>
            <PublicHeader />
            <h2>People</h2>
        </>
    )

}

export default People;