import { useState, useEffect } from 'react';

import {useSelector, useDispatch} from 'react-redux';

import {organizationUpdate} from '../../../store/actions/itemAddActions';

import {createUseStyles} from 'react-jss';

import OrganizationTag from '../organizationTag'
import ReactTooltip from 'react-tooltip';

const useStyles = createUseStyles({
    searchDiv: {
        position: "relative",
    },
    input: {
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "white",
        marginTop: 3,
    },
    tagBox: {
        height: 60,
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        padding: "0.5rem",
        marginTop: 3,
        backgroundColor: "white",
        display: "flex",
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
            width: 3,
            height: 3,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "whitesmoke",
            "&:hover": {
                backgroundColor: "rgb(207, 205, 204)",
            },
            borderRadius: "10px",
        },
    },
    errorMessage: {
        color: "red",
        fontSize: 10,
    },
    searchBox: {
        position: "absolute",
        backgroundColor: "white",
        boxShadow: "0px 0px 15px 1px grey",
        borderRadius: 6,
        width: "90%",
        zIndex: 7,

    },
    searchOptions: {
        position: "relative",
        display: "block",
        transition: "all 0.5s",
        padding: 10,
        fontSize: 14,
        borderRadius: 3,
        "&:hover": {
            backgroundColor: "grey",
            transition: "0.5s",
        },
        cursor: "pointer",
        zIndex: 7,
    },
});

const ItemOrganizations = ({refresh}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const organizations  = useSelector((state) => state.itemAdd.organizations)

    const [ orgSearch, setOrgSearch ] = useState()


    const [ orgOptions, setOrgOptions] = useState([])

    const [ orgLoading, setOrgLoading ] = useState(true)

    const [errorMessage, setErrorMessage] = useState()

    const domain = 'https://www.bayfieldhistoricalwebmap.ca'

    useEffect( () => {
        if (refresh === 'organizationsUpdated') {
            fetch(`${domain}/api/organizations/`)
            .then(response => response.json())
            .then( (fetchedFeatures) => {
                setOrgOptions(fetchedFeatures)
                setOrgLoading(false)
            })
        }
    },[refresh])

    useEffect( () => {
        fetch(`${domain}/api/organizations/`)
        .then(response => response.json())
        .then( (fetchedFeatures) => {
            setOrgOptions(fetchedFeatures)
            setOrgLoading(false)
        })

    },[])

    const onChange = (e) => {
        setOrgSearch(e.target.value)
        setErrorMessage(null)
        document.getElementById("orgInput").style.backgroundColor = "white";
    }

    const onClick = (e) => {
        let prevState = organizations
        if (e.target.innerHTML) {
            const trimmedInput = e.target.innerHTML.trim();

            let duplicate = false;
            // console.log(trimmedInput, e.target.id)

            if (trimmedInput.length) {
                for (let i=0; i<organizations.length; i++){
                    if (organizations[i].id === (e.target.id)) {
                        setErrorMessage("You have already added this organization tag")
                        document.getElementById("orgInput").style.backgroundColor = "rgb(245, 81, 66)";
                        duplicate = true
                        break;
                    }
                }
                if (!duplicate) {
                    prevState.push({name: trimmedInput, id: e.target.id})
                    dispatch(organizationUpdate(prevState));
                    setOrgSearch('');
                    document.getElementById("orgInput").style.backgroundColor = "white";
                }
            }
        }
    };

    return (
        <div className={classes.searchDiv}>
            <ReactTooltip multiline={true} />
            <label htmlFor="orgInput" data-tip="Search for organizations (businesses, community groups, etc.) and add them from the suggestions that appear. Add as many organizations as are applicable. If your organization is missing, add it using the 'Add organization' button.">Organization Tags</label>
            <div className={classes.errorMessage}>{errorMessage}</div>
            <div className={classes.tagBox}>
                {organizations.map((organization) => <OrganizationTag id={organization.id} name={organization.name} />)}
            </div>

            <input className={classes.input} id="orgInput" onChange={onChange} value={orgSearch ? orgSearch : ''} autoComplete="off" placeholder="Search organization..." />
            {!orgLoading && orgSearch && orgSearch.length > 2
                ? <div className={classes.searchBox}> 
                    {orgOptions.filter((organization) => {
                        const name = organization.name.toLowerCase()
                        if (name.includes(orgSearch.toLowerCase())) {
                            return organization;
                        }
                        return null;
                    }).map((organization) =>
                        <span className={classes.searchOptions} onClick={onClick} id={organization.id}>{organization.name}</span>
                    )} 
                </div>
                : null
            }
        </div>
    )
}

export default ItemOrganizations