import {createUseStyles} from 'react-jss';
import BHSLogo from './logo.svg';
import { Link } from 'react-router-dom';

const useStyles = createUseStyles({
    headerDiv: {
        position: "sticky",
        backgroundColor: "rgb(66, 132, 150)",
        height: "5rem",
        padding: "0.2rem",
        paddingLeft: "2rem",
        display: "grid",
        gridTemplateColumns: "2fr 4fr 1fr",
        alignItems: "center",
        justifyContent: "space-around",
        zIndex: 10,
        marginBottom: 30,
    },
    headerLogo: {
        height: "3.5rem",
    },
    headerLogoDiv: {
        position: "relative",
    }
})

const SimpleHeader = () => {

    const classes = useStyles();

    return (
        <div className={classes.headerDiv}>
            <div className={classes.headerLogoDiv}>
                <Link to="/"><img className={classes.headerLogo} src={BHSLogo} alt="Bayfield Historical"/></Link>
            </div>
        </div>
    )
}

export default SimpleHeader;