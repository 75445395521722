import { useState, useEffect } from "react"

import { useParams } from "react-router"

import PersonDisplay from "./personDisplay"

import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    imageDiv: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxHeight: "100%",
    },
    scanViewer: {
        display: "grid",
        gridTemplateColumns: "10% 80% 10%",
        width: "100%",
        height: "60%",
    },
    progression: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        transition: "all 0.2s ease-in-out",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgb(210, 210, 210)",
        }
    },
    progLeft: {
        composes: "$progression",
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
    },
    progRight: {
        composes: "$progression",
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
    },
    svgLeft: {
        fontSize: 15,
        transform: "rotate(180deg)",
    },
    svgRight: {
        fontSize: 20,
    },
})

const SiblingDisplay = () => {

    const classes = useStyles();

    const {id} = useParams();

    const [siblings, setSiblings] = useState([])
    const [siblingsLoading, setSiblingsLoading] = useState(true)

    async function parent_array_async(personId) {
        let parent_response
        let parent
        let child_response
        let child
        let relationships_response
        let relationships
        let parentsArray = []
        let siblingRelationsArray = []
        let childArray = []
        let relationUrl
        let parentUrl
        let childUrl
        let childIds = []
        const domain = 'https://www.bayfieldhistoricwebmap.ca'
        relationUrl = `${domain}/api/people/relationships/?person2=${personId}&relationship_type=parent`
        relationships_response = await fetch(relationUrl)
        relationships = await relationships_response.json()

        for (let i=0; i<relationships.length; i++) {
            parentUrl = relationships[i].person1
            parent_response = await fetch(`${domain}/api/person/${parentUrl}/`)
            parent = await parent_response.json()
            parentsArray.push(parent)
        }

        console.log(parentsArray)
        
        for (let i=0; i<parentsArray.length; i++) {
            relationUrl = `${domain}/api/people/relationships/?person1=${parentsArray[i].id}&relationship_type=parent`;
            relationships_response = await fetch(relationUrl)
            relationships = await relationships_response.json()
            for (let x=0; x<relationships.length; x++) {
                siblingRelationsArray.push(relationships[x])
            }  
        }
        
        console.log(siblingRelationsArray)

        for (let i=0; i<siblingRelationsArray.length; i++) {
            childUrl = siblingRelationsArray[i].person2
            console.log(childUrl)
            console.log(personId)
            if (childUrl != personId && !childIds.includes(childUrl)) {
                child_response = await fetch(`${domain}/api/person/${childUrl}/`)
                child = await child_response.json()
                childArray.push(child)
                childIds.push(child.id)
            }
        }

        console.log(childArray)

        setSiblings(childArray)
        setSiblingsLoading(false)
    }

    useEffect(() => {
        if (id) {
            parent_array_async(id)
        }
    }, [id])

    const [siblingNum, setSiblingNum] = useState(0)

    const onSiblingClick = e => {
        if (e.target.id === 'previous' && siblingNum > 0) {
            setSiblingNum(siblingNum-1)
        }
        else if (e.target.id === 'next' && siblingNum < (siblings.length-1)){
            setSiblingNum(siblingNum+1)
        }
    }

    return (
        <>
            {siblings.length > 0 && !siblingsLoading ? 
                <div className={classes.scanViewer}>
                    <div id="previous" onClick={onSiblingClick} className={classes.progLeft}>
                        { siblingNum > 0 ? 
                            <svg id="previous" onClick={onSiblingClick} className={classes.svgLeft} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                viewBox="0 0 407.436 407.436">
                                <polygon fill="black" points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 "/>
                            </svg>
                        : null}
                    </div>
                    <div className={classes.imageDiv}>
                        <PersonDisplay person={siblings[siblingNum]} />
                    </div>
                    <div id="next" onClick={onSiblingClick} className={classes.progRight}>
                        { siblingNum < siblings.length-1 ? 
                            <svg id="next" onClick={onSiblingClick} className={classes.svgRight} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                viewBox="0 0 407.436 407.436">
                                <polygon fill="black" points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 "/>
                            </svg>
                        : null}
                    </div>
                </div>
                : <span>No siblings.</span>
            }
        </>
    )
}

export default SiblingDisplay