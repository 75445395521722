import { useState, useEffect } from 'react';

import {useSelector, useDispatch} from 'react-redux';

import {subjectsChange} from '../../store/actions/queryActions';

import {createUseStyles} from 'react-jss';

import SubjectTag from './subjectTag'

const useStyles = createUseStyles({
    searchDiv: {
        position: "relative",
        width: "100%",
    },
    text: {
        color: "white",
        display: "flex",
        justifyContent: "center",
    },
    input: {
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "white",
        marginTop: 3,
    },
    tagBox: {
        height: 60,
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: 8,
        padding: "0.5rem",
        marginTop: 3,
        backgroundColor: "white",
        display: "flex",
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
            width: 3,
            height: 3,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,0)",
            borderRadius: "10px",
        },
    },
    errorMessage: {
        color: "red",
        fontSize: 10,
    },
    searchBox: {
        position: "absolute",
        backgroundColor: "white",
        boxShadow: "0px 0px 15px 1px grey",
        borderRadius: 6,
        width: "100%",
        zIndex: 997,
    },
    searchOptions: {
        position: "relative",
        display: "block",
        transition: "all 0.5s",
        padding: 10,
        fontSize: 14,
        borderRadius: 3,
        "&:hover": {
            backgroundColor: "grey",
            transition: "0.5s",
        },
        zIndex: 997,
        cursor: "pointer",
    },
});

const SubjectFilter = ({detectSubjectsUpdate, toggleSubjectQueryType}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const subjects = useSelector((state) => state.query.subjects)

    const [subjectSearch, setSubjectSearch] = useState()

    const [subjectOptions, setSubjectOptions] = useState([])

    const [subjectsLoading, setSubjectsLoading] = useState(true)

    const [errorMessage, setErrorMessage] = useState()

    const domain = 'https://www.bayfieldhistoricalwebmap.ca'

    useEffect( () => {
        fetch(`${domain}/api/subjects/`)
        .then(response => response.json())
        .then( (fetchedFeatures) => {
            setSubjectOptions(fetchedFeatures)
            setSubjectsLoading(false)
        })

    },[])

    const onChange = (e) => {
        setSubjectSearch(e.target.value)
        setErrorMessage(null)
        document.getElementById("subjectInput").style.backgroundColor = "white";
    }

    const onClick = (e) => {
        let prevState = subjects
        if (e.target.innerHTML) {
            const trimmedInput = e.target.innerHTML.trim();

            let duplicate = false;
            // console.log(trimmedInput, e.target.id)

            if (trimmedInput.length) {
                for (let i=0; i<subjects.length; i++){
                    if (subjects[i].id === (e.target.id)) {
                        setErrorMessage("You have already added this subject tag")
                        document.getElementById("subjectInput").style.backgroundColor = "rgb(245, 81, 66)";
                        duplicate = true
                        break;
                    }
                }
                if (!duplicate) {
                    prevState.push({name: trimmedInput, id: e.target.id})
                    dispatch(subjectsChange(prevState));
                    setSubjectSearch('');
                    document.getElementById("subjectInput").style.backgroundColor = "white";
                    detectSubjectsUpdate()
                }
            }
        }
    };



    return (
        <div className={classes.searchDiv}>
            <label className={classes.text} htmlFor="subjectInput">Subject Tags</label>
            <div className={classes.errorMessage}>{errorMessage}</div>
            <div className={classes.tagBox}>
                {subjects.map((subject) => <SubjectTag id={subject.id} name={subject.name} detectSubjectsUpdate={detectSubjectsUpdate} />)}
            </div>

            <input className={classes.input} id="subjectInput" onChange={onChange} value={subjectSearch} autoComplete="off" placeholder="Search for a subject..." />
            {!subjectsLoading && subjectSearch && subjectSearch.length > 2
                ? <div className={classes.searchBox}> {subjectOptions.filter((subject) => {
                        if (subject.subject_name.toLowerCase().includes(subjectSearch.toLowerCase()) || subject.subject_description.toLowerCase().includes(subjectSearch.toLowerCase())) {
                            return subject;
                        }
                        return null;
                    }).map((subject) =>
                        <span className={classes.searchOptions} onClick={onClick} id={subject.id} key={subject.id}>{subject.subject_name}</span>
                    )} 
                </div>
                : null
            }
            <select className={classes.input} onChange={toggleSubjectQueryType}>
                <option value='OR'>OR</option>
                <option value='AND'>AND</option>
            </select>
        </div>
    )
}

export default SubjectFilter
