import {useSelector, useDispatch} from 'react-redux';

import {descriptionUpdate} from '../../../store/actions/itemAddActions';

import {createUseStyles} from 'react-jss';
import ReactTooltip from 'react-tooltip';

const useStyles = createUseStyles({
    input: {
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 167,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "white",
        "&::-webkit-scrollbar": {
            width: 5,
            height: 5,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "whitesmoke",
            "&:hover": {
                backgroundColor: "rgb(207, 205, 204)",
            },
            borderRadius: "10px",
        },
        marginTop: 3,
        resize: "none",
    },
    errorMessage: {
        color: "red",
        fontSize: 10,
    },
});

const ItemDescription = ({error, descriptionErrorChange}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const description = useSelector((state) => state.itemAdd.description)

    const onDescriptionChange = (e) => {
        dispatch(descriptionUpdate(e.target.value))
        if (e.target.value && error) {
            descriptionErrorChange();
        }
    }

    return (
        <>
            <ReactTooltip multiline={true} />
            <label htmlFor="description" data-tip="Describe your item. We invite you to mention any people, dates, stories, and memories associated with your item.">General Description</label>
            { error
                ? <div className={classes.errorMessage}>{error}</div>
                : null
            }
            <textarea className={classes.input} id="description" value={description ? description : ''} rows="9" cols="30" onChange={onDescriptionChange}></textarea>
        </>
    )
}

export default ItemDescription