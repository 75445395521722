import { createUseStyles } from "react-jss";

import PinchZoomPan from "react-image-zoom-pan";

const useStyles = createUseStyles({
    popupModal: {
        position: "fixed",
        zIndex: 999,
        display: "block",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        overflow: "auto",
        backgroundColor: "rgba(0,0,0,0.4)",
    },
    popup: {
        backgroundColor: "#fefefe",
        borderRadius: 10,
        padding: 20,
        marginLeft: "15%",
        marginRight: "15%",
        marginTop: "5vh",
        // marginBottom: "5vh",
        // width: "65%",
        height: "80vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "top",
        justifyContent: "center",
    },
    closeButtonDivDiv: {
        display: "flex",
        justifyContent: "flex-end",
    },
    closeButtonDiv: {
        width: 25,
        height: 25,
        top: 10,
        right: 10,
        cursor: "pointer",
        "&:hover": {
            transform: "scale(1.5)",
            transition: "0.2s",
        },
    },
    imageDiv: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transformOrigin: "0 0",
        height: "80vh",
        buffer: 10,
    },
    image: {
        // width: "100%",
        height: "100%",
    },
})

const ImagePopup = ({popupImage, onCloseClick}) => {
    const classes = useStyles();

    return(
        <div className={classes.popupModal}>
            <div className={classes.popup}>
                <div className={classes.closeButtonDivDiv}>
                    <div onClick={onCloseClick} id="none" className={classes.closeButtonDiv}>
                        <svg id="none" onClick={onCloseClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="rgb(242, 73, 51)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line id="none" x1="18" y1="6" x2="6" y2="18"></line><line id="none" x1="6" y1="6" x2="18" y2="18"></line></svg>
                    </div>
                </div>
                <div className={classes.imageDiv}>
                    <PinchZoomPan maxScale={5} zoomButtons={false} position={'center'}>
                        <img className={classes.image} src={popupImage} alt="Popup" />
                    </PinchZoomPan>
                </div>
            </div>
        </div>
    )
}

export default ImagePopup