import { useState, useEffect, useCallback } from "react"

import { useParams, Redirect } from "react-router"
import { Link } from "react-router-dom"
import { titleCase } from "title-case"

import GeoJSON from 'ol/format/GeoJSON'

import ImagePopup from "../imagePopup"
import ItemDisplayContainer from "../itemDisplayContainer"

import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
    overviewDiv: {
        display: "flex",
        width: "100%",
        height: "87vh",
    },
    orgOverviewDiv: {
        display: "block",
        height: "82vh",
        width: "100%",
        padding: 10,
    },
    overviewContainer: {
        margin: 8,
        height: "35%",
        display: "flex",
        // width: "100%",
        flexDirection: "column",
        borderRadius: 10,
        backgroundColor: "rgb(113, 172, 191)",
        color: "white",
        padding: 10,
    },
    overviewInfoDiv: {
        display: "flex",
        flexDirection: "column",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            width: 5,
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "rgb(191, 191, 191)",
            opacity: 0,
            borderRadius: "10px",
        },
    },
    overviewSmallFont: {
        fontSize: 12,
        marginBottom: 3,
    },
    overviewTitleFont: {
        fontSize: 16,
        marginBottom: 3,
    },
    menuDiv: {
        display: "flex",
        justifyContent: "space-around",
        width: "100%",
        height: "8%",
    },
    menuItem: {
        width: "33%",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        backgroundColor: "rgb(113, 172, 191)",
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        marginLeft: 3,
        marginRight: 3,
        color: "white",
        "&:hover": {
            backgroundColor: "rgb(66, 132, 150)",
            fontSize: 18,
        }
    },
    menuItemSelected: {
        composes: "$menuItem",
        backgroundColor: "rgb(47, 86, 102)",
        marginLeft: 8,
    },
    menuItemRight: {
        composes: "$menuItem",
        marginRight: 8,
    },
    itemViewDiv: {
        backgroundColor: "rgb(113, 172, 191)",
        marginLeft: 8,
        marginRight: 8,
        marginBottom: 8,
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
        height: "65%",
    },
    buttonDiv: {
        height: "11%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        color: "white",
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
    },
    buttonContainerDiv: {
        zIndex: 996,
        width: "50%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgb(66, 132, 150)",
        transition: "all 0.2s ease-in-out",
        cursor: "pointer",
        borderBottom: "solid",
        borderBottomColor: "rgb(66, 132, 150)",
        "&:hover": {
            backgroundColor: "rgb(83, 156, 176)",
            borderBottomColor: "rgb(83, 156, 176)",
            fontSize: 20,
        }
    },
    buttonContainerLeft: {
        composes: "$buttonContainerDiv",
        borderBottomLeftRadius: 8,
    },
    buttonContainerRight: {
        composes: "$buttonContainerDiv",
        borderBottomRightRadius: 8,
    },
    collectionContainer: {
        display: "flex",
        flexDirection: "column",
        height: "94%",
        backgroundColor: "rgb(113, 172, 191)",
        borderTop: "solid",
        borderWidth: 6,
        borderColor: "rgb(113, 172, 191)",
    },
    menuContainer: {
        display: "flex",
        flexDirection: "column",
        height: "95%",
        backgroundColor: "rgb(113, 172, 191)",
        borderTop: "solid",
        borderWidth: 6,
        borderColor: "rgb(113, 172, 191)",
        padding: 10,
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
        marginBottom: 8,
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            width: 5,
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "rgb(191, 191, 191)",
            opacity: 0,
            borderRadius: "10px",
        },
    },
    imageRow: {
        height: "100%",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
    },
    imageMiddleRow: {
        height: "100%",
        display: "grid",
        gridTemplateColumns: "33% 33% 33%"
    },
    imageDiv: {
        display: "flex",
        justifyContent: "space-around",
        overflowX: "hidden",
        overflowY: "hidden",
        // "&::-webkit-scrollbar": {
        //     width: 5,
        // },
        // "&::-webkit-scrollbar-thumb": {
        //     background: "grey",
        //     borderRadius: "10px",
        // },
        alignItems: "center",
    },
    table: {
        borderCollapse: "collapse",
        width: "100%",
        tableLayout: "fixed",
        "& > :nth-child(2n)": {
            backgroundColor: "rgb(230, 230, 230)",
        },
        "& > :nth-child(2n-1)": {
            backgroundColor: "rgb(210, 210, 210)",
        }
    },
    tableCells: {
        paddingRight: 5,
        paddingLeft: 5,
    },
    tableLeftCells: {
        composes: "$tableCells",
        width: 80,
    },
    font: {
        fontSize: 12,
        color: "black",
    },
    fontBold: {
        composes: "$font",
        fontWeight: 500,
    },
    fontLink: {
        composes: "$font",
        textDecoration: "underline",
        cursor: "pointer",
    },
    tagDiv: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
    },
})

const OrgOverview = () => {

    const classes = useStyles();

    const { id } = useParams();

    const [org, setOrg] = useState()

    const [page, setPage] = useState(1)
    const [pages, setPages] = useState()

    const [items, setItems] = useState([])
    const [itemsLoading, setItemsLoading] = useState(true)

    const [menu, setMenu] = useState("items")

    const [altNameList, setAltNameList] = useState()

    const makeList = (array) => {
        let string = ''
        try {
            for (let i=0; i<(array.length - 1); i++) {
                string = string + titleCase(array[i]) + ', '
            }
            string = string + titleCase(array[(array.length-1)])
            return string
        }
        catch {
            return null
        }
    }

    const domain = 'https://www.bayfieldhistoricalwebmap.ca'

    useEffect(() => {
        fetch(`${domain}/api/organization/${id}/`)
            .then(response => {
                if (response.status !== 200) {
                    setOrg(null)
                    return null;
                }
                response.json()
                    .then( (fetchedFeatures) => {
                        const altNames = fetchedFeatures.altnames
                        const altNameString = makeList(altNames)
                        setAltNameList(altNameString)
                        setOrg(fetchedFeatures)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            })
            .catch(() => {
                setOrg(null)
                return(<Redirect to="/" />)
            })
    }, [id])


    useEffect(() => {
        fetch(`${domain}/api/items/?page=${page}&page_size=5&organizations=${[id]}&visibility=published`)
            .then(response => {
                if (response.status !== 200) {
                    return;
                }
                response.json().then( (fetchedFeatures) => {
                    if (fetchedFeatures.results.length > 0) {
                        const features = fetchedFeatures.results.filter(item => item.visibility === "published")
                        setItems(features)
                        setPages(Math.ceil(fetchedFeatures.count/10))
                        setItemsLoading(false)
                    }
                    else {
                        setItems([])
                        setPages(1)
                        setItemsLoading(false)
                    }
                })
                .catch(() => {
                    setItems(null)
                    setItemsLoading(false)
                })
            })
    }, [page, id])

    const onClick = (e) => {
        if (e.target.id === 'previous'){
            setPage(page-1)
        }
        else if (e.target.id === 'next'){
            setPage(page+1)
        }
        setItemsLoading(true)
    }

    const [locations, setLocations] = useState([])

    async function getLocations(id) {
        const res = await fetch(`${domain}/api/organizations/location/?organization=${id}`)
        const res_json = await res.json()
        const wktOptions = {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        }
        for (let i=0; i<res_json.length; i++) {
            const location_res = await fetch(`${domain}/api/locations/?search=${res_json[i].location}`)
            const location_json = await location_res.json()
            const parsedFeatures = new GeoJSON().readFeatures(location_json, wktOptions)
            res_json[i].location = parsedFeatures[0]
        }
        return res_json
    }

    useEffect(() => {
        getLocations(id)
            .then(res => {
                setLocations(res)
                console.log(res)
            })
    }, [id])

    const [employees, setEmployees] = useState([])

    async function getEmployees(id) {
        const res = await fetch(`${domain}/api/employment/?organization=${id}`)
        const res_json = await res.json()
        for (let i=0; i<res_json.length; i++) {
            const person_res = await fetch(`${domain}/api/person/${res_json[i].person}/`)
            const person_json = await person_res.json()
            res_json[i].person = person_json
        }
        return res_json
    }

    useEffect(() => {
        getEmployees(id)
            .then(res => {
                setEmployees(res)
            })
    }, [id])

    const onMenuClick = (e) => {
        document.getElementById(menu).style.backgroundColor = "rgb(113, 172, 191)";
        setMenu(e.target.id)
        document.getElementById(e.target.id).style.backgroundColor = "rgb(47, 86, 102)";
    }

    const [popupImage, setPopupImage] = useState();

    const togglePopup = useCallback((e) => {
        setPopupImage(e.target.getAttribute('src'))
    })

    const onCloseClick = useCallback(() => {
        setPopupImage()
    })

    return(
        <div>
            { popupImage ?
                <ImagePopup popupImage={popupImage} onCloseClick={onCloseClick} />
                : null
            }
            <div className={classes.overviewDiv}>
            <div className={classes.orgOverviewDiv}>
                <div className={classes.overviewContainer}>
                    <div className={classes.overviewInfoDiv}>
                        <span className={classes.overviewTitleFont}>
                            {org ? org.name : null}
                        </span>
                        <span className={classes.overviewSmallFont}>{altNameList ? altNameList : null}</span>
                        {org && org.date_opened && org.date_closed ?
                            <span className={classes.overviewSmallFont}>{org.date_opened.toString().substr(0,4) + ' - ' + org.date_closed.toString().substr(0,4)}</span>
                            : org && org.date_opened ? 
                            <span className={classes.overviewSmallFont}>{org.date_opened.toString().substr(0,4) + ' - '}</span>
                            : null
                        }
                        <br />
                        {org && org.description ?
                            <span className={classes.overviewSmallFont}>{org.description}</span>
                            : <span className={classes.overviewSmallFont}>No description for this organization.</span>
                        }
                    </div>
                </div>
                <div className={classes.menuDiv}>
                    <span className={classes.menuItemSelected} onClick={onMenuClick} id="items">Items</span>
                    <span className={classes.menuItem} onClick={onMenuClick} id="locations">Locations</span>
                    <span className={classes.menuItemRight} onClick={onMenuClick} id="people">People</span>
                </div>
            
                <div className={classes.itemViewDiv}>
                { menu === "items" ?
                        <>
                            <div className={classes.collectionContainer}>
                                <ItemDisplayContainer loading={itemsLoading} items={items} togglePopup={togglePopup} />
                            </div>
                            <div className={classes.buttonDiv}>
                            { page > 1
                                ? <span className={classes.buttonContainerLeft} id='previous' onClick={onClick}>Previous</span>
                                : <span className={classes.buttonContainerLeft}></span>
                            }
                            { page < pages
                                ? <span className={classes.buttonContainerRight} id='next' onClick={onClick}>Next</span>
                                : <span className={classes.buttonContainerRight}></span>
                            }
                            </div>
                        </>
                    : menu === "locations" ? 
                    <div className={classes.menuContainer}>
                        <table className={classes.table}>
                            <tr>
                                <td className={classes.tableCells}>
                                    <div className={classes.tagDiv}>
                                        <p className={classes.fontBold}>Location</p>
                                    </div>
                                </td>
                                <td className={classes.tableCells}>
                                    <div className={classes.tagDiv}>
                                        <p className={classes.fontBold}>Date</p>
                                    </div>
                                </td>
                            </tr>
                            {locations ? locations.map(location => {
                                return(
                                    <tr>
                                        <td className={classes.tableCells}>
                                            {location.location ?
                                                <div className={classes.tagDiv}>
                                                    <Link className={classes.fontLink} to={`/zone-overview/${location.location.values_.pk}/`}>{location.location.values_.name}</Link>
                                                </div>
                                            : null}
                                        </td>
                                        <td className={classes.tableCells}>
                                            <div className={classes.tagDiv}>
                                                <p className={classes.font}>{location.date_start && location.date_end ? location.date_start.toString().substr(0,4) + ' - ' + location.date_end.toString().substr(0,4) : location.date_start ? location.date_start.toString().substr(0,4) + ' - ' : null}</p>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) : null}
                        </table>
                    </div>
                    : menu === "people" ?
                    <div className={classes.menuContainer}>
                        <table className={classes.table}>
                            <tr>
                                <td className={classes.tableCells}>
                                    <div className={classes.tagDiv}>
                                        <p className={classes.fontBold}>Person</p>
                                    </div>
                                </td>
                                <td className={classes.tableCells}>
                                    <div className={classes.tagDiv}>
                                        <p className={classes.fontBold}>Date</p>
                                    </div>
                                </td>
                            </tr>
                            {employees ? employees.map(employee => {
                                return(
                                    <tr>
                                        <td className={classes.tableCells}>
                                            {employee.person ?
                                                <div className={classes.tagDiv}>
                                                    <Link className={classes.fontLink} to={`/person-overview/${employee.person.id}/`}>{employee.person.middle_name ? employee.person.first_name + ' ' + employee.person.middle_name + ' ' + employee.person.surname : employee.person.first_name + ' ' + employee.person.surname}</Link>
                                                </div>
                                            : null}
                                        </td>
                                        <td className={classes.tableCells}>
                                            <div className={classes.tagDiv}>
                                                <p className={classes.font}>{employee.date_start && employee.date_end ? employee.date_start.toString().substr(0,4) + ' - ' + employee.date_end.toString().substr(0,4) : employee.date_start ? employee.date_start.toString().substr(0,4) + ' - ' : null}</p>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) : null}
                        </table>
                    </div>
                    : null
                }
                </div>
            </div>
        </div>
        </div>
    )
}

export default OrgOverview