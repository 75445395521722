import { useState, useEffect, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"

import { searchTermChange } from "../store/actions/queryActions";

//Import components
import PublicHeader from '../headers/PublicHeader';
import SearchBar from "../components/searchbar/searchbar";
import ItemDisplayContainer from '../components/itemDisplayContainer';
import ImagePopup from "../components/imagePopup";

//Import filters
import TimeSlider from "../components/sidebar/timeSlider/timeSlider";
import CollectionFilter from "../components/filters/collectionFilter";
import OrgFilter from "../components/filters/orgFilter";
import PeopleFilter from "../components/filters/peopleFilter";
import SubjectFilter from "../components/filters/subjectFilter";
import TypeFilter from "../components/filters/typeFilter";

import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
    allDiv: {
        position: "absolute",
        width: "100%",
    },
    collectionDiv: {
        display: "grid",
        gridTemplateColumns: "25% 75%",
        height: "87vh",
        overflowX: "hidden",
        overflowY: "hidden",
    },
    filterDiv: {
        padding: 15,
        paddingRight: 20,
        overflowY: "scroll",
        backgroundColor: "rgba(218, 224, 235, 0.75)",
        "&::-webkit-scrollbar": {
            width: 5,
        },
        "&::-webkit-scrollbar-thumb": {
            background: "rgba(66, 132, 150, 0.6)",
            borderRadius: "10px",
        },
        direction: "rtl",
    },
    displayDiv: {
        height: "100%",
        width: "100%",
    },
    searchBarDiv: {
        width: "100%",
        height: "12vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "rgb(81, 162, 184)",
    },
    itemContainerDiv: {
        width: "100%",
        height: "70vh",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            width: 10,
        },
        "&::-webkit-scrollbar-thumb": {
            background: "rgba(66, 132, 150, 0.6)",
            borderRadius: "10px",
        },
    },
    pageFlipDiv: {
        width: "100%",
        height: "5vh",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    pageFlipButton: {
        width: "50%",
        color: "white",
        cursor: "pointer",
        backgroundColor: "rgb(66, 132, 150)",
        "&:hover": {
            backgroundColor: "rgb(81, 162, 184)",
        },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    filterContainer: {
        backgroundColor: "rgb(66, 132, 150)",
        borderRadius: 10,
        padding: 15,
        direction: "ltr",
    },
    filterContainerTime: {
        composes: "$filterContainer",
        height: "20%",
    },
    filterFont: {
        display: "flex",
        justifyContent: "center",
        fontSize: 20,
        color: "black",
        margin: 5,
    },
    resultCount: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "3vh",
        color: "white",
        paddingBottom: 3,
        paddingLeft: 15,
    },
})

const Collection = () => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const [items, setItems] = useState()
    const [loading, setLoading] = useState(true)

    const timeStart = useSelector((state) => state.query.timeStart)
    const timeEnd = useSelector((state) => state.query.timeEnd)
    const people = useSelector((state) => state.query.people)
    const organizations = useSelector((state) => state.query.organizations)
    const subjects = useSelector((state) => state.query.subjects)
    const type = useSelector((state) => state.query.type)
    const collection = useSelector((state) => state.query.collection)

    const [peopleIds, setPeopleIds] = useState([])
    const [orgIds, setOrgIds] = useState([])
    const [subjectIds, setSubjectIds] = useState([])

    const [page, setPage] = useState(1)
    const [pages, setPages] = useState()

    //Callbacks
    //Get search term
    const searchTerm = useSelector(state => state.query.searchTerm)
    // const [searchTerm, setSearchTerm] = useState()

    const searchUpdate = useCallback((search) => {
        dispatch(searchTermChange(search))
    })

    //Detect updates

    const [peopleUpdate, setPeopleUpdate] = useState(false)

    const detectPeopleUpdate = useCallback(() => {
        setPeopleUpdate(!peopleUpdate)
    })

    const [subjectUpdate, setSubjectUpdate] = useState(false)

    const detectSubjectUpdate = useCallback(() => {
        setSubjectUpdate(!subjectUpdate)
    })

    const [collectionUpdate, setCollectionUpdate] = useState(false)

    const detectCollectionUpdate = useCallback(() => {
        setCollectionUpdate(!collectionUpdate)
    })

    const [orgUpdate, setOrgUpdate] = useState(false)

    const detectOrgUpdate = useCallback(() => {
        setOrgUpdate(!orgUpdate)
    })

    //Get query type information (AND vs. OR)

    const [peopleQueryType, setPeopleQueryType] = useState('OR')
    const togglePeopleQueryType = useCallback((e) => {
        if (e.target.value === 'OR' || e.target.value === 'AND') {
            setPeopleQueryType(e.target.value)
        }
        else {
            return;
        }
    })

    const [subjectQueryType, setSubjectQueryType] = useState('OR')
    const toggleSubjectQueryType = useCallback((e) => {
        if (e.target.value === 'OR' || e.target.value === 'AND') {
            setSubjectQueryType(e.target.value)
        }
        else {
            return;
        }
    })

    async function recursiveCollectionSearch(id) {
        const domain = 'https://www.bayfieldhistoricalwebmap.ca'
        let collectionsArray = [parseInt(id)]
        let searchNum = 0
        //Add child collections to array
        if (id) {
            while (searchNum < collectionsArray.length) {
                const collectionRes = await fetch(`${domain}/api/collections/?parent_collection=${collectionsArray[searchNum]}&visibility=published`)
                const collectionJson = await collectionRes.json()
                for (let i=0; i<collectionJson.length; i++) {
                    collectionsArray.push(collectionJson[i].id)
                }
                searchNum = searchNum + 1
            }
            return collectionsArray
        }
    }

    //Get collections
    const [collections, setCollections] = useState([])

    useEffect(() => {
        recursiveCollectionSearch(collection)
            .then(res => {
                setCollections(res)
            })
    }, [collection])

    //Fetch
    useEffect(() => {
        //Initiate loading
        if (!loading) {
            setLoading(true)
        }

        //Set page to 1
        setPage(1)

        //Extract ids from people, organizations, and subjects
        let peopleNums = []
        for (let i=0; i<people.length; i++) {
            peopleNums.push(people[i].id)
        }
        setPeopleIds(peopleNums)

        let subjectNums = []
        for (let i=0; i<subjects.length; i++) {
            subjectNums.push(subjects[i].id)
        }
        setSubjectIds(subjectNums)

        let orgNums = []
        for (let i=0; i<organizations.length; i++) {
            orgNums.push(organizations[i].id)
        }
        setOrgIds(orgNums)

        const domain = 'https://www.bayfieldhistoricalwebmap.ca'

        let url = `${domain}/api/items/?page=1&search=${searchTerm}&date=${timeStart.toString() + timeEnd.toString()}&organizations=${orgNums}&type=${type}&visibility=published`

        //OR vs. AND query for subjects and people
        if (peopleQueryType === 'AND') {
            url = url + `&people_and=${peopleNums}`
        }
        else if (peopleQueryType === 'OR') {
            url = url + `&people_or=${peopleNums}`
        }

        if (subjectQueryType === 'AND') {
            url = url + `&subjects_and=${subjectNums}`
        }
        else if (subjectQueryType === 'OR') {
            url = url + `&subjects_or=${subjectNums}`
        }

        if (collections) {
            url = url + `&collections=${collections}`
        }

        //Fetch items based on search term and filters
        fetch(url)
            .then(response => response.json())
            .then( (fetchedFeatures) => {
                if (fetchedFeatures.results.length > 0) {
                    const features = fetchedFeatures.results.filter(item => item.visibility === "published")
                    setItems(features)
                    setResultCount(fetchedFeatures.count)
                    setPages(Math.ceil(fetchedFeatures.count/10))
                    setLoading(false)
                }
                else {
                    setItems([])
                    setPages(1)
                    setLoading(false)
                    setResultCount(0)
                }
            })

    }, [searchTerm, timeStart, timeEnd, peopleUpdate, subjectUpdate, orgUpdate, type, collections, peopleQueryType, subjectQueryType])

    useEffect(() => {
        const domain = 'https://www.bayfieldhistoricalwebmap.ca'
        if (!loading) {
            setLoading(true)
        }
        if (page > 0 && page <= pages) {
            let url = `${domain}/api/items/?page=${page}&search=${searchTerm}&date=${timeStart.toString() + timeEnd.toString()}&organizations=${orgIds}&collection=${collection}&type=${type}`

            //OR vs. AND query for subjects and people
            if (peopleQueryType === 'AND') {
                url = url + `&people_and=${peopleIds}`
            }
            else if (peopleQueryType === 'OR') {
                url = url + `&people_or=${peopleIds}`
            }

            if (subjectQueryType === 'AND') {
                url = url + `&subjects_and=${subjectIds}`
            }
            else if (subjectQueryType === 'OR') {
                url = url + `&subjects_or=${subjectIds}`
            }

            //Fetch items based on search term and filters
            console.log(url)
            fetch(url)
                .then(response => response.json())
                .then( (fetchedFeatures) => {
                    if (fetchedFeatures.results.length > 0) {
                        const features = fetchedFeatures.results.filter(item => item.visibility === "published")
                        setItems(features)
                        setResultCount(fetchedFeatures.count)
                        setPages(Math.ceil(fetchedFeatures.count/10))
                        setLoading(false)
                    }
                    else {
                        setItems([])
                        setPages(1)
                        setLoading(false)
                        setResultCount(0)
                    }
                })
        }
    }, [page])

    //Page Flipper
    const flipPage = (e) => {
        if (e.target.id === 'next' && page < pages) {
            setPage(page + 1)
        }
        else if (e.target.id === 'previous' && page > 1) {
            setPage(page - 1)
        }
    }

    //Popups
    const [popupImage, setPopupImage] = useState();

    const togglePopup = useCallback((e) => {
        setPopupImage(e.target.getAttribute('src'))
    })

    const onCloseClick = useCallback(() => {
        setPopupImage()
    })

    //Results
    const [resultCount, setResultCount] = useState(0)

    return(
        <div className={classes.allDiv}>
            {/* Popups */}
            { popupImage ?
                <ImagePopup loading={loading} popupImage={popupImage} onCloseClick={onCloseClick} />
                : null
            }
            <PublicHeader />
            <div className={classes.collectionDiv}>
                <div className={classes.filterDiv}>
                    <span className={classes.filterFont}>Refine Your Search</span>
                    <div className={classes.filterContainerTime}>
                        <TimeSlider />
                    </div>
                    <span className={classes.filterFont}>&</span>
                    <div className={classes.filterContainer}>
                        <PeopleFilter detectPeopleUpdate={detectPeopleUpdate} togglePeopleQueryType={togglePeopleQueryType} />
                    </div>
                    <span className={classes.filterFont}>&</span>
                    <div className={classes.filterContainer}>
                        <SubjectFilter detectSubjectsUpdate={detectSubjectUpdate} toggleSubjectQueryType={toggleSubjectQueryType} />
                    </div>
                    <span className={classes.filterFont}>&</span>
                    <div className={classes.filterContainer}>
                        <OrgFilter detectOrgUpdate={detectOrgUpdate} />
                    </div>
                    <span className={classes.filterFont}>&</span>
                    <div className={classes.filterContainer}>
                        <TypeFilter />
                    </div>
                    <span className={classes.filterFont}>&</span>
                    <div className={classes.filterContainer}>
                        <CollectionFilter detectCollectionUpdate={detectCollectionUpdate} />
                    </div>

                </div>
                <div className={classes.displayDiv}>
                    <div className={classes.searchBarDiv}>
                        <SearchBar searchUpdate={searchUpdate} searchTerm={searchTerm} />
                        <span className={classes.resultCount}>{resultCount + ' results'}</span>
                    </div>
                    <div className={classes.itemContainerDiv}>
                        <ItemDisplayContainer items={items} loading={loading} togglePopup={togglePopup} detectSubjectUpdate={detectSubjectUpdate} />
                    </div>
                    <div className={classes.pageFlipDiv}>
                        {/* Page flipping */}
                        { page > 1
                            ? <span className={classes.pageFlipButton} id='previous' onClick={flipPage}>Previous</span>
                            : <span className={classes.pageFlipButton}></span>
                        }
                        { page < pages
                            ? <span className={classes.pageFlipButton} id='next' onClick={flipPage}>Next</span>
                            : <span className={classes.pageFlipButton}></span>
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Collection;