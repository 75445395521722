import { useState, useEffect } from "react"

import { useParams } from "react-router"

import PersonDisplay from "./personDisplay"

import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    imageDiv: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxHeight: "100%",
    },
})

const ParentDisplay = ({type}) => {

    const classes = useStyles();

    const {id} = useParams();

    const [parents, setParents] = useState([])
    const [parentsLoading, setParentsLoading] = useState(false)

    async function parent_array_async(personId) {
        const domain = 'https://www.bayfieldhistoricwebmap.ca'
        let parent_response
        let parent
        let parentsArray = []
        let relationUrl = ''
        let parentUrl = ''
        if (type === 'parents') {
            relationUrl = `${domain}/api/people/relationships/?person2=${personId}&relationship_type=parent`
        }
        else if (type === 'children') {
            relationUrl = `${domain}/api/people/relationships/?person1=${personId}&relationship_type=parent`
        }
        const relationships_response = await fetch(relationUrl)
        const relationships = await relationships_response.json()

        for (let i=0; i<relationships.length; i++) {
            if(type === 'parents') {
                parentUrl = relationships[i].person1
            }
            else if(type === 'children') {
                parentUrl = relationships[i].person2
            }
            parent_response = await fetch(`${domain}/api/person/${parentUrl}/`)
            parent = await parent_response.json()
            parentsArray.push(parent)
        }
        setParents(parentsArray)
        setParentsLoading(false)
    }

    useEffect(() => {
        if (id) {
            parent_array_async(id)
        }
    }, [id])

    return (
        <>
            {parents.length > 0 && !parentsLoading ? 
                parents.map(parent => {
                    return(
                    <div className={classes.imageDiv}>
                        <PersonDisplay person={parent} />
                    </div>
                    )
                })
                : <span>No {type}.</span>
            }
        </>
    )
}

export default ParentDisplay