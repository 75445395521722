import {useState, useEffect, useCallback} from 'react';

import { Redirect } from 'react-router';

import { Link } from 'react-router-dom';

import { createUseStyles } from 'react-jss';

import { titleCase } from "title-case";

import ItemDisplayContainer from './itemDisplayContainer';
import ImagePopup from './imagePopup';
import OverviewMap from "./overviews/overviewMap";

const useStyles = createUseStyles({
    zoneOverviewDiv: {
        display: "block",
        height: "82vh",
        width: "100%",
    },
    overviewContainer: {
        height: "35%",
        display: "grid",
        gridTemplateColumns: "70% 30%",
        borderRadius: 10,
        backgroundColor: "rgb(113, 172, 191)",
        color: "white",
        margin: 8,
        padding: 10,
    },
    overviewInfoDiv: {
        display: "flex",
        flexDirection: "column",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            width: 5,
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "rgb(191, 191, 191)",
            opacity: 0,
            borderRadius: "10px",
        },
    },
    overviewSmallFont: {
        fontSize: 12,
        marginBottom: 3,
    },
    overviewTitleFont: {
        fontSize: 16,
        marginBottom: 3,
    },
    menuDiv: {
        display: "flex",
        justifyContent: "space-around",
        width: "100%",
        height: "8%",
    },
    menuItem: {
        width: "25%",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        backgroundColor: "rgb(113, 172, 191)",
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        marginLeft: 3,
        marginRight: 3,
        color: "white",
        "&:hover": {
            backgroundColor: "rgb(66, 132, 150)",
            fontSize: 18,
        }
    },
    menuItemSelected: {
        composes: "$menuItem",
        backgroundColor: "rgb(47, 86, 102)",
        marginLeft: 8,
    },
    menuItemRight: {
        composes: "$menuItem",
        marginRight: 8,
    },
    itemViewDiv: {
        backgroundColor: "rgb(113, 172, 191)",
        marginLeft: 8,
        marginRight: 8,
        marginBottom: 8,
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
        height: "65%",
    },
    buttonDiv: {
        height: "11%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        color: "white",
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
    },
    buttonContainerDiv: {
        zIndex: 996,
        width: "50%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgb(66, 132, 150)",
        transition: "all 0.2s ease-in-out",
        cursor: "pointer",
        borderBottom: "solid",
        borderBottomColor: "rgb(66, 132, 150)",
        "&:hover": {
            backgroundColor: "rgb(83, 156, 176)",
            borderBottomColor: "rgb(83, 156, 176)",
            fontSize: 20,
        }
    },
    buttonContainerLeft: {
        composes: "$buttonContainerDiv",
        borderBottomLeftRadius: 8,
    },
    buttonContainerRight: {
        composes: "$buttonContainerDiv",
        borderBottomRightRadius: 8,
    },
    collectionContainer: {
        display: "flex",
        flexDirection: "column",
        height: "65vh",
        backgroundColor: "rgb(113, 172, 191)",
        borderTop: "solid",
        borderWidth: 6,
        borderColor: "rgb(113, 172, 191)",
    },
    menuContainer: {
        display: "flex",
        flexDirection: "column",
        height: "95%",
        backgroundColor: "rgb(113, 172, 191)",
        borderTop: "solid",
        borderWidth: 6,
        borderColor: "rgb(113, 172, 191)",
        padding: 10,
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
        marginBottom: 8,
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            width: 5,
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "rgb(191, 191, 191)",
            opacity: 0,
            borderRadius: "10px",
        },
    },
    plaqueDiv: {
        margin: 10,
        padding: 20,
        backgroundColor: "rgba(200, 200, 200, 0.8)",
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
    },
    plaqueTitle: {
        fontSize: 16,
        color: "black",
        marginBottom: 5,
    },
    plaqueText: {
        fontSize: 12,
        color: "black",
    },
    heritageRegisterLink: {
        color: "black",
        cursor: "pointer",
        marginLeft: 20
    },
    table: {
        borderCollapse: "collapse",
        width: "100%",
        tableLayout: "fixed",
        "& > :nth-child(2n)": {
            backgroundColor: "rgb(230, 230, 230)",
        },
        "& > :nth-child(2n-1)": {
            backgroundColor: "rgb(210, 210, 210)",
        }
    },
    tableCells: {
        padding: 5,
    },
    tableLeftCells: {
        composes: "$tableCells",
        width: "20%",
    },
    font: {
        fontSize: 12,
        color: "black",
    },
    fontBold: {
        composes: "$font",
        fontWeight: 600,
    },
    fontLink: {
        composes: "$font",
        textDecoration: "underline",
        cursor: "pointer",
    },
    whiteText: {
        color: "white",
    },
    imageDiv: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transformOrigin: "0 0",
        height: "100%",
    },
})


const ZoneOverview = ({id}) => {

    const classes = useStyles();

    const [location, setLocation] = useState();
    const [loading, setLoading] = useState(true);

    const [lots, setLots] = useState([]);
    const [lotsLoading, setLotsLoading] = useState(true);

    const [lotList, setLotList] = useState('');

    const [page, setPage] = useState(1)
    const [pages, setPages] = useState()

    const [items, setItems] = useState([])
    const [itemsLoading, setItemsLoading] = useState(true)

    const [menu, setMenu] = useState("items")

    const [heritageRegister, setHeritageRegister] = useState()
    const [heritageLoading, setHeritageLoading] = useState(true)

    const [altNameList, setAltNameList] = useState()

    const [locationDescription, setLocationDescription] = useState()
    const [descriptionLoading, setDescriptionLoading] = useState(true)

    const [plaques, setPlaques] = useState()
    const [plaquesLoading, setPlaquesLoading] = useState(true)


    const makeList = (array) => {
        let string = ''
        try {
            for (let i=0; i<(array.length - 1); i++) {
                string = string + titleCase(array[i]) + ', '
            }
            string = string + titleCase(array[(array.length-1)])
            return string
        }
        catch {
            return null
        }
    }
    const domain = 'https://www.bayfieldhistoricalwebmap.ca'

    async function getLocations(location) {
        if (location && location.lots.length > 0) {
            const res = await fetch(`${domain}/api/lots/ownership/?lot=${location.lots}`)
            const res_json = await res.json()
            console.log(res_json)
            for (let i=0; i<res_json.length; i++) {
                const person_res = await fetch(`${domain}/api/person/${res_json[i].person}/`)
                const person_json = await person_res.json()
                res_json[i].people = person_json
            }
            return res_json
        }
        else {
            return [];
        }
    }

    const [landOwnership, setLandOwnership] = useState([])

    useEffect( () => {
        fetch(`${domain}/api/location/${id}/`)
        .then(response => {
            if (response.status != 200) {
                setLocation("failed")
                return;
            }
            response.json().then( (fetchedFeatures) => {
                setLocation(fetchedFeatures)
                const altNames = fetchedFeatures.altnames
                const altNameString = makeList(altNames)
                setAltNameList(altNameString)
                fetch(`${domain}/api/lots/?search=${id}`)
                    .then(response => {
                        if (response.status != 200) {
                            return;
                        }
                        response.json().then( (fetchedFeatures) => {
                            setLots(fetchedFeatures)
                            setLotsLoading(false)
                            const lotNumbers = fetchedFeatures.map(feature => feature.lotNum)
                            const lotString = makeList(lotNumbers)
                            setLotList(lotString)
                        })
                    })
                    .catch(() => {
                        setLots(null)
                    })
                fetch(`${domain}/api/heritage-information/?location=${id}`)
                    .then(response => {
                        if (response.status != 200) {
                            return;
                        }
                        response.json().then( (fetchedFeatures) => {
                            setHeritageRegister(fetchedFeatures)
                            setHeritageLoading(false)
                        })
                    })
                    .catch(() => {
                        setHeritageRegister(null)
                    })
                fetch(`${domain}/api/locationdescription/?search=${id}`)
                    .then(response => {
                        if (response.status != 200) {
                            return;
                        }
                        response.json().then( (fetchedFeatures) => {
                            setLocationDescription(fetchedFeatures)
                            setDescriptionLoading(false)
                        })
                    })
                    .catch(() => {
                        setLocationDescription(null)
                    })
                fetch(`${domain}/api/plaques/?search=${id}`)
                    .then(response => {
                        if (response.status != 200) {
                            return;
                        }
                        response.json().then( (fetchedFeatures) => {
                            setPlaques(fetchedFeatures)
                            setPlaquesLoading(false)
                        })
                    })
                    .catch(() => {
                        setPlaques(null)
                    })
                console.log(fetchedFeatures)
                getLocations(fetchedFeatures)
                    .then(res => {
                        setLandOwnership(res)
                        console.log(res)
                    })
                setLoading(false)
            })
        })
        .catch(() => {
            setLocation(null)
            return(<Redirect to="/" />)
        })

    },[])

    useEffect(() => {
        fetch(`${domain}/api/items/?page=${page}&page_size=5&location=${id}&visibility=published`)
            .then(response => {
                if (response.status != 200) {
                    return;
                }
                response.json().then( (fetchedFeatures) => {
                    if (fetchedFeatures.results.length > 0) {
                        const features = fetchedFeatures.results.filter(item => item.visibility === "published")
                        setItems(features)
                        setPages(Math.ceil(fetchedFeatures.count/10))
                        setItemsLoading(false)
                    }
                    else {
                        setItems([])
                        setPages(1)
                        setItemsLoading(false)
                    }
                })
                .catch(() => {
                    setItems(null)
                    setItemsLoading(false)
                })
            })
    }, [page])

    const onClick = (e) => {
        if (e.target.id === 'previous'){
            setPage(page-1)
        }
        else if (e.target.id === 'next'){
            setPage(page+1)
        }
        setItemsLoading(true)
    }

    const onMenuClick = (e) => {
        document.getElementById(menu).style.backgroundColor = "rgb(113, 172, 191)";
        setMenu(e.target.id)
        document.getElementById(e.target.id).style.backgroundColor = "rgb(47, 86, 102)";
    }

    const [popupImage, setPopupImage] = useState();

    const togglePopup = useCallback((e) => {
        setPopupImage(e.target.getAttribute('src'))
    })

    const onCloseClick = useCallback(() => {
        setPopupImage()
    })

    const [orgLocations, setOrgLocations] = useState([])

    async function getOrgLocations(id) {
        const res = await fetch(`${domain}/api/organizations/location/?location=${id}`)
        const res_json = await res.json()
        for (let i=0; i<res_json.length; i++) {
            const org_res = await fetch(`${domain}/api/organization/${res_json[i].organization}/`)
            const org_json = await org_res.json()
            res_json[i].organization = org_json
        }
        return res_json
    }

    useEffect(() => {
        getOrgLocations(id)
            .then(res => {
                setOrgLocations(res)
                console.log(res)
            })
    }, [id])

    return(
        <div className={classes.zoneOverviewDiv}>
            { popupImage ?
                <ImagePopup popupImage={popupImage} onCloseClick={onCloseClick} />
                : null
            }
            <div className={classes.overviewContainer}>
                <div className={classes.overviewInfoDiv}>
                    <span className={classes.overviewTitleFont}>{location ? location.name : null}</span>
                    <span className={classes.overviewSmallFont}>{location ? altNameList : null}</span>
                    { !lotsLoading && lots.length > 1 ?
                        <span className={classes.overviewSmallFont}>{"Lots " + lotList}</span>
                        : !lotsLoading && lots.length == 1 ?
                        <span className={classes.overviewSmallFont}>{"Lot " + lots[0].lotNum}</span>
                        : null
                    }
                    <br />
                    {!descriptionLoading && locationDescription.description ?
                        <span className={classes.overviewSmallFont}>{locationDescription.description}</span>
                        : <span className={classes.overviewSmallFont}>No description for this location.</span>
                    }
                </div>
                {!loading ?
                    <OverviewMap id={id} />
                    : null
                }
            </div>
            <div className={classes.menuDiv}>
                <span className={classes.menuItemSelected} onClick={onMenuClick} id="items">Items</span>
                <span className={classes.menuItem} onClick={onMenuClick} id="heritage">Heritage Information</span>
                <span className={classes.menuItem} onClick={onMenuClick} id="ownership">Land Ownership</span>
                <span className={classes.menuItemRight} onClick={onMenuClick} id="businesses">Organizations</span>
            </div>
            <div className={classes.itemViewDiv}>
            { menu === "items" ?
                    <>
                        <div className={classes.collectionContainer}>
                            <ItemDisplayContainer loading={itemsLoading} items={items} togglePopup={togglePopup} />
                        </div>
                        <div className={classes.buttonDiv}>
                        { page > 1
                            ? <span className={classes.buttonContainerLeft} id='previous' onClick={onClick}>Previous</span>
                            : <span className={classes.buttonContainerLeft}></span>
                        }
                        { page < pages
                            ? <span className={classes.buttonContainerRight} id='next' onClick={onClick}>Next</span>
                            : <span className={classes.buttonContainerRight}></span>
                        }
                        </div>
                    </>
                : menu === "heritage" ? 
                <div className={classes.menuContainer}>
                    <span className={classes.whiteText}>Heritage Information</span>
                    {heritageRegister && heritageRegister.length > 0 && !heritageLoading ?
                        heritageRegister.map((entry) => {
                            return(
                                <>
                                    <br />
                                    <table className={classes.table}>
                                        <tr>
                                            <td className={classes.tableLeftCells}>
                                                <div className={classes.tagDiv}>
                                                    <p className={classes.fontBold}>Architectural Style</p>
                                                </div>
                                            </td>
                                            <td className={classes.tableCells}>
                                                <div className={classes.tagDiv}>
                                                    <p className={classes.font}>{entry.architectural_style}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={classes.tableLeftCells}>
                                                <div className={classes.tagDiv}>
                                                    <p className={classes.fontBold}>Architectural Description</p>
                                                </div>
                                            </td>
                                            <td className={classes.tableCells}>
                                                <div className={classes.tagDiv}>
                                                    <p className={classes.font}>{entry.architectural_description}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={classes.tableLeftCells}>
                                                <div className={classes.tagDiv}>
                                                    <p className={classes.fontBold}>Heritage Registry Entry</p>
                                                </div>
                                            </td>
                                            <td className={classes.tableCells}>
                                                <div className={classes.tagDiv}>
                                                    {entry.heritage_registry_entry ?
                                                        <a className={classes.font} href={entry.heritage_registry_entry} target="_blank">{entry.heritage_registry_entry}</a>
                                                        : <p className={classes.font}>Not registered under the Ontario Heritage Act Section IV</p>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </>
                            )
                        })
                        : <div className={classes.imageDiv}>
                            <span className={classes.whiteText}>No heritage information for this property</span>
                        </div>
                    }
                    <br />
                    <span className={classes.whiteText}>Plaques</span>
                    {plaques && plaques.length > 0 && !plaquesLoading
                        ? plaques.map((plaque) => {
                            return(
                                <div className={classes.plaqueDiv}>
                                    <span className={classes.plaqueTitle}>{plaque.name}</span>
                                    <span className={classes.plaqueText}>"{plaque.text}"</span>
                                </div>
                            )
                        })
                        : <div className={classes.imageDiv}>
                            <span className={classes.whiteText}>No plaques on this property</span>
                        </div>
                    }
                </div>
                : menu === "ownership" ?
                <div className={classes.menuContainer}>
                    <table className={classes.table}>
                        <tr>
                            <td className={classes.tableLeftCells}>
                                <div className={classes.tagDiv}>
                                    <p className={classes.fontBold}>Lot</p>
                                </div>
                            </td>
                            <td className={classes.tableCells}>
                                <div className={classes.tagDiv}>
                                    <p className={classes.fontBold}>Person</p>
                                </div>
                            </td>
                            <td className={classes.tableCells}>
                                <div className={classes.tagDiv}>
                                    <p className={classes.fontBold}>Date</p>
                                </div>
                            </td>
                            <td className={classes.tableCells}>
                                <div className={classes.tagDiv}>
                                    <p className={classes.fontBold}>Source</p>
                                </div>
                            </td>
                        </tr>
                        {landOwnership && landOwnership.length > 0 ? landOwnership.map(landOwnership => {
                            return(
                                <tr>
                                    <td className={classes.tableLeftCells}>
                                        <div className={classes.tagDiv}>
                                            <p className={classes.font}>{landOwnership.lot}</p>
                                        </div>
                                    </td>
                                    <td className={classes.tableCells}>
                                        {landOwnership.people ?
                                            <div className={classes.tagDiv}>
                                                <Link className={classes.fontLink} to={`/person-overview/${landOwnership.people.id}/`}>{landOwnership.people.middle_name ? landOwnership.people.first_name + ' ' + landOwnership.people.middle_name + ' ' + landOwnership.people.surname : landOwnership.people.first_name + ' ' + landOwnership.people.surname}</Link>
                                            </div>
                                        : null}
                                    </td>
                                    <td className={classes.tableCells}>
                                        <div className={classes.tagDiv}>
                                            <p className={classes.font}>{landOwnership.date.toString().substr(0,4)}</p>
                                        </div>
                                    </td>
                                    <td className={classes.tableCells}>
                                        <div className={classes.tagDiv}>
                                            <p className={classes.font}>{landOwnership.source}</p>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }) : null}
                    </table>
                </div>
                : menu === "businesses" ?
                <div className={classes.menuContainer}>
                    <table className={classes.table}>
                        <tr>
                            <td className={classes.tableCells}>
                                <div className={classes.tagDiv}>
                                    <p className={classes.fontBold}>Organization</p>
                                </div>
                            </td>
                            <td className={classes.tableCells}>
                                <div className={classes.tagDiv}>
                                    <p className={classes.fontBold}>Date</p>
                                </div>
                            </td>
                        </tr>
                        {orgLocations ? orgLocations.map(orgLocation=> {
                            return(
                                <tr>
                                    <td className={classes.tableCells}>
                                        {orgLocation.organization ?
                                            <div className={classes.tagDiv}>
                                                <Link className={classes.fontLink} to={`/org-overview/${orgLocation.organization.id}/`}>{orgLocation.organization.name}</Link>
                                            </div>
                                        : null}
                                    </td>
                                    <td className={classes.tableCells}>
                                        <div className={classes.tagDiv}>
                                            <p className={classes.font}>{orgLocation.date_start && orgLocation.date_end ? orgLocation.date_start.toString().substr(0,4) + ' - ' + orgLocation.date_end.toString().substr(0,4) : orgLocation.date_start ? orgLocation.date_start.toString().substr(0,4) + ' - ' : null}</p>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }) : null}
                    </table>
                </div>
                : null
            }
            </div>
        </div>
    )
}

export default ZoneOverview