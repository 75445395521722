import { useState, useEffect } from 'react';

import {useSelector, useDispatch} from 'react-redux';

import {organizationsChange} from '../../store/actions/queryActions';

import {createUseStyles} from 'react-jss';

import OrgTag from './orgTag'

const useStyles = createUseStyles({
    searchDiv: {
        position: "relative",
        width: "100%",
    },
    text: {
        color: "white",
        display: "flex",
        justifyContent: "center",
    },
    input: {
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "white",
        marginTop: 3,
    },
    tagBox: {
        height: 60,
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: 8,
        padding: "0.5rem",
        marginTop: 3,
        backgroundColor: "white",
        display: "flex",
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
            width: 3,
            height: 3,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,0)",
            borderRadius: "10px",
        },
    },
    errorMessage: {
        color: "red",
        fontSize: 10,
    },
    searchBox: {
        position: "absolute",
        backgroundColor: "white",
        boxShadow: "0px 0px 15px 1px grey",
        borderRadius: 6,
        width: "100%",
        zIndex: 997,

    },
    searchOptions: {
        position: "relative",
        display: "block",
        transition: "all 0.5s",
        padding: 10,
        fontSize: 14,
        borderRadius: 3,
        "&:hover": {
            backgroundColor: "grey",
            transition: "0.5s",
        },
        zIndex: 997,
        cursor: "pointer",
    },
});

const OrgFilter = ({detectOrgUpdate}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const organizations  = useSelector((state) => state.query.organizations)

    const [ orgSearch, setOrgSearch ] = useState()

    const [ orgOptions, setOrgOptions] = useState([])

    const [ orgLoading, setOrgLoading ] = useState(true)

    const [errorMessage, setErrorMessage] = useState()

    const domain = 'https://www.bayfieldhistoricalwebmap.ca'

    useEffect( () => {
        fetch(`${domain}/api/organizations/`)
        .then(response => response.json())
        .then( (fetchedFeatures) => {
            setOrgOptions(fetchedFeatures)
            setOrgLoading(false)
        })

    },[])

    const onChange = (e) => {
        setOrgSearch(e.target.value)
        setErrorMessage(null)
        document.getElementById("orgInput").style.backgroundColor = "white";
    }

    const onClick = (e) => {
        let prevState = organizations
        if (e.target.innerHTML) {
            const trimmedInput = e.target.innerHTML.trim();

            let duplicate = false;
            // console.log(trimmedInput, e.target.id)

            if (trimmedInput.length) {
                for (let i=0; i<organizations.length; i++){
                    if (organizations[i].id === (e.target.id)) {
                        setErrorMessage("You have already added this organization tag")
                        document.getElementById("orgInput").style.backgroundColor = "rgb(245, 81, 66)";
                        duplicate = true
                        break;
                    }
                }
                if (!duplicate) {
                    prevState.push({name: trimmedInput, id: e.target.id})
                    dispatch(organizationsChange(prevState));
                    setOrgSearch('');
                    document.getElementById("orgInput").style.backgroundColor = "white";
                    detectOrgUpdate()
                }
            }
        }
    };

    return (
        <div className={classes.searchDiv}>
            <label className={classes.text} htmlFor="orgInput">Organization Tags</label>
            <div className={classes.errorMessage}>{errorMessage}</div>
            <div className={classes.tagBox}>
                {organizations.map((organization) => <OrgTag id={organization.id} name={organization.name} detectOrgUpdate={detectOrgUpdate} />)}
            </div>

            <input className={classes.input} id="orgInput" onChange={onChange} value={orgSearch} autoComplete="off" placeholder="Search for an organization..." />
            {!orgLoading && orgSearch && orgSearch.length > 2
                ? <div className={classes.searchBox}>
                    {orgOptions.filter((organization) => {
                        const name = organization.name.toLowerCase()
                        if (name.includes(orgSearch.toLowerCase())) {
                            return organization;
                        }
                        return null;
                    }).map((organization) =>
                        <span className={classes.searchOptions} onClick={onClick} id={organization.id}>{organization.name}</span>
                    )} 
                </div>
                : null
            }
        </div>
    )
}

export default OrgFilter