import * as actionTypes from './mapActionTypes';

export const zoneUpdate = (zone) => dispatch => {
    dispatch({
        type: actionTypes.ZONE_UPDATE,
        payload: zone,
    })
}

export const businessZoneUpdate = zones => dispatch => {
    dispatch({
        type: actionTypes.BUSINESS_ZONE_UPDATE,
        payload: zones,
    })
}

export const toggleLayer = (layer, toggle) => (dispatch) => {
    if (layer === "Historical Map of Huron County - 1862") {
        dispatch({
            type: actionTypes.TOGGLE_1862,
            payload: toggle,
        })
    }
    else if (layer === "Bayfield Town Plan - 1856") {
        dispatch({
            type: actionTypes.TOGGLE_1856,
            payload: toggle,
        })
    }
    else if (layer === "Belden Town Map - 1879") {
        dispatch({
            type: actionTypes.TOGGLE_1879,
            payload: toggle,
        })
    }
    else if (layer === '"Town and Estate of Bayfield" (Town) - 1835') {
        dispatch({
            type: actionTypes.TOGGLE_1835TOWN,
            payload: toggle,
        })
    }
    else if (layer === '"Town and Estate of Bayfield" (Surroundings) - 1835') {
        dispatch({
            type: actionTypes.TOGGLE_1835SURROUNDINGS,
            payload: toggle,
        })
    }
    else if (layer === "Bayfield Cemetery Plan - 1969") {
        dispatch({
            type: actionTypes.TOGGLE_CEMETERY,
            payload: toggle,
        })
    }
}

export const changeLayerOpacity = (layer, opacity) => (dispatch) => {
    if (layer === "Historical Map of Huron County - 1862") {
        dispatch({
            type: actionTypes.OPACITY_1862,
            payload: opacity,
        })
    }
    else if (layer === "Bayfield Town Plan - 1856") {
        dispatch({
            type: actionTypes.OPACITY_1856,
            payload: opacity,
        })
    }
    else if (layer === "Belden Town Map - 1879") {
        dispatch({
            type: actionTypes.OPACITY_1879,
            payload: opacity,
        })
    }
    else if (layer === '"Town and Estate of Bayfield" (Town) - 1835') {
        dispatch({
            type: actionTypes.OPACITY_1835TOWN,
            payload: opacity,
        })
    }
    else if (layer === '"Town and Estate of Bayfield" (Surroundings) - 1835') {
        dispatch({
            type: actionTypes.OPACITY_1835SURROUNDINGS,
            payload: opacity,
        })
    }
    else if (layer === "Bayfield Cemetery Plan - 1969") {
        dispatch({
            type: actionTypes.OPACITY_CEMETERY,
            payload: opacity,
        })
    }
}

export const toggleBaseMap = (basemap) => (dispatch) => {
    dispatch({
        type: actionTypes.TOGGLE_BASEMAP,
        payload: basemap,
    })
}

export const toggleMenu = (menu, toggle) => dispatch => {
    if (menu === 'Legend') {
        dispatch({
            type: actionTypes.TOGGLE_LEGEND,
            payload: toggle,
        })
    }
    else if (menu === 'CollectionDisplay') {
        dispatch({
            type: actionTypes.TOGGLE_COLLECTIONDISPLAY,
            payload: toggle,
        })
    }
    else if (menu === 'Time Slider') {
        dispatch({
            type: actionTypes.TOGGLE_TIMESLIDER,
            payload: toggle,
        })
    }
    else if (menu === 'AddressSearch') {
        dispatch({
            type: actionTypes.TOGGLE_ADDRESSSEARCH,
            payload: toggle,
        })
    }
    else if (menu === 'Business Search') {
        dispatch({
            type: actionTypes.TOGGLE_ORGSEARCH,
            payload: toggle,
        })
    }
}