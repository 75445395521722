import { useState } from 'react';

import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    imageUploadPreviewDiv: {
        height: "32vh",
        display: "grid",
        gridTemplateColumns: "25% 75%",
        alignItems: "center",
        margin: 5,
    },
    imagePreviewDiv: {
        height: "32vh",
        display: "flex",
        justifyContent: "flex-start",
        overflowX: "scroll",
        overflowY: "hidden",
        "&::-webkit-scrollbar": {
            height: 5,
        },
        "&::-webkit-scrollbar-thumb": {
            background: "rgba(200, 200, 200, 0.8)",
            borderRadius: "10px",
        },
    },
    dropDiv: {
        borderWidth: 8,
        border: "solid",
        borderColor: "whitesmoke",
        borderRadius: 10,
        height: "30vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    imageDiv: {
        margin: 5,
        borderRadius: 10,
        position: "relative",
        cursor: "pointer",
    },
    imagePreview: {
        height: "30vh",
    },
    deleteDiv: {
        position: "absolute",
        top: 10,
        right: 10,
        width: 20,
        height: 20,
        "&:hover": {
            transform: "scale(1.5)",
            transition: "0.2s",
        },
    },
});

const SingleImageInput = ({getImage}) => {

    const classes = useStyles();

    const [file, setFile] = useState();
    const [tempUrl, setTempUrl] = useState();

    const handleDragOver = e => {
        e.preventDefault();
        document.getElementById("dropzone").style.backgroundColor = "rgb(92, 230, 110)";
        document.getElementById("dropzone").style.color = "white";
    }

    const handleDragLeave = e => {
        e.preventDefault();
        document.getElementById("dropzone").style.backgroundColor = "white";
        document.getElementById("dropzone").style.color = "black";
    }

    const handleOnDrop = e => {
        //prevent the browser from opening the image
        e.preventDefault(); 
        e.stopPropagation(); 

        imageSet(e.dataTransfer.files[0])
        setTempUrl(URL.createObjectURL(e.dataTransfer.files[0]))

        document.getElementById("dropzone").style.backgroundColor = "white";
        document.getElementById("dropzone").style.color = "black";
    }

    const onClick = e => {
        imageSet(null)
        setTempUrl(null)
    }

    const imageSet = (image) => {
        setFile(image)
        getImage(image)
        console.log(image)
    }

    return (
        <>
            <div className={classes.imageUploadPreviewDiv}>
                <div id="dropzone" className={classes.dropDiv} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleOnDrop}>
                    <center>Drag and drop your image here</center>
                </div>
                <div className={classes.imagePreviewDiv}>
                    { file ?
                        <div 
                            className={classes.imageDiv}
                        >
                            <div onClick={onClick} id={file.id} className={classes.deleteDiv}>
                                <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="rgb(242, 73, 51)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line id={file.id} x1="18" y1="6" x2="6" y2="18"></line><line id={file.id} x1="6" y1="6" x2="18" y2="18"></line></svg>
                            </div>
                            <img src={tempUrl} className={classes.imagePreview} alt="Preview" />
                            
                        </div>
                    : null}
                </div>
            </div>
        </>
    )
}

export default SingleImageInput
