import { useParams } from "react-router"

import PublicHeader from "../headers/PublicHeader";
import OrgOverview from "../components/orgOverview/orgOverview"

const OrgOverviewWrapper = () => {
    const { id } = useParams();

    return(
        <>
            <PublicHeader />
            <OrgOverview id={id} />
        </>
    )
}

export default OrgOverviewWrapper