import { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
    personDiv: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
        cursor: "pointer",
        "&:hover": {
            transform: "scale(1.2)",
        },
        marginLeft: 5,
        marginRight: 5,
    },
    imageDiv: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxHeight: "100%",
    },
    image: {
        maxHeight: "10vh",
    },
    infoDiv: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& span": {
            marginTop: 2,
            fontSize: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "100%",
            textAlign: "center",
        },
        
    },
})

const PersonDisplay = ({person}) => {
    const classes=useStyles();

    const [src, setSrc]= useState();
    // const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     fetch(`http://www.benwoodward.ca/api/people/images/?search=${person.id}`)
    //         .then(response => {
    //             if (response.status !== 200) {
    //                 setImage(null)
    //                 setLoading(false)
    //                 return null;
    //             }
    //             response.json().then( (fetchedFeatures) => {
    //                 setImage(fetchedFeatures[0])
    //                 setLoading(false)
    //             })
    //         })
    //         .catch(err => {
    //             console.log(err)
    //             setLoading(false)
    //         })
    // }, [])
    

    useEffect(() => {
        const domain = 'https://www.bayfieldhistoricwebmap.ca'
        setSrc(`${domain}/api/people/images/${person.id}/`)
    }, [person])

    const onError = () => {
        setSrc(`https://upload.wikimedia.org/wikipedia/commons/b/bc/Unknown_person.jpg`)
    }

    return (
        <>
            {person ?
                <Link to={`/person-overview/${person.id}`} className={classes.personDiv} id={person.id}>
                    <div className={classes.imageDiv}>
                        <img className={classes.image} src={src} onError={onError} alt={person.first_name + ' ' + person.surname} />
                    </div>
                    <div className={classes.infoDiv}>
                        <span>{person.middle_name ? person.first_name + ' ' + person.middle_name + ' ' + person.surname : person.first_name + ' ' + person.surname}</span>
                        <span>{person.date_of_death & person.date_of_birth ? person.date_of_birth.toString().substr(0,4) + ' - ' + person.date_of_death.toString().substr(0,4) : person.date_of_birth ? person.date_of_birth.toString().substr(0,4) + ' - ' : null}</span>
                    </div>
                </Link>
                : <span>Display Error</span>
            }
        </>
    )
}

export default PersonDisplay