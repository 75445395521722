import * as actionTypes from './itemAddActionTypes';

export const typeUpdate = (type) => dispatch => {
    dispatch({
        type: actionTypes.TYPE_UPDATE,
        payload: type,
    })
}

export const descriptionUpdate = (description) => dispatch => {
    dispatch({
        type: actionTypes.DESCRIPTION_UPDATE,
        payload: description,
    })
}

export const peopleUpdate = (people) => dispatch => {
    dispatch({
        type: actionTypes.PEOPLE_UPDATE,
        payload: people,
    })
}

export const subjectsUpdate = (subjects) => dispatch => {
    dispatch({
        type: actionTypes.SUBJECTS_UPDATE,
        payload: subjects,
    })
}

export const dateStartUpdate = (datestart) => dispatch => {
    dispatch({
        type: actionTypes.DATESTART_UPDATE,
        payload: datestart,
    })
}

export const dateEndUpdate = (dateend) => dispatch => {
    dispatch({
        type: actionTypes.DATEEND_UPDATE,
        payload: dateend,
    })
}

export const zoneNameUpdate = (zonename) => dispatch => {
    dispatch({
        type: actionTypes.ZONENAME_UPDATE,
        payload: zonename,
    })
}

export const titleUpdate = (title) => dispatch => {
    dispatch({
        type: actionTypes.TITLE_UPDATE,
        payload: title,
    })
}

export const collectionUpdate = (collection) => dispatch => {
    dispatch({
        type: actionTypes.COLLECTION_UPDATE,
        payload: collection,
    })
}

export const archivalIdentifierUpdate = (archivalIdentifier) => dispatch => {
    dispatch({
        type: actionTypes.ARCHIVALIDENTIFIER_UPDATE,
        payload: archivalIdentifier,
    })
}

export const copyrightUpdate = (copyright) => dispatch => {
    dispatch({
        type: actionTypes.COPYRIGHT_UPDATE,
        payload: copyright,
    })
}

export const creatorUpdate = (creator) => dispatch => {
    dispatch({
        type: actionTypes.CREATOR_UPDATE,
        payload: creator,
    })
}

export const contributorsUpdate = (contributors) => dispatch => {
    dispatch({
        type: actionTypes.CONTRIBUTORS_UPDATE,
        payload: contributors,
    })
}

export const externalRecordUpdate = (url) => dispatch => {
    dispatch({
        type: actionTypes.EXTERNALRECORDURL_UPDATE,
        payload: url,
    })
}

export const imageUpdate = (image) => dispatch => {
    dispatch({
        type: actionTypes.IMAGES_UPDATE,
        payload: image,
    })
}

export const organizationUpdate = (organization) => dispatch => {
    dispatch({
        type: actionTypes.ORGANIZATION_UPDATE,
        payload: organization,
    })
}

export const clearItemState = () => dispatch => {
    dispatch({
        type: actionTypes.CLEAR_ITEM_STATE,
    })
}

export const errorUpdate = (errors) => dispatch => {
    dispatch({
        type: actionTypes.ERROR_UPDATE,
        payload: errors,
    })
}

export const changePending = (pending) => dispatch => {
    dispatch({
        type: actionTypes.CHANGE_PENDING,
        payload: pending,
    })
}