export const ZONE_UPDATE = 'ZONE_UPDATE';
export const BUSINESS_ZONE_UPDATE = 'BUSINESS_ZONE_UPDATE';

export const TOGGLE_1856 = 'TOGGLE_1856';
export const TOGGLE_1879 = 'TOGGLE_1879';
export const TOGGLE_1862 = 'TOGGLE_1862';
export const TOGGLE_1835TOWN = 'TOGGLE_1835TOWN';
export const TOGGLE_1835SURROUNDINGS = 'TOGGLE_1835SURROUNDINGS';
export const TOGGLE_CEMETERY = 'TOGGLE_CEMETERY';
export const OPACITY_1856 = 'OPACITY_1856';
export const OPACITY_1879 = 'OPACITY_1879';
export const OPACITY_1862 = 'OPACITY_1862';
export const OPACITY_1835TOWN = 'OPACITY_1835TOWN';
export const OPACITY_1835SURROUNDINGS = 'OPACITY_1835SURROUNDINGS';
export const OPACITY_CEMETERY = 'OPACITY_CEMETERY';
export const TOGGLE_BASEMAP = 'TOGGLE_BASEMAP';

export const TOGGLE_LEGEND = 'TOGGLE_LEGEND';
export const TOGGLE_COLLECTIONDISPLAY = 'TOGGLE_COLLECTIONDISPLA';
export const TOGGLE_TIMESLIDER = 'TOGGLE_TIMESLIDER';
export const TOGGLE_ADDRESSSEARCH = 'TOGGLE_ADDRESSSEARCH';
export const TOGGLE_ORGSEARCH = 'TOGGLE_ORGSEARCH';