import { useEffect, useState } from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {creatorUpdate} from '../../../store/actions/itemAddActions';

import {createUseStyles} from 'react-jss';
import ReactTooltip from 'react-tooltip';

const useStyles = createUseStyles({
    searchDiv: {
        position: "relative",
    },
    input: {
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "white",
        marginTop: 3,
    },
    errorMessage: {
        color: "red",
        fontSize: 10,
    },
    searchBox: {
        position: "absolute",
        backgroundColor: "white",
        boxShadow: "0px 0px 15px 1px grey",
        borderRadius: 6,
        width: "90%",
        zIndex: 999,
    },
    searchOptions: {
        position: "relative",
        display: "block",
        transition: "all 0.5s",
        padding: 10,
        fontSize: 14,
        borderRadius: 3,
        "&:hover": {
            backgroundColor: "grey",
            transition: "0.5s",
        },
        cursor: "pointer",
        zIndex: 999,
    },
});

const ItemCreator = ({peopleOptions, peopleLoading}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const creator = useSelector(state => state.itemAdd.creator)

    const [ creatorSearch, setCreatorSearch ] = useState()

    useEffect(() => {
        if (creator) {
            setCreatorSearch(creator.name)
        }
    }, [creator])

    const [ visible, setVisible ] = useState(true)

    // const creator = useSelector(state => state.itemAdd.creator)

    const onChange = (e) => {
        setCreatorSearch(e.target.value)
        if (!visible){
            setVisible(true)
        }
    }

    const onClick = (e) => {
        if (e.target.innerHTML) {
            const trimmedInput = e.target.innerHTML.trim();
            dispatch(creatorUpdate({name: trimmedInput, id: e.target.id}));
            setCreatorSearch(trimmedInput);
            setVisible(false);
        }
    };


    return (
        <div className={classes.searchDiv}>
            <ReactTooltip multiline={true} />
            <label htmlFor="creatorInput" data-tip="Select the creator of your item from the list. The creator is the person who made the item (e.g., a photographer for a photograph, an author for a text, etc.)">Primary Creator</label>
            <input className={classes.input} id="creatorInput" onChange={onChange} value={creatorSearch ? creatorSearch : ''} autoComplete="off" />
            {!peopleLoading && creatorSearch && visible && creatorSearch.length > 2
                ? <div className={classes.searchBox}> {peopleOptions.filter((person) => {
                        const name = person.first_name.toLowerCase() + ' ' + person.surname.toLowerCase()
                        if (name.includes(creatorSearch.toLowerCase())) {
                            return person;
                        }
                        return null;
                    }).map((person) =>
                        <span className={classes.searchOptions} onClick={onClick} id={person.id}>{person.middle_name ? person.first_name + ' ' + person.middle_name + ' ' + person.surname : person.first_name + ' ' + person.surname}</span>
                    )} 
                </div>
                : null
            }
        </div>
    )
}

export default ItemCreator