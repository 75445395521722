import { createUseStyles } from "react-jss"

import { Link } from "react-router-dom";

const useStyles = createUseStyles({
    font: {
        fontSize: 12,
        color: "black",
        marginLeft: 3,
        marginRight: 3,
        "&:hover": {
            fontSize: 14,
        },
    },
})

const LinkDisplay = ({name, id, to}) => {

    const classes = useStyles();

    return(
        <Link id={id} className={classes.font} to={to}>{name}</Link>
    )
}

export default LinkDisplay