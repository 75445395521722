import { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { collectionChange } from "../store/actions/queryActions";

import { createUseStyles } from "react-jss";

import ItemDisplay from './searchbar/itemDisplay';
//import VideoDisplay from "./videoDisplay";

import {SpinningCircles} from 'react-loading-icons';

import { titleCase } from "title-case";

const useStyles = createUseStyles({
    itemDisplayDiv: {
        height: "100%",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            width: 5,
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "rgb(191, 191, 191)",
            opacity: 0,
            borderRadius: "10px",
        },
    },
    noItemsAlert: {
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    loadingIconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    collectionBox: {
        display: "flex",
        flexDirection: "column",
        padding: 15,
        margin: 10,
        backgroundColor: "rgba(240, 240, 240, 0.8)",
        borderRadius: 5,
    },
    collectionFont: {
        fontSize: 12,
        marginTop: 5,
        color: "black",
    },
    collectionFontTitle: {
        composes: "$collectionFont",
        fontSize: 16,
        color: "black",
        marginBottom: 5,
    },
    collectionFontLink: {
        composes: "$collectionFont",
        cursor: "pointer",
        "&:hover": {
            fontSize: 15,
            textDecoration: "underline",
        },
    },
    table: {
        borderCollapse: "collapse",
        width: "100%",
        tableLayout: "fixed",
        "& > :nth-child(2n)": {
            backgroundColor: "rgb(230, 230, 230)",
        },
        "& > :nth-child(2n-1)": {
            backgroundColor: "rgb(210, 210, 210)",
        }
    },
    tableCells: {
        // padding: 5,
    },
    tableLeftCells: {
        composes: "$tableCells",
        width: 140,
    },
    tagDiv: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        margin: 5,
    },
    tagContainer: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        overflowY: "hidden",
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
            width: 5,
        },
        "&::-webkit-scrollbar-thumb": {
            background: "rgba(66, 132, 150, 0.6)",
            borderRadius: "10px",
        },
    },
})

const ItemDisplayContainer = ({items, loading, togglePopup, detectSubjectUpdate}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const domain = 'https://www.bayfieldhistoricalwebmap.ca'

    const collectionId = useSelector(state => state.query.collection)

    const [collection, setCollection] = useState()

    const [parentCollection, setParentCollection] = useState()

    const [childCollections, setChildCollections] = useState([])

    async function getCollection(id) {
        if (id) {
            const collectionRes = await fetch(`${domain}/api/collection/${id}/`)
            const collectionJson = await collectionRes.json()
            return collectionJson
        }
    }

    async function getChildCollections(id) {
        if (id) {
            const collectionRes = await fetch(`${domain}/api/collections/?parent_collection=${id}`)
            const collectionJson = await collectionRes.json()
            return collectionJson
        }
    }

    useEffect(() => {
        if (collectionId) {
            getCollection(collectionId)
                .then(json => {
                    setCollection(json)
                    if (json.parent_collection) {
                        getCollection(json.parent_collection)
                            .then(json => {
                                setParentCollection(json)
                            })
                    }
                    else {
                        setParentCollection(null)
                    }
                })
        }
        else {
            setCollection(null)
        }
    }, [collectionId])

    useEffect(() => {
        if (collectionId) {
            getChildCollections(collectionId)
                .then(json => {
                    setChildCollections(json)
                })
        }
        else {
            setChildCollections([])
        }   
    }, [collectionId])

    const onCollectionChange = (e) => {
        dispatch(collectionChange(e.target.id))
    }

    return(
        <div className={classes.itemDisplayDiv}>
            {collection ?
                <div className={classes.collectionBox}>
                    <span className={classes.collectionFontTitle}>{collection.collection_name}</span>
                    <table className={classes.table}>
                        <tr>
                            <td className={classes.tableLeftCells}>
                                <div className={classes.tagDiv}>
                                    <span className={classes.collectionFont}>Hierarchy</span>
                                </div>
                            </td>
                            <td className={classes.tableCells}>
                                <div className={classes.tagDiv}>
                                    <span className={classes.collectionFont}>{collection.hierarchy ? titleCase(collection.hierarchy) : null}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.tableLeftCells}>
                                <div className={classes.tagDiv}>
                                    <span className={classes.collectionFont}>Description</span>
                                </div>
                            </td>
                            <td className={classes.tableCells}>
                                <div className={classes.tagDiv}>
                                    <span className={classes.collectionFont}>{collection.collection_description}</span>
                                </div>
                            </td>
                        </tr>
                        {parentCollection ?
                            <tr>
                                <td className={classes.tableLeftCells}>
                                    <div className={classes.tagDiv}>
                                        <span className={classes.collectionFont}>Parent Collection</span>
                                    </div>
                                </td>
                                <td className={classes.tableCells}>
                                    <div className={classes.tagDiv}>
                                        <span id={parentCollection.id} onClick={onCollectionChange} className={classes.collectionFontLink}>{parentCollection.collection_name}</span>
                                    </div>
                                </td>
                            </tr>
                            :null
                        }
                        {childCollections && childCollections.length > 0 ?
                            <tr>
                                <td className={classes.tableLeftCells}>
                                    <div className={classes.tagDiv}>
                                        <span className={classes.collectionFont}>Child Collections</span>
                                    </div>
                                </td>
                                <td className={classes.tableCells}>
                                    <div className={classes.tagContainer}>
                                    {childCollections.map(collection => {
                                        return(
                                            <div className={classes.tagDiv}>
                                                <span id={collection.id} onClick={onCollectionChange} className={classes.collectionFontLink}>{collection.collection_name}</span>
                                            </div>
                                        )
                                    })}
                                    </div>
                                </td>
                            </tr>
                            : null
                        }
                    </table>
                </div>
                : null
            }
            {loading ? 
                <div className={classes.loadingIconContainer}>
                    <SpinningCircles fill="rgb(66, 132, 150)" />
                </div>
                : !loading && items.length > 0?
                <>
                    {items.map((item) => {
                        return(
                            <ItemDisplay id={item.id} item={item} length={item.item_scans.length} togglePopup={togglePopup} detectSubjectUpdate={detectSubjectUpdate} />
                        )
                    })}
                </>
                : <div className={classes.noItemsAlert}>No items</div>
            }
        </div>
    )
}

export default ItemDisplayContainer