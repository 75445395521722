import { useState } from 'react';

import {useSelector, useDispatch} from 'react-redux';

import {peopleUpdate} from '../../../store/actions/itemAddActions';

import {createUseStyles} from 'react-jss';

import PeopleTag from '../peopleTag'
import ReactTooltip from 'react-tooltip';

const useStyles = createUseStyles({
    searchDiv: {
        position: "relative",
    },
    input: {
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "white",
        marginTop: 3,
    },
    tagBox: {
        height: 60,
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        padding: "0.5rem",
        marginTop: 3,
        backgroundColor: "white",
        display: "flex",
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
            width: 3,
            height: 3,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "whitesmoke",
            "&:hover": {
                backgroundColor: "rgb(207, 205, 204)",
            },
            borderRadius: "10px",
        },
    },
    errorMessage: {
        color: "red",
        fontSize: 10,
    },
    searchBox: {
        position: "absolute",
        backgroundColor: "white",
        boxShadow: "0px 0px 15px 1px grey",
        borderRadius: 6,
        width: "90%",
        zIndex: 999,
    },
    searchOptions: {
        position: "relative",
        display: "block",
        transition: "all 0.5s",
        padding: 10,
        fontSize: 14,
        borderRadius: 3,
        "&:hover": {
            backgroundColor: "grey",
            transition: "0.5s",
        },
        cursor: "pointer",
        zIndex: 999,
    },
});

const ItemPeople = ({peopleOptions, peopleLoading}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const people  = useSelector((state) => state.itemAdd.people)

    const [ peopleSearch, setPeopleSearch ] = useState()

    const [errorMessage, setErrorMessage] = useState()

    const onChange = (e) => {
        setPeopleSearch(e.target.value)
        setErrorMessage(null)
        document.getElementById("peopleInput").style.backgroundColor = "white";
    }

    const onClick = (e) => {
        let prevState = people
        if (e.target.innerHTML) {
            const trimmedInput = e.target.innerHTML.trim();

            let duplicate = false;
            // console.log(trimmedInput, e.target.id)

            if (trimmedInput.length) {
                for (let i=0; i<people.length; i++){
                    if (people[i].id === (e.target.id)) {
                        setErrorMessage("You have already added this person tag")
                        document.getElementById("peopleInput").style.backgroundColor = "rgb(245, 81, 66)";
                        duplicate = true
                        break;
                    }
                }
                if (!duplicate) {
                    prevState.push({name: trimmedInput, id: e.target.id})
                    dispatch(peopleUpdate(prevState));
                    setPeopleSearch('');
                    document.getElementById("peopleInput").style.backgroundColor = "white";
                }
            }
        }
    };

    return (
        <div className={classes.searchDiv}>
            <ReactTooltip multiline={true} />
            <label htmlFor="peopleInput" data-tip="Search for people in the search box and add them from the suggestions that appear. Include any people who are associated with the item (e.g., people who appear in a photograph or are mentioned in a text.) Add anyone that is missing from our records using the 'Add a person' button.">People Tags</label>
            <div className={classes.errorMessage}>{errorMessage}</div>
            <div className={classes.tagBox}>
                {people.map((people) => <PeopleTag id={people.id} name={people.name} />)}
            </div>

            <input className={classes.input} id="peopleInput" onChange={onChange} value={peopleSearch ? peopleSearch : ''} autoComplete="off" placeholder="Search for a person..." />
            {!peopleLoading && peopleSearch && peopleOptions
                ? <div className={classes.searchBox}> 
                    {peopleOptions.filter((person) => {
                        const name = person.middle_name ? person.first_name.toLowerCase() + ' ' + person.middle_name.toLowerCase() + ' ' + person.surname.toLowerCase() : person.first_name.toLowerCase() + ' ' + person.surname.toLowerCase()
                        if (peopleSearch.length > 2 && name.includes(peopleSearch.toLowerCase())) {
                            return person;
                        }
                        return null;
                    }).map((person) =>
                        <span className={classes.searchOptions} onClick={onClick} id={person.id}>{person.middle_name ? person.first_name + ' ' + person.middle_name + ' ' + person.surname : person.first_name + ' ' + person.surname}</span>
                    )} 
                </div>
                : null
            }
        </div>
    )
}

export default ItemPeople


