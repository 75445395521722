export const TYPE_UPDATE = 'TYPE_UPDATE';
export const DESCRIPTION_UPDATE = 'DESCRIPTION_UPDATE';
export const PEOPLE_UPDATE = 'PEOPLE_UPDATE';
export const SUBJECTS_UPDATE = 'SUBJECTS_UPDATE';
export const DATESTART_UPDATE = 'DATESTART_UPDATE';
export const DATEEND_UPDATE = 'DATEEND_UPDATE';
export const ZONENAME_UPDATE = 'ZONENAME_UPDATE';
export const TITLE_UPDATE = 'TITLE_UPDATE';
export const COLLECTION_UPDATE = 'COLLECTION_UPDATE';
export const ARCHIVALIDENTIFIER_UPDATE = 'ARCHIVALIDENTIFIER_UPDATE';
export const COPYRIGHT_UPDATE = 'COPYRIGHT_UPDATE';
export const CREATOR_UPDATE = 'CREATOR_UPDATE';
export const CONTRIBUTORS_UPDATE = 'CONTRIBUTORS_UPDATE';
export const EXTERNALRECORDURL_UPDATE = 'EXTERNALRECORDURL_UPDATE';
export const IMAGES_UPDATE = 'IMAGES_UPDATE'
export const ORGANIZATION_UPDATE = 'ORGANIZATION_UPDATE'
export const CLEAR_ITEM_STATE = 'CLEAR_ITEM_STATE'
export const ERROR_UPDATE = 'ERROR_UPDATE'
export const CHANGE_PENDING = 'CHANGE_PENDING'