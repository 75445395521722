import {useSelector, useDispatch} from 'react-redux';

import {peopleChange} from '../../store/actions/queryActions';

import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    tag: {
        backgroundColor: "rgb(50, 133, 168)",
        borderRadius: 8,
        border: 0,
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        margin: 4,
        fontSize: 14,
        padding: 5,
        whiteSpace: "nowrap",
    },
})

const PeopleTag = ({id, name, detectPeopleUpdate}) => {

    const dispatch = useDispatch()

    const classes = useStyles();

    const people = useSelector((state) => state.query.people)

    const onClick = (e) => {
        let array = people
        if (array.length > 0) {
            for (let x = 0; x < array.length; x++) {
                console.log(x)
                if (array[x].id === e.target.id) {
                    array.splice(x,1);
                    break;
                }
            }
        }

        dispatch(peopleChange(array))
        detectPeopleUpdate()
    }

    return (
        <span className={classes.tag} id={id} onClick={onClick}>{name}</span>
    )
}

export default PeopleTag;