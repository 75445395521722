import {useSelector, useDispatch} from 'react-redux';

import {zoneNameUpdate} from '../../../store/actions/itemAddActions';

import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    input: {
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "white",
        marginTop: 3,
    },
    locationsContainer: {
        maxHeight: 110,
        overflow: "scroll",
        "&::-webkit-scrollbar": {
            width: 5,
        },
        "&::-webkit-scrollbar-thumb": {
            background: "rgba(200, 200, 200, 0.8)",
            borderRadius: "10px",
        },
    },
    errorMessage: {
        color: "red",
        fontSize: 10,
    },
});

const ItemLocation = ({features, loading, error}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const zoneName = useSelector((state) => state.itemAdd.zonename)

    const onZoneNameChange = (e) => {
 
        dispatch(zoneNameUpdate(e.target.value))
    }

    return (
        <>
            { loading 
                ? <p>Loading Locations...</p> 
                : <>
                    <label htmlFor="location">Location</label>
                    { error
                        ? <div className={classes.errorMessage}>{error}</div>
                        : null
                    }
                    <input id="location" list="locations" className={classes.input} placeholder="Search location or click on map..." value={zoneName ? zoneName : ''} onChange={onZoneNameChange} autoComplete="off" ></input>
                    <datalist id="locations">
                        {zoneName && zoneName.length >= 2 && features
                            ?
                                features.filter((zone) => {
                                    if (zone.values_.name.toLowerCase().includes(zoneName.toLowerCase()) || (zone.values_.address && zone.values_.address.toLowerCase().includes(zoneName.toLowerCase()))) {
                                        return zone;
                                    }
                                    else if (zone.values_.altnames) {
                                        if (zone.values_.altnames.includes(zoneName.toLowerCase())) {
                                            return zone
                                        }
                                    }
                                    return null;
                                }).map((zone) => {
                                    return(
                                        <option value={zone.values_.name}>{zone.values_.name}</option>
                                    )
                                })
                            : null
                        }
                    </datalist>     
                </>
            }
        </>
    )
}

export default ItemLocation