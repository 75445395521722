import { useState, useEffect, useCallback, useRef } from 'react';

import {useSelector, useDispatch} from 'react-redux';

import {changePending, clearItemState, errorUpdate} from '../store/actions/itemAddActions';

import GeoJSON from 'ol/format/GeoJSON'

import {Link} from 'react-router-dom';

import disableScroll from 'disable-scroll';

import {createUseStyles} from 'react-jss';

import {SpinningCircles} from 'react-loading-icons';


import axios from 'axios';

import PublicHeader from '../headers/PublicHeader';
import LocationSelector from '../components/itemAdd/locationAdd';
import ImageUploadPreview from '../components/itemAdd/imageUploadPreview';

import ItemDate from '../components/itemAdd/formFields/itemDate';
import ItemType from '../components/itemAdd/formFields/itemType';
import ItemDescription from '../components/itemAdd/formFields/itemDescription';
import ItemPeople from '../components/itemAdd/formFields/itemPeople';
import ItemSubjects from '../components/itemAdd/formFields/itemSubjects';
import ItemLocation from '../components/itemAdd/formFields/itemLocation';

import ItemTitle from '../components/itemAdd/formFields/itemTitle';
import ItemCollection from '../components/itemAdd/formFields/itemCollection';
import ItemIdentifier from '../components/itemAdd/formFields/itemArchivalIdentifier';
import ItemCopyrightInfo from '../components/itemAdd/formFields/itemCopyrightInfo';
import ItemCreator from '../components/itemAdd/formFields/itemCreator';
import ItemContributors from '../components/itemAdd/formFields/itemContributor';
import ItemExternalURL from '../components/itemAdd/formFields/itemExternalURL';
import ItemOrganizations from '../components/itemAdd/formFields/itemOrganizations';

import NewCollection from '../components/itemAdd/newAdds/newCollection'
import NewSubject from '../components/itemAdd/newAdds/newSubject';
import NewOrganization from '../components/itemAdd/newAdds/newOrganization';
import NewPerson from '../components/itemAdd/newAdds/newPerson';
import PhotoEditPopup from '../components/itemAdd/photoEditPopup';

import axiosInstance from '../axios';

const useStyles = createUseStyles({
    allDiv: {
        position: "absolute",
        width: "100%",
    },
    popupModal: {
        position: "fixed",
        zIndex: 999,
        display: "block",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        overflow: "auto",
        backgroundColor: "rgba(0,0,0,0.4)",
    },
    popup: {
        backgroundColor: "#fefefe",
        borderRadius: 10,
        margin: "5% auto",
        padding: 20,
        border: "4px solid #ababab",
        width: "50%",
        display: "flex",
        flexDirection: "column",
        alignItems: "top",
    },
    addItemHeaderDiv: {
        display: "grid",
        gridTemplateColumns: "80% 20%",
        color: "black",
    },
    addItemHeaderButtonDiv: {
        display: "flex",
        justifyContent: "space-around",
    },
    typeDiv: {
        padding: 10,
        backgroundColor: "rgba(219, 219, 219, 1)",
        height: "87vh",
        display: "grid",
        gridTemplateRows: "8vh 71vh 5vh 3vh",
    },
    imageUploadPreviewDiv: {
        backgroundColor: "rgba(219, 219, 219, 1)",
        padding: "2vh",
        color: "black",
        height: "100vh",
        display: "grid",
        gridTemplateRows: "90vh 5vh 2vh",
    },
    mainDiv: {
        padding: 10,
        backgroundColor: "rgba(219, 219, 219, 1)",
        height: "100vh",
        display: "grid",
        gridTemplateRows: "8vh 84vh 5vh 3vh",
    },
    infoDiv: {
        display: "grid",
        gridTemplateColumns: "60% 40%",
    },
    infoSubDiv: {
        display: "grid",
        gridTemplateColumns: "50% 50%",
        gridTemplateRows: "50 50 50 50 50 50 50",
        gridGap: 5,
        padding: 4,
    },
    infoSubDivBottom : {
        padding: 4,
    },
    infoSubDivColumns: {
        display: "grid",
        gridTemplateColumns: "50% 50%",
        
    },
    column: {
        display: "grid",
        gridTemplateRows: "50 50 50 50 50 50 50",
        gridGap: 5,
        padding: 4,
    },
    mapDiv: {
        height: "40vh",
    },
    descriptionTypesDiv: {
        display: "flex",
        justifyContent: "space-around",
    },
    descriptionTypeButton: {
        "&:hover": {
            backgroundColor: "lightgrey",
            fontSize: 18,
            transition: "0.4s",
            fontWeight: "semibold",
        },
        cursor: "pointer",
        width: "16.7%",
        height: "5vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    descriptionDiv: {
        gridRow: "4 / 5",
    },
    contributorsDiv: {
        gridRow: "5 / 1",
    },
    button: {
        cursor: "pointer",
        backgroundColor: "rgb(47, 86, 102)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 6,
        color: "white",
        padding: 10,
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "rgb(83, 156, 176)",
        },
    },
    inputDiv: {
        display: "flex",
        flexDirection: "column",
        alignItems: "top",
    },
    typeInputDiv: {
        display: "flex",
        height: "60vh",
        justifyContent: "center",
        alignItems: "center",
    },
    input: {
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "white",
        marginTop: 3,
    },
    watermarkDiv: {
        marginTop: 15,
        width: "30%",
    },
    addButton: {
        fontSize: 12,
        color: "rgb(47, 86, 102)",
        cursor: "pointer",
        "&:hover": {
            fontSize: 14,
            transition: "0.4s",
        }
    },
    closeButtonDivDiv: {
        display: "flex",
        justifyContent: "flex-end",
    },
    closeButtonDiv: {
        width: 25,
        height: 25,
        top: 10,
        right: 10,
        cursor: "pointer",
        "&:hover": {
            transform: "scale(1.5)",
            transition: "0.2s",
        },
    },
    title: {
        fontSize: 18,
        marginLeft: 5,
    },
    loadingIconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
    },
    disclaimer: {
        display: "flex",
        justifyContent: "flex-end",
    },
});


const AddItem = () => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const domain = 'https://www.bayfieldhistoricalwebmap.ca'

    // const [reloading, setReloading] = useState(false)
    // const [locationError, setLocationError] = useState(null)
    const [descriptionError, setDescriptionError] = useState(null)
    const [subjectsError, setSubjectsError] = useState(null)
    const [typeError, setTypeError] = useState(null)
    const [archivalIdError, setArchivalIdError] = useState(null)
    const [externalURLError, setExternalURLError] = useState(null)
    const [images, setImages] = useState([])

    const auth = useSelector((state) => state.auth.isAuthenticated)

    const type = useSelector((state) => state.itemAdd.type)
    const description = useSelector((state) => state.itemAdd.description)
    const people = useSelector((state) => state.itemAdd.people)
    const subjects = useSelector((state) => state.itemAdd.subjects)
    const dateStart = useSelector((state) => state.itemAdd.dateStart)
    const dateEnd = useSelector((state) => state.itemAdd.dateEnd)
    const zonename = useSelector((state) => state.itemAdd.zonename)
    const title = useSelector((state) => state.itemAdd.title)
    const collection = useSelector((state) => state.itemAdd.collection)
    const archivalIdentifier = useSelector((state) => state.itemAdd.archivalIdentifier)
    const copyright = useSelector((state) => state.itemAdd.copyright)
    const creator = useSelector((state) => state.itemAdd.creator)
    const contributors = useSelector((state) => state.itemAdd.contributors)
    const externalRecordUrl = useSelector((state) => state.itemAdd.externalRecordUrl)
    const organizations = useSelector((state) => state.itemAdd.organizations)
    const errors = useSelector(state => state.itemAdd.errors)
    const user = useSelector(state => state.auth.user)
    const pending = useSelector(state => state.itemAdd.pending)

    const [features, setFeatures] = useState([])

    const [ loading, setLoading ] = useState(true)
        
    useEffect( () => {
        fetch(`${domain}/api/locations/`)
        .then(response => response.json())
        .then( (fetchedFeatures) => {
            const wktOptions = {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
            }
            const parsedFeatures = new GeoJSON().readFeatures(fetchedFeatures, wktOptions)

            setFeatures(parsedFeatures)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if (errors) {
            if (errors.archival_identifier) {
                setArchivalIdError("This archival ID is already in use!")
            }
            else {
                setArchivalIdError()
            }
            if (errors.externalRecordURL) {
                setExternalURLError("This URL is already being used in another item!")
            }
            else {
                setExternalURLError()
            }
        }
        else {
            setArchivalIdError()
            setExternalURLError()
        }
        
    }, [errors])
    
    const getImages = useCallback((images) => {
        setImages(images)
    })

    // async function postItem(url, data) {
    //     axiosInstance.post(url, JSON.stringify(data))
    //         .then(res => {
    //             return res.data;
    //         })
    //         .catch(err => {
    //             console.log(err.response)
    //             if (err.response.status === 400) {
    //                 console.log(err.response.data)
    //                 if (err.response.data.archival_identifier || err.response.data.externalRecordURL) {
    //                     console.log(err.response.data)
    //                     dispatch(errorUpdate(err.response.data))
    //                     dispatch(changePending(false))
    //                 }
    //             }
    //             return null;
    //         })
    // }

    async function postImage(url, inputData, images) {
        axiosInstance.post(url, JSON.stringify(inputData))
            .then(res => {
                console.log(res.data)
                if (images.length > 0 && res.data.id) {
                    axiosInstance.defaults.headers['Content-Type'] = 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
                    Promise.all(images.map(image => {
                        console.log(image)
                        image.append('item', res.data.id)
                        axiosInstance.post(`${domain}/api/scans/create/`, image)
                            .then(() => {
                                // dispatch(changePending(false))
                                console.log("Uploaded!")
                            })
                            .catch(err => {
                                console.log(err.response.data)
                                alert("There was an error adding your image! Please try uploading your item again.")
                                // if (data.id) {
                                //     axiosInstance.delete(`${domain}/api/item/${data.id}/`)
                                // }
                                dispatch(changePending(false))
                                setSection(0)
                            })
                    })).then(() => {
                        alert("Your item was successfully added to our records. Once one of our cataloguers reviews the item, it will be displayed on our website.")
                        dispatch(clearItemState())
                        // window.location.reload()
                        setSection(0)
                        dispatch(changePending(false))
                    })
                }
                else {
                    alert("Your item was successfully added to our records with no images. Once one of our cataloguers reviews the item, it will be displayed on our website.")
                    dispatch(clearItemState())
                    // window.location.reload()
                    setSection(0)
                    dispatch(changePending(false))
                }
            })
            .catch(err => {
                console.log(err)
                if (err && err.response) {
                    if (err.response.status === 400) {
                        console.log(err.response.data)
                        if (err.response.data.archival_identifier || err.response.data.externalRecordURL) {
                            console.log(err.response.data)
                            dispatch(errorUpdate(err.response.data))
                            dispatch(changePending(false))
                            if (err.response.data.externalRecordURL) {
                                setSection(sections.length-2)
                                setTimeout(() => document.getElementById(sections[sections.length-2]).scrollIntoView(), 1000)
                            }
                            else if (err.response.data.archival_identifier) {
                                setSection(sections.length-1)
                                setTimeout(() => document.getElementById(sections[sections.length-1]).scrollIntoView(), 1000)
                            }
                        }
                    }
                    if (err.response.status === 401) {
                        alert("Invalid user credentials!")
                    }
                }
                else {
                    alert("An error occurred! Please find and correct the error before you submit.")
                }
                if (pending === true) {
                    dispatch(changePending(false));
                }
            })
    }

    const descriptionErrorChange = useCallback(() => {
        setDescriptionError(null);
    })

    const idErrorChange = useCallback(() => {
        setArchivalIdError(null);
    })

    const urlErrorChange = useCallback(() => {
        setExternalURLError(null);
    })

    const [zoneId, setZoneId] = useState();

    let zone;

    useEffect(() => {
        zone = features.find((zone) => zone.values_.name === zonename)
        if (zone) {
            setZoneId(zone.values_.pk)
        }
        
    }, [zonename])

    const onClick = () => {
        dispatch(changePending(true))

        let contributorIds = []
        for (let i=0; i<contributors.length; i++) {
            contributorIds.push(contributors[i].id)
        }

        let peopleIds = []
        for (let i=0; i<people.length; i++) {
            peopleIds.push(people[i].id)
        }

        let subjectIds = []
        for (let i=0; i<subjects.length; i++) {
            subjectIds.push(subjects[i].id)
        }

        let creatorId = creator ? creator.id : null

        let orgIds = []
        for (let i=0; i<organizations.length; i++) {
            orgIds.push(organizations[i].id)
        }

        let dateStartInt = null
        let dateEndInt = null
        
        if (dateStart) {
            if (dateStart.length === 4) {
                dateStartInt = parseInt(dateStart + '0000')
            }
            else if (dateStart.length === 7) {
                dateStartInt = parseInt(dateStart.replace("-", "") + '00')
            }
            else if (dateStart.length === 10) {
                dateStartInt = parseInt(dateStart.replace("-", "").replace("-", ""))
            }
        }


        if (dateEnd) {
            if (dateEnd.length === 4) {
                dateEndInt = parseInt(dateEnd + '0000')
            }
            else if (dateEnd.length === 7) {
                dateEndInt = parseInt(dateEnd.replace("-", "") + '00')
            }
            else if (dateEnd.length === 10) {
                dateEndInt = parseInt(dateEnd.replace("-", "").replace("-", ""))
            }
        }

        let refinedExternalURL = null

        if (externalRecordUrl) {
            if (externalRecordUrl.includes('https://www.youtube.com/')) {
                refinedExternalURL = externalRecordUrl.split('?')[1]
                refinedExternalURL = refinedExternalURL.split('&')[0]
                refinedExternalURL = 'https://www.youtube.com/embed/' + refinedExternalURL.replace('v=','')
            }
        }

        let archivalID = archivalIdentifier

        if (archivalIdentifier === "") {
            archivalID = null;
        }

        if (!type || !description) {
            if (!type) {
                setTypeError("Add an item type")
                setSection(0)
                setTimeout(() => document.getElementById(sections[0]).scrollIntoView(), 500)
            }
            if (!description) {
                setDescriptionError("Add a description of your item")
                setSection(sections.length-2)
                setTimeout(() => document.getElementById(sections[sections.length-2]).scrollIntoView(), 500)
            }
            dispatch(changePending(false))
            return;
        }

        let zonePk = null

        if (zoneId) {
            zonePk = zoneId
        }
        
        let postData = {}

        postData = {
            user_author: user ? user.id : null,
            visibility: "draft",
            contributors: contributorIds,
            location: zonePk,
            creator: creatorId,
            date_start: dateStartInt,
            date_end: dateEndInt,
            general_description: description,
            archival_identifier: archivalID,
            people: peopleIds,
            copyright_info: copyright,
            collection: collection,
            subject: subjectIds,
            title: title,
            type: type,
            externalRecordURL: refinedExternalURL,
            organizations: orgIds,
        }


        postImage(`${domain}/api/item/post/`, postData, images)
    }

    const [ peopleOptions, setPeopleOptions ] = useState([])

    const [ peopleLoading, setPeopleLoading ] = useState(true)

    useEffect( () => {
        if (addPopup === 'peopleUpdated') {
            fetch(`${domain}/api/people/`)
                .then(response => response.json())
                .then( (fetchedFeatures) => {
                    setPeopleOptions(fetchedFeatures)
                    setPeopleLoading(false)
                })
        }
    },[addPopup, peopleLoading])

    useEffect( () => {
        fetch(`${domain}/api/people/`)
        .then(response => response.json())
        .then( (fetchedFeatures) => {
            setPeopleOptions(fetchedFeatures)
            setPeopleLoading(false)
        })
    },[])

    const [watermark, setWatermark] = useState("Bayfield Historical Society")

    const onChange = (e) => {
        setWatermark(e.target.value)
    }

    const [addPopup, setAddPopup] = useState('none');

    const toggleAddPopup = (e) => {
        setAddPopup(e.target.id)
    }

    const toggleAddPopupComponent = useCallback((value) => {
        setAddPopup(value)
    })

    const togglePeopleLoading = useCallback(() => {
        setPeopleLoading(true);
    })

    const [section, setSection] = useState(0)

    const [sections, _setSections] = useState(['top'])

    useEffect(() => {
        if (type=='video') {
            setSections(['top', 'url', 'locationtime', 'description', 'archivalDescription'])
        }
        else if (type=='event') {
            setSections(['top', 'locationtime', 'description', 'archivalDescription'])
        }
        else if (type == 'none') {
            setSections(['top'])
        }
        else {
            setSections(['top', 'images', 'locationtime', 'description', 'archivalDescription'])
        }
        setSection(0)
    }, [type])

    const sectionsRef = useRef(sections);
    const setSections = data => {
        sectionsRef.current = data;
        _setSections(data);
    };

    useEffect(() => {
        if (!pending && user && auth) {
            setTimeout(() => document.getElementById(sections[0]).scrollIntoView(), 500)
        }
    }, [])

    const onDown = () => {
        if (section < (sections.length-1)) {
            document.getElementById(sections[section + 1]).scrollIntoView()
            setSection(section + 1)
        }
    }

    const onUp = () => {
        if (section > 0) {
            document.getElementById(sections[section - 1]).scrollIntoView()
            setSection(section - 1)
        }
    }

    const onArrowKey = (e) => {
        if (e.keyCode == '38' && section > 0) {
            document.getElementById(sectionsRef.current[section - 1]).scrollIntoView()
            setSection(section - 1)
        }
        else if (e.keyCode == '40' && section < (sectionsRef.current.length-1)) {
            document.getElementById(sectionsRef.current[section + 1]).scrollIntoView()
            setSection(section + 1)
        }
        else if (e.keyCode == '37' && section > 0) {
            document.getElementById(sectionsRef.current[section - 1]).scrollIntoView()
            setSection(section - 1)
        }
        else if (e.keyCode == '39' && section < (sectionsRef.current.length-1)) {
            document.getElementById(sectionsRef.current[section + 1]).scrollIntoView()
            setSection(section + 1)
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', onArrowKey);
        return () => {
            window.removeEventListener('keydown', onArrowKey);
        };
    }, [section])

    const [editImage, setEditImage] = useState()

    const openPopup = useCallback((editImage) => {
        setAddPopup('photoEdit')
        setEditImage(editImage)
    })

    const [newImage, setNewImage] = useState()
    const [newImagePing, setNewImagePing] = useState(true)

    const getNewImage = useCallback((newImage) => {
        setNewImagePing(!newImagePing)
        setNewImage(newImage)
    })

    //Scroll locking

    // useEffect(() => {
    //     disableScroll.options = {
    //             authorizedInInputs: [32, 37, 38, 39, 40],
    //             disableKeys: true,
    //             disableScroll: false,
    //             disableWheel: true,
    //             keyboardKeys: [32, 33, 34, 35, 36, 37, 38, 39, 40],
    //         }
    //     disableScroll.on();
    // },[])

    useEffect(() => {
        if (addPopup) {
            disableScroll.options = {
                authorizedInInputs: [32, 37, 38, 39, 40],
                disableKeys: true,
                disableScroll: false,
                disableWheel: true,
                keyboardKeys: [32, 33, 34, 35, 36, 37, 38, 39, 40],
            }
            if (['people', 'creator', 'contributors', 'collection', 'organizations', 'photoEdit'].includes(addPopup)) {  
                disableScroll.off();
            }
            else {
                disableScroll.on();
            }
        }
    }, [addPopup])

    return (
        <div>
                <div className={classes.allDiv}>
                    { addPopup && ['subjects', 'people', 'creator', 'contributors', 'collection', 'organizations', 'photoEdit'].includes(addPopup) ?
                        <div className={classes.popupModal}>
                            <div className={classes.popup}>
                                <div className={classes.closeButtonDivDiv}>
                                    <div onClick={toggleAddPopup} id="none" className={classes.closeButtonDiv}>
                                        <svg id="none" onClick={toggleAddPopup} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="rgb(242, 73, 51)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line id="none" x1="18" y1="6" x2="6" y2="18"></line><line id="none" x1="6" y1="6" x2="18" y2="18"></line></svg>
                                    </div>
                                </div>
                                { addPopup === 'subjects' ?
                                    <NewSubject toggleAddPopupComponent={toggleAddPopupComponent} />
                                    : addPopup === 'people' ?
                                    <NewPerson role='person' toggleAddPopupComponent={toggleAddPopupComponent} peopleOptions={peopleOptions} togglePeopleLoading={togglePeopleLoading}/>
                                    : addPopup === 'creator' ?
                                    <NewPerson role='creator' toggleAddPopupComponent={toggleAddPopupComponent} peopleOptions={peopleOptions} togglePeopleLoading={togglePeopleLoading}/>
                                    : addPopup === 'contributors' ?
                                    <NewPerson role='contributor' toggleAddPopupComponent={toggleAddPopupComponent} peopleOptions={peopleOptions} togglePeopleLoading={togglePeopleLoading}/>
                                    : addPopup === 'collection' ?
                                    <NewCollection toggleAddPopupComponent={toggleAddPopupComponent} />
                                    : addPopup === 'organizations' ?
                                    <NewOrganization toggleAddPopupComponent={toggleAddPopupComponent} />
                                    : addPopup === 'photoEdit' && editImage ?
                                    <PhotoEditPopup editImage={editImage} newImage={getNewImage} watermark={watermark} />
                                    : null
                                } 
                            </div>
                        </div>
                        : null
                    }
                    { pending === false && auth && user ?
                        <>
                            <PublicHeader multiline={true} />
                            <div className={classes.typeDiv} id='type'>
                                <p className={classes.title}>Indicate the type of item you are adding</p>
                                <div className={classes.typeInputDiv}>
                                    <ItemType error={typeError} />
                                </div>
                                { type != 'none' ?
                                    <div className={classes.addItemHeaderButtonDiv}>
                                        <Link className={classes.button} to='/collection'>Back</Link>
                                        <span className={classes.button} onClick={onDown}>Next</span>
                                    </div>
                                    : null
                                }
                            </div>
                            {type!="event" && type!="video" ?
                                <div className={classes.imageUploadPreviewDiv} id='images'>
                                    <div>
                                        <p className={classes.title}>Add one or more pictures of your item (if applicable)</p>
                                        <div>
                                            <ImageUploadPreview watermark={watermark} openPopup={openPopup} newImage={newImage} newImagePing={newImagePing} getImages={getImages} />
                                        </div>
                                        <div className={classes.watermarkDiv}>
                                            <label htmlFor="watermark">Watermark Text</label>
                                            <input id="watermark" className={classes.input} value={watermark} onChange={onChange}></input>
                                        </div>
                                    </div>
                                    <div className={classes.addItemHeaderButtonDiv}>
                                        <span className={classes.button} onClick={onUp}>Back</span>
                                        <span className={classes.button} onClick={onDown}>Next</span>
                                    </div>
                                </div>
                                : null
                            }
                            {type == 'video' ?
                                <div className={classes.mainDiv} id="url">
                                    <span className={classes.title}>Add a link to a YouTube video</span>
                                    <div className={classes.typeInputDiv}>
                                        <ItemExternalURL error={externalURLError} />
                                    </div>
                                    <div className={classes.addItemHeaderButtonDiv}>
                                        <span className={classes.button} onClick={onUp}>Back</span>
                                        <span className={classes.button} onClick={onDown}>Next</span>
                                    </div>
                                </div>
                                : null
                            }
                            <div className={classes.mainDiv} id="locationtime">
                                <p className={classes.title}>Indicate your item's spatial and temporal context (location and time)</p>
                                <div className={classes.infoSubDiv}>
                                    <div>                 
                                        {/* <div className={classes.infoSubDiv}>
                                                    
                                        </div> */}
                                        <div className={classes.inputDiv}>
                                            <ItemLocation features={features} loading={loading} />
                                        </div>
                                        <br />
                                        <div className={classes.inputDiv}>
                                            <ItemDate />
                                        </div>
                                    </div>
                                    <div className={classes.mapDiv}>
                                        <span>Click on the map to set your item's location</span>
                                        <LocationSelector features={features} loading={loading} />
                                    </div>
                                </div>
                                <div className={classes.addItemHeaderButtonDiv}>
                                    <span className={classes.button} onClick={onUp}>Back</span>
                                    <span className={classes.button} onClick={onDown}>Next</span>
                                </div>
                            </div>
                            
                            <div className={classes.mainDiv} id="description">
                                <span className={classes.title}>Describe your item</span>
                                <div className={classes.infoSubDivColumns}>
                                    <div className={classes.column}>
                                        <div className={classes.inputDiv}>
                                            <ItemTitle />
                                        </div>
                                        <div className={classes.inputDiv}>
                                            <ItemPeople peopleOptions={peopleOptions} peopleLoading={peopleLoading} />
                                            <span className={classes.addButton} id='people' onClick={toggleAddPopup}>Add a person</span>
                                        </div>
                                        <div className={classes.inputDiv}>
                                            <ItemDescription descriptionErrorChange={descriptionErrorChange} error={descriptionError}/>
                                        </div>
                                        
                                    </div>
                                    <div className={classes.column}>
                                        <div className={classes.inputDiv}>
                                            <ItemSubjects error={subjectsError} refresh={addPopup} />
                                            <span className={classes.addButton} id='subjects' onClick={toggleAddPopup}>Add Subject</span>
                                        </div>
                                        
                                        <div className={classes.inputDiv}>
                                            <ItemOrganizations refresh={addPopup} />
                                            <span className={classes.addButton} id='organizations' onClick={toggleAddPopup}>Add Organization</span>
                                        </div>
                                        {type != 'video' ?
                                            <div className={classes.inputDiv}>
                                                <ItemExternalURL urlErrorChange={urlErrorChange} error={externalURLError} />
                                            </div>
                                            :null
                                        }
                                    </div>
                                </div>
                                <div className={classes.addItemHeaderButtonDiv}>
                                    <span className={classes.button} onClick={onUp}>Back</span>
                                    <span className={classes.button} onClick={onDown}>Next</span>
                                </div>
                            </div>
                            <div className={classes.mainDiv} id="archivalDescription">
                                <p className={classes.title}>Archival Description</p>
                                <div className={classes.infoSubDiv}>
                                    <div className={classes.inputDiv}>
                                        <ItemCreator peopleOptions={peopleOptions} peopleLoading={peopleLoading} />
                                        <span className={classes.addButton} id='creator' onClick={toggleAddPopup}>Add Creator</span>
                                    </div>
                                    {user.organization === 'bhs' ?
                                        <div className={classes.inputDiv}>
                                            <ItemCollection refresh={addPopup} />
                                            <span className={classes.addButton} id='collection' onClick={toggleAddPopup}>Add Collection</span>
                                        </div>
                                        : null
                                    }
                                    {user.organization === 'bhs' ?
                                        <div className={classes.inputDiv}>
                                            <ItemIdentifier idErrorChange={idErrorChange} error={archivalIdError} />
                                        </div>
                                        : null
                                    }
                                    <div className={classes.inputDiv}>
                                        <ItemContributors peopleOptions={peopleOptions} peopleLoading={peopleLoading} />
                                        <span className={classes.addButton} id='contributors' onClick={toggleAddPopup}>Add Contributor</span>
                                    </div>
                                    {user.organization === 'bhs' ?
                                        <div className={classes.inputDiv}>
                                            <ItemCopyrightInfo />
                                        </div>
                                        : null
                                    }
                                    {user.organization != 'bhs' ?
                                        <>
                                            <div>
                                                <span className={classes.disclaimer}><b>Disclaimer:&nbsp;&nbsp;</b></span>
                                            </div>
                                            <div>
                                                <span>Once you upload your item and our staff review and approve it, it and any associated information, images, and media will be visible to the public. By clicking submit, you acknowledge that this will be the case. Additionally, your entry may be modified slightly by staff to ensure historical accuracy as well as consistency with other entries.</span>
                                            </div>
                                        </>
                                        : null
                                    }
                                </div>
                                <div className={classes.addItemHeaderButtonDiv}>
                                    <span className={classes.button} onClick={onUp}>Back</span>
                                    <span className={classes.button} onClick={onClick}>Submit</span>
                                </div>
                            </div>
                        </>
                    : <div className={classes.loadingIconContainer}>
                        <SpinningCircles fill="rgb(66, 132, 150)" />
                    </div>
                    }
                </div>
        </div>
    )

}

export default AddItem;