import {useState, useEffect} from 'react'
import axios from 'axios'

import { Link } from 'react-router-dom';

import ImageDisplay from '../../searchbar/imageDisplay';

import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    scanViewer: {
        display: "grid",
        gridTemplateColumns: "5% 90% 5%",
        height: "30vh",
    },
    scanBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    scan: {
        maxWidth: "100%",
        maxHeight: "30vh",
        borderRadius: 2,
        cursor: "pointer",
        justifyContent: "center",
        alignItems: "center",
    },
    progression: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgb(199, 199, 199)",
        }
    },
    progLeft: {
        composes: "$progression",
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
    },
    progRight: {
        composes: "$progression",
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
    },
    svgLeft: {
        fontSize: 15,
        transform: "rotate(180deg)",
    },
    svgRight: {
        fontSize: 20,
    },
    description: {
        fontSize: 12,
    },
    addressSearchButton: {
        borderRadius: 5,
        background: "white",
        whiteSpace: "nowrap",
        color: "rgb(36, 142, 156)",
        fontSize: 12,
        outline: "none",
        border: "none",
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        textDecoration: "none",
        "&:hover": {
            fontSize: 14,
            textDecoration: "underline"
        }
    },
    addressSearchButtonDiv: {
        display: "flex",
        justifyContent: "space-around"
    },
    video: {
        width: "100%",
        height: "100%",
        border: 0,
    },
})

const Item = ({item, togglePopup, toggleItemOverview, loading}) => {

    const classes = useStyles();

    // const [scan, setScan] = useState();
    // const [scans, setScans] = useState([]);
    const [scanNumber, setScanNumber] = useState(0);
    const [rightVisible, setRightVisible] = useState(false);
    const [leftVisible, setLeftVisible] = useState(false);

    const [arrowLoading, setArrowLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {setArrowLoading(false)}, 1000)
    }, [])

    const onClick = (e) => {
        if (e.target.id === "next" && scanNumber < (item.item_scans.length - 1)) {
            if (leftVisible === false) {
                setLeftVisible(true)
            }
            if (scanNumber === (item.item_scans.length - 2)) {
                setRightVisible(false)
            }
            setScanNumber(scanNumber + 1)
        }
        else if (e.target.id === "previous" && scanNumber > 0) {
            if (rightVisible === false) {
                setRightVisible(true)
            }
            if (scanNumber === 1) {
                setLeftVisible(false)
            }
            setScanNumber(scanNumber - 1)
        }
    }

    const domain = 'https://www.bayfieldhistoricalwebmap.ca'

    // async function getScans(id){
        
    //     const scanRes = await fetch(`${domain}/api/scans/?search=${id}`)
    //     const scanJson = await scanRes.json()
    //     return scanJson
    // }

    // useEffect(() => {
    //     if(item) {
    //         getScans(item.id)
    //             .then(res => {
    //                 setScans(res)
    //                 if (res.length > 1) {
    //                     setRightVisible(true)
    //                 }
    //                 // getScan(item.id)
    //                 //     .then(res => {
    //                 //         setScan(res)
    //                 //     })
    //             })
    //     }
    // }, [])

    // useEffect(() => {
    //     if(scans && scans.length > 0) {
    //         getScan(scans[scanNumber].id)
    //             .then(res => {
    //                 setScan(res)
    //             })
    //     }
    // }, [scanNumber])

    return (
        <div>
            {item.type === 'video' ?
                <div>
                    <iframe className={classes.video} src={item.externalRecordURL} allowFullScreen>
                    </iframe>
                </div>
            : item && item.type!=='video' && item.item_scans.length > 0 && !loading ? 
            //     <div className={classes.scanViewer}>
            //         <div id="previous" onClick={onClick} className={classes.progLeft}>
            //             { leftVisible && !arrowLoading ? 
            //                 <svg id="previous" onClick={onClick} className={classes.svgLeft} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            //                     viewBox="0 0 407.436 407.436">
            //                     <polygon points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 "/>
            //                 </svg>
            //             : null}
            //         </div>
            //         <div className={classes.scanBox}>
            //             <img onClick={togglePopup} className={classes.scan} src={`${domain}/api/scan/image/${item.item_scans[scanNumber].id}/`} alt={item.item_scans[scanNumber].id} />
            //         </div>
            //         <div id="next" onClick={onClick} className={classes.progRight}>
            //             { rightVisible && !arrowLoading ? 
            //                 <svg id="next" onClick={onClick} className={classes.svgRight} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            //                     viewBox="0 0 407.436 407.436">
            //                     <polygon points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 "/>
            //                 </svg>
            //             : null}
            //         </div>
            //     </div>
            //     : <div className={classes.scanViewer}>
            //         <div id="previous" onClick={onClick} className={classes.progLeft}>
            //             {/* { leftVisible ? 
            //                 <svg id="previous" onClick={onClick} className={classes.svgLeft} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            //                     viewBox="0 0 407.436 407.436">
            //                     <polygon fill="white" points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 "/>
            //                 </svg>
            //             : null} */}
            //         </div>
            //         <div className={classes.scanBox}>
            //             <img className={classes.scan} src={`https://upload.wikimedia.org/wikipedia/commons/3/3f/Placeholder_view_vector.svg`} alt="Default image" />
            //         </div>
            //         <div id="next" onClick={onClick} className={classes.progRight}>
            //             {/* { rightVisible ? 
            //                 <svg id="next" onClick={onClick} className={classes.svgRight} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            //                     viewBox="0 0 407.436 407.436">
            //                     <polygon fill="white" points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 "/>
            //                 </svg>
            //             : null} */}
            //         </div>
            //     </div>
            // }
            // <p className={classes.description}>{item.general_description}</p>
            // <div className={classes.addressSearchButtonDiv}>
            //     <button className={classes.addressSearchButton} id={item.id} onClick={toggleItemOverview}>More Information</button>
            // </div>
                <>
                    <ImageDisplay scans={item.item_scans} togglePopup={togglePopup} />
                    <p className={classes.description}>{item.general_description}</p>
                    <div className={classes.addressSearchButtonDiv}>
                        <button className={classes.addressSearchButton} id={item.id} onClick={toggleItemOverview}>More Information</button>
                    </div>
                </>
            : null}
        </div>
    )
}

export default Item