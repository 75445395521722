import {useEffect, useState} from 'react';

import { useSelector } from 'react-redux';

import {createUseStyles} from 'react-jss';
import BusinessResultBox from './businessResultBox';

const useStyles = createUseStyles({
    searchBarDiv: {
        display: "flex",
        justifyContent: "center",
        marginBottom: 5,
    },
    searchBar: {
        border: "none",
        borderRadius: 5,
        height: 30,
        width: "90vw",
        padding: 2,
        paddingLeft: 8,
        marginBottom: 5,
        "&:focus": {
            border: 10,
            borderColor: "grey",
            outline: "none",
        }
    },
    locationResults: {
        overflowY: "scroll",
        overflowX: "none",
        height: "30vh",
        "&::-webkit-scrollbar": {
            width: 5,
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "rgba(255, 255, 255, 0)",
            opacity: 0,
            borderRadius: "10px",
        },
        backgroundColor: "rgba(176, 54, 5, 0)",
        opacity: 1,
        padding: 3,
        borderRadius: 15,
        width: "90vw",
        // marginBottom: 8,
    },
    locationResultsContainer: {
        display: "flex",
        justifyContent: "center",
    },
    locationDiv: {
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 1)",
        marginRight: 0,
        marginLeft: 5,
        marginTop: 10,
        marginBottom: 10,
        padding: 12,
        borderRadius: 12,
    },
    resultHeading: {
        fontSize: 14,
        fontWeight: "150",
        color: "black",
        marginTop: 2,
    },
    resultText: {
        fontSize: 12,
        fontWeight: "150",
        color: "black",
        marginTop: 2,
    },
    addressSearchButton: {
        borderRadius: 5,
        background: "white",
        whiteSpace: "nowrap",
        color: "black",
        fontSize: 12,
        outline: "none",
        border: "none",
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        textDecoration: "none",
        height: "4vh",
        display: "flex",
        alignItems: "center",
        paddingLeft: 5,
        paddingRight: 5,
        "&:hover": {
            fontSize: 14,
            backgroundColor: "rgb(66, 132, 150)",
            color: "white",
        },
        
    },
    addressSearchButtonDiv: {
        display: "flex",
        justifyContent: "space-around"
    },
});

const OrgSearch = () => {
    
    const classes = useStyles();

    const [searchTerm, setSearchTerm] = useState('');

    const [orgs, setOrgs] = useState([])

    const domain = 'https://www.bayfieldhistoricalwebmap.ca'

    //Get and set search term
    useEffect(() => {
        console.log(searchTerm)
        if (searchTerm && searchTerm.length > 2) {
            fetch(`${domain}/api/organizations/?search=${searchTerm}`)
                .then(res => {
                    if (res.status != 200) {
                        setOrgs([])
                        return;
                    }
                    res.json()
                        .then(res => {
                            setOrgs(res)
                        })
                        .catch(err => {
                            console.log(err)
                        })
                })
                .catch(err => {
                    console.log(err)
                })
        }
        else {
            setOrgs([])
        }
    }, [searchTerm])
    
    return(
        <div>
            <div className = {classes.searchBarDiv}>
                <input 
                    className = {classes.searchBar}
                    type="text"
                    placeholder="Search organizations"
                    id="orgSearch"
                    onChange = {e=> {setSearchTerm(e.target.value)}}
                    autocomplete="off"
                />
            </div>
            <div className={classes.locationResultsContainer}>
                <div className={classes.locationResults}>
                    {orgs.map(org => {
                        return(<BusinessResultBox org={org} />)
                    })}
                    {/* {found && found.values_.name.length > 0
                        ? <AddressResultBox zone={found} toggleZoneOverview={toggleZoneOverview} />
                        : results.map((zone) => {
                            return(
                                <>
                                    <AddressResultBox zone={zone} toggleZoneOverview={toggleZoneOverview} />
                                </>
                            )
                        })
                    } */}
                </div>
            </div>
        </div>
    )
}

export default OrgSearch;