import {useState, useEffect} from 'react'
import left from './left.svg'
import right from './right.svg'

import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    scanViewer: {
        display: "grid",
        gridTemplateColumns: "7% 86% 7%",
    },
    scanBoxBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    },
    scanBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    scan: {
        maxWidth: "100%",
        maxHeight: "35vh",
        borderRadius: 2,
        cursor: "pointer",
    },
    progression: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        transition: "all 0.2s ease-in-out",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgb(210, 210, 210)",
        }
    },
    progLeft: {
        composes: "$progression",
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
    },
    progRight: {
        composes: "$progression",
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
    },
    svgLeft: {
        fontSize: 20,
        transform: "rotate(180deg)",
    },
    svgRight: {
        fontSize: 20,
    },
})



const ImageDisplay = ({scans, togglePopup}) => {

    const classes = useStyles();

    const [scanNumber, setScanNumber] = useState(1);

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {setLoading(false)}, 500)
    }, [])

    const domain = "https://www.bayfieldhistoricalwebmap.ca"

    const onClick = (e) => {
        if (e.target.id === "next" && scanNumber < scans.length) {
            setScanNumber(scanNumber + 1)
        }
        else if (e.target.id === "previous" && scanNumber > 1) {
            setScanNumber(scanNumber - 1)
        }
    }

    return(
        <>
        {scans ?
            <div>
                <div className={classes.scanBoxBox}>
                    { scans.length > 0
                        ? <div className={classes.scanViewer}>
                            <div id="previous" onClick={onClick} className={classes.progLeft}>
                                { scanNumber > 1 && !loading ? 
                                    <img src={left} id="previous" onClick={onClick} className={classes.svgLeft} />
                                : null}
                            </div>
                            <div className={classes.scanBox}>
                                <img onClick={togglePopup} className={classes.scan} src={`${domain}/api/scan/image/${scans[scanNumber-1].id}/`} alt={scans[scanNumber-1].id} />
                            </div>
                            <div id="next" onClick={onClick} className={classes.progRight}>
                                { scanNumber < scans.length && !loading ? 
                                    <img src={right} id="next" onClick={onClick} className={classes.svgRight}/>
                                : null}
                            </div>
                        </div>
                        : <div className={classes.scanViewer}>
                            <div id="previous" onClick={onClick} className={classes.progLeft}>
                                {/* { leftVisible ? 
                                    <svg id="previous" onClick={onClick} className={classes.svgLeft} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                        viewBox="0 0 407.436 407.436">
                                        <polygon fill="black" points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 "/>
                                    </svg>
                                : null} */}
                            </div>
                            <div className={classes.scanBox}>
                                <img className={classes.scan} src={`https://upload.wikimedia.org/wikipedia/commons/3/3f/Placeholder_view_vector.svg`} alt="Default image" />
                            </div>
                            <div id="next" onClick={onClick} className={classes.progRight}>
                                {/* { rightVisible ? 
                                    <svg id="next" onClick={onClick} className={classes.svgRight} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 407.436 407.436">
                                        <polygon fill="black" points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 "/>
                                    </svg>
                                : null} */}
                            </div>
                        </div>
                    }
                </div>
            </div>
        : null}
        </>
    )
}

export default ImageDisplay;