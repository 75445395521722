import React, {useState, useRef, useEffect} from 'react';

import { Map, View } from 'ol'
import VectorLayer from 'ol/layer/Vector'
import TileLayer from 'ol/layer/Tile'
import VectorSource from 'ol/source/Vector'
import { TileArcGISRest } from 'ol/source'
import {Attribution, defaults as defaultControls, FullScreen} from 'ol/control';
import {Fill, Stroke, Style } from 'ol/style';

import {useSelector, useDispatch} from 'react-redux';

import { zoneUpdate } from '../../store/actions/mapActions';
import { zoneNameUpdate } from '../../store/actions/itemAddActions'

import {SpinningCircles} from 'react-loading-icons';

import createUseStyles from 'react-jss';

const useStyles = createUseStyles({
    loadingIconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
    },
})

function LocationSelector({ features, loading }) {

    const classes = useStyles();
    
    const [ map, setMap ] = useState()
    const [ featuresLayer, setFeaturesLayer ] = useState()
    const [ locationNameHover, setLocationNameHover ] = useState('Hover over a location')

    let mapZone = useSelector((state) => state.map.zone)
    let zoneName = useSelector((state) => state.itemAdd.zonename)

    const dispatch = useDispatch();

    const mapElement = useRef()

    const mapRef = useRef()
    mapRef.current = map

    //Initialize map
    useEffect( () => {
        //Declare Layers
        const huronCountyImagery = new TileLayer({
            source: new TileArcGISRest({
                url: 'https://gis.huroncounty.ca/hurongis/rest/services/Imagery/Huron_Imagery_2015/ImageServer',
            })
        })

        const initialFeaturesLayer = new VectorLayer({
            source: new VectorSource()
        })

        var attribution = new Attribution({
            collapsible: false,
        });

        var fullscreen = new FullScreen()

        const initialMap = new Map({
            target: mapElement.current,
            layers: [ 
                huronCountyImagery,
                initialFeaturesLayer
            ],
            view: new View({
                projection: 'EPSG:3857',
                center: [-9094456.127456, 5397978.160185],
                zoom: 15,
                minZoom: 14,
            }),
            controls: defaultControls({attribution: false, rotate: false}).extend([attribution]).extend([fullscreen]),
        })

        setMap(initialMap)
        setFeaturesLayer(initialFeaturesLayer)
    },[])

    //Location hover and click
    useEffect( ()=> {
        let style = new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0)',
            }),
            stroke: new Stroke({
                color: 'rgba(0, 0, 0, 0)',
                width: 0.5,
            }),
        });

        try {
            featuresLayer.setSource(
                new VectorSource({
                    features: features
                })
            )
            featuresLayer.setStyle(style)

            featuresLayer.setZIndex(997)

            let selected = null

            var highlightStyle = new Style({
                fill: new Fill({
                color: 'rgba(10, 166, 12, 0.5)',
                }),
                stroke: new Stroke({
                color: 'rgba(10, 140, 12, 0.8)',
                width: 3,
                }),
            });

            mapRef.current.on('pointermove', function (e) {
                if (selected !== null) {
                    selected.setStyle(undefined);
                    selected = null;
                }

                mapRef.current.forEachFeatureAtPixel(e.pixel, function (f) {
                    selected = f;
                    f.setStyle(highlightStyle);
                    return true;
                });

                if (selected) {
                    setLocationNameHover(selected.get('name'));
                } else {
                    setLocationNameHover('Hover over a location');
                }
            });

            mapRef.current.on('click', function (e) {
                if (selected !== null) {
                    selected.setStyle(undefined);
                    selected = null;
                }

                mapRef.current.forEachFeatureAtPixel(e.pixel, function (f) {
                    selected = f;

                    if (selected) {
                        let mapZoneSelected = selected.get('name')
                        let mapZoneSelectedName = selected.get('name')
                        if (mapZoneSelected !== mapZone) {
                            dispatch(zoneUpdate(mapZoneSelected));
                            dispatch(zoneNameUpdate(mapZoneSelectedName))
                        }
                        else if (mapZoneSelected === mapZone && mapZone != null) {
                            dispatch(zoneUpdate(null))
                        }
                    } else {
                        return;
                    }
                })
            });
        }
        catch {
            return;
        }
    }, [features, mapZone])

    useEffect(() => {
        const searchStyle = new Style({
            fill: new Fill({
            color: 'rgba(245, 24, 0, 0.5)',
            }),
            stroke: new Stroke({
            color: 'rgba(245, 24, 0, 0.8)',
            width: 3,
            }),
        });

        const style = new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0)',
            }),
            stroke: new Stroke({
                color: 'rgba(0, 0, 0, 0)',
                width: 0.5,
            }),
        });

        if (mapZone && features.length && mapRef.current){
            let targetZone = features.find((zone) => zone.values_.name === mapZone)
            // console.log(targetZone);

            mapRef.current.getView().fit(targetZone.getGeometry(), {padding: [150, 150, 150, 150]})
                        
            featuresLayer.setStyle(function(feature) {
                const id = feature.get('name');
                return id === targetZone.values_.name ? searchStyle : style;
            })
        }
    }, [mapZone, features])

    useEffect(() => {
        if (map != null && zoneName != null) {
            const searchStyle = new Style({
                fill: new Fill({
                color: 'rgba(245, 24, 0, 0.5)',
                }),
                stroke: new Stroke({
                color: 'rgba(245, 24, 0, 0.8)',
                width: 3,
                }),
            });
    
            const style = new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0)',
                }),
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 0)',
                    width: 0.5,
                }),
            });

            try {
                let targetZone = features.find((zone) => zone.values_.name === zoneName)
                dispatch(zoneUpdate(targetZone.values_.name))
                mapRef.current.getView().fit(targetZone.getGeometry(), {padding: [150, 150, 150, 150]})
                        
                featuresLayer.setStyle(function(feature) {
                    const id = feature.get('name');
                    return id === targetZone.values_.name ? searchStyle : style;
                })
            }
            catch {
                return;
            }
        }
    }, [zoneName, features])

    useEffect(() => {
        try{
            if (features.find((zone) => zone.values_.name.toLowerCase() === zoneName.toLowerCase())) {
                setLocationNameHover(zoneName)
            }
        }
        catch {
            return;
        }
    }, [zoneName])

    return (
        <div id="formMap">
            <div ref={mapElement} className="map-container-form map" id="map">
                <div className="location-label-form">
                    <p>{ loading ? "Loading..." : locationNameHover===null ? 'Hover over a location' : locationNameHover }</p>
                </div>
            </div>
        </div>
    )
}

export default LocationSelector