import {useSelector, useDispatch} from 'react-redux';

import {typeUpdate} from '../../../store/actions/itemAddActions';

import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    div: {
        display: "flex",
        flexDirection: "column",
        width: "40%",
    },
    input: {
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "white",
        marginTop: 3,
    },
    errorMessage: {
        color: "red",
        fontSize: 10,
    },
});

const ItemType = ({error}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const type = useSelector((state) => state.itemAdd.type)

    const onTypeChange = (e) => {
        dispatch(typeUpdate(e.target.value))
    }

    return (
        <div className={classes.div}>
            <label htmlFor="type">Type</label>
            { error
                ? <div className={classes.errorMessage}>{error}</div>
                : null
            }
            <select className={classes.input} value={type ? type : ''} id="type" onChange={onTypeChange}>
                <option value='none'> </option>
                <option value='photograph'>Photograph</option>
                <option value='postcard'>Postcard</option>
                <option value='event'>Event</option>
                <option value='video'>Video</option>
                {/* <option value='oral_history'>Oral History</option> */}
                <option value='object'>Object</option>
                {/* <option value='newspaper'>Newspaper</option> */}
                <option value='paper_record'>Paper Record</option>
            </select>
        </div>
    )
}

export default ItemType