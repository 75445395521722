import { useEffect, useState } from 'react';

import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    imageUploadPreviewDiv: {
        height: "32vh",
        display: "grid",
        gridTemplateColumns: "25.5% 74.5%",
        gridGap: 5,
        alignItems: "center",
    },
    imagePreviewDiv: {
        height: "32vh",
        padding: "1vh",
        display: "flex",
        justifyContent: "flex-start",
        overflowX: "scroll",
        overflowY: "hidden",
        "&::-webkit-scrollbar": {
            height: 5,
        },
        "&::-webkit-scrollbar-thumb": {
            background: "rgba(200, 200, 200, 0.8)",
            borderRadius: "10px",
        },
    },
    dropDiv: {
        borderWidth: 4,
        border: "solid",
        borderColor: "rgba(175, 175, 175, 1)",
        borderRadius: 10,
        height: "30vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(219, 219, 219, 1)",
        color: "black",
        width: "100%",
        textAlign: "center",
    },
    imageDiv: {
        padding: 5,
        borderRadius: 10,
        position: "relative",
        cursor: "pointer",
    },
    imagePreview: {
        height: "30vh",
    },
    deleteDiv: {
        position: "absolute",
        top: 10,
        right: 10,
        width: 20,
        height: 20,
        "&:hover": {
            transform: "scale(1.5)",
            transition: "0.2s",
        },
    },
});

const ImageUploadPreview = ({watermark, openPopup, newImage, newImagePing, getImages}) => {

    const classes = useStyles();

    const [files, setFiles] = useState([]);

    // const [fileList, setFileList] = useState([]);

    const handleDragOver = e => {
        e.preventDefault();
        document.getElementById("dropzone").style.backgroundColor = "white";
        document.getElementById("dropzone").style.color = "black";
    }

    const handleDragLeave = e => {
        e.preventDefault();
        document.getElementById("dropzone").style.backgroundColor = "rgba(219, 219, 219, 1)";
        document.getElementById("dropzone").style.color = "black";
    }

    const handleOnDrop = e => {
        //prevent the browser from opening the image
        e.preventDefault(); 
        e.stopPropagation(); 

        let updatedFiles = files

        // setFileList(e.dataTransfer.files);
        let maxOrder
        if (files) {
            const orders = files.map(file => {return file.order})
            maxOrder = Math.max(orders)
        }
        else {
            maxOrder = 0
        }

        for (let i=0; i < e.dataTransfer.files.length; i++) {
            const file = e.dataTransfer.files[i]
            const url = URL.createObjectURL(file)
            

            const updateObject = {
                id: parseInt(maxOrder + (i+1)),
                file: file,
                url: url,
                order: maxOrder + (i+1),
                color: 'black',
                position: 'br',
            }
            updatedFiles = [...updatedFiles, updateObject]
            console.log(updatedFiles)
            setFiles(updatedFiles)
        }

        document.getElementById("dropzone").style.backgroundColor = "rgba(219, 219, 219, 1)";
        document.getElementById("dropzone").style.color = "black";
    }

    const onClick = e => {
        let updatedFiles = files
        if (updatedFiles.length > 0) {
            updatedFiles = updatedFiles.filter(file => parseInt(file.id) !== parseInt(e.target.id))
            setFiles(updatedFiles)
        }
    }

    useEffect(() => {
        let imageArray = []
        console.log(files)
        
        for (let i=0; i<files.length; i++) {
            let formData = new FormData()
            // console.log(item)
            // formData.append('item', item)
            // console.log(files[i].file)
            formData.append('image', files[i].file)
            formData.append('watermark', watermark)
            formData.append('order', files[i].order)
            formData.append('color', files[i].color)
            formData.append('position', files[i].position)
            imageArray.push(formData)
        }
        getImages(imageArray)
    }, [files, watermark, newImagePing])

    const [dragId, setDragId] = useState();

    const handleDrag = e => {
        setDragId(e.currentTarget.id);
    }

    const handleDrop = (e) => {
        console.log("Drop: ", e.currentTarget.id);
        console.log("Drag: ", dragId)

        const dragFile = files.find((file) => file.id == dragId);
        const dropFile = files.find((file) => file.id == e.currentTarget.id);
    
        const dragFileOrder = dragFile.order;
        const dropFileOrder = dropFile.order;
    
        const updatedFileOrder = files.map((file) => {
            if (file.id == dragId) {
                file.order = dropFileOrder;
            }
            if (file.id == e.target.id) {
                file.order = dragFileOrder;
            }
            return file;
        });
        setFiles(updatedFileOrder);
    }

    const onOpenPopup = (e) => {
        console.log(e.target.id)
        const editImage = files.find(file => file.id == e.target.id)
        openPopup(editImage)
    }

    useEffect(() => {
        if (newImage) {
            const oldImage = files.find(file => file.id == newImage.id)
            const oldIndex = files.indexOf(oldImage)
            files[oldIndex] = newImage
            console.log(newImage)
        } 
    }, [newImagePing])

    return (
        <>
            <div className={classes.imageUploadPreviewDiv}>
                <div id="dropzone" className={classes.dropDiv} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleOnDrop}>
                    Drag and drop your images here
                </div>
                <div className={classes.imagePreviewDiv}>
                    { files
                        ? 
                            files
                            .sort((a, b) => a.order - b.order)
                            .map((file) => {
                                return(
                                    <>
                                    {file.id ? 
                                        <div 
                                            draggable={true} 
                                            onDragOver={(e) => e.preventDefault()} 
                                            onDragStart={handleDrag}
                                            onDrop={handleDrop}
                                            className={classes.imageDiv}
                                            id={file.id}
                                        >
                                            {/* <span>{file.id + "Order:" + file.order}</span> */}
                                            <div onClick={onClick} id={file.id} className={classes.deleteDiv}>
                                                <svg id={file.id} onClick={onClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="rgb(242, 73, 51)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line id={file.id} x1="18" y1="6" x2="6" y2="18"></line><line id={file.id} x1="6" y1="6" x2="18" y2="18"></line></svg>
                                            </div>
                                            <img onClick={onOpenPopup} id={file.id} src={file.url} className={classes.imagePreview} alt={`To be uploaded. Order + ${file.order}`} />
                                            
                                        </div>
                                    : null}
                                    </>
                                )
                            })
                        : null
                    }
                </div>
            </div>
        </>
    )
}

export default ImageUploadPreview
