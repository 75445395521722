import {useSelector, useDispatch} from 'react-redux';

import {externalRecordUpdate} from '../../../store/actions/itemAddActions';

import {createUseStyles} from 'react-jss';
import ReactTooltip from 'react-tooltip';

const useStyles = createUseStyles({
    div: {
        display: "flex",
        flexDirection: "column",
        width: "90%",
    },
    input: {
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "white",
        marginTop: 3,
    },
    errorMessage: {
        color: "red",
        fontSize: 10,
    },
});

const ItemExternalURL = ({error, urlErrorChange}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const externalRecordUrl = useSelector((state) => state.itemAdd.externalRecordUrl)

    const onExternalUrlChange = (e) => {
        dispatch(externalRecordUpdate(e.target.value))
        if (e.target.value && error) {
            urlErrorChange();
        }
    }

    return (
        <div className={classes.div}>
            <ReactTooltip multiline={true} />
            <label htmlFor="pastPerfectId" data-tip="If your file or web page is hosted on a different website (e.g., a PDF), include a link to that file or web page.">External Url</label>
            { error
                ? <div className={classes.errorMessage}>{error}</div>
                : null
            }
            <input id="pastPerfectId" className={classes.input} type="url" value={externalRecordUrl ? externalRecordUrl : ''} onChange={onExternalUrlChange} autoComplete="off"></input>
        </div>
    )
}

export default ItemExternalURL