import {useEffect, useState} from 'react';

import { useSelector } from 'react-redux';

import {createUseStyles} from 'react-jss';

import AddressResultBox from './addressResultBox';


const useStyles = createUseStyles({
    searchBarDiv: {
        display: "flex",
        justifyContent: "center",
        marginBottom: 5,
    },
    searchBar: {
        border: "none",
        borderRadius: 5,
        height: 30,
        width: "93%",
        padding: 2,
        paddingLeft: 8,
        marginBottom: 5,
        "&:focus": {
            border: 10,
            borderColor: "grey",
            outline: "none",
        }
    },
    locationResults: {
        overflowY: "scroll",
        overflowX: "none",
        height: "30vh",
        "&::-webkit-scrollbar": {
            width: 5,
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "rgba(255, 255, 255, 0)",
            opacity: 0,
            borderRadius: "10px",
        },
        backgroundColor: "rgba(176, 54, 5, 0)",
        opacity: 1,
        padding: 3,
        borderRadius: 15,
        width: "90vw",
        // marginBottom: 8,
    },
    locationResultsContainer: {
        display: "flex",
        justifyContent: "center",
    },
    locationDiv: {
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 1)",
        marginRight: 0,
        marginLeft: 5,
        marginTop: 10,
        marginBottom: 10,
        padding: 12,
        borderRadius: 12,
    },
    resultHeading: {
        fontSize: 14,
        fontWeight: "150",
        color: "black",
        marginTop: 2,
    },
    resultText: {
        fontSize: 12,
        fontWeight: "150",
        color: "black",
        marginTop: 2,
    },
    addressSearchButton: {
        borderRadius: 5,
        background: "white",
        whiteSpace: "nowrap",
        color: "black",
        fontSize: 12,
        outline: "none",
        border: "none",
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        textDecoration: "none",
        height: "4vh",
        display: "flex",
        alignItems: "center",
        paddingLeft: 5,
        paddingRight: 5,
        "&:hover": {
            fontSize: 14,
            backgroundColor: "rgb(66, 132, 150)",
            color: "white",
        },
        
    },
    addressSearchButtonDiv: {
        display: "flex",
        justifyContent: "space-around"
    },
});

const AddressSearch = ({features, toggleZoneOverview}) => {

    const location = useSelector((state) => state.map.zone)
    
    const [searchTerm, setSearchTerm] = useState('');

    const classes = useStyles();

    const [found, setFound] = useState()

    const [results, setResults] = useState([])

    useEffect(() => {
        document.getElementById("locationSearch").value = location;
        if (location != null) {
            setSearchTerm(location)
        }
    }, [location])

    useEffect(() => {
        let foundZone = features.find(zone => zone.values_.name.toLowerCase() === searchTerm.toLowerCase() || (zone.values_.address && zone.values_.address.toLowerCase() === searchTerm.toLowerCase()) || zone.values_.altnames === searchTerm.toLowerCase())
        setFound(foundZone)
    }, [searchTerm])

    useEffect(() => {
        const bayfield = "bayfield main street "
        if (features && searchTerm && searchTerm.length >= 2 && !bayfield.includes(searchTerm.toLowerCase())) {
            // let resultZones = features.filter(zone => zone.values_.name.toLowerCase().includes(searchTerm.toLowerCase()) || (zone.values_.address && zone.values_.address.toLowerCase().includes(searchTerm.toLowerCase())) || zone.values_.altnames.includes(searchTerm.toLowerCase()))
            let resultZones = features.filter(zone => zone.values_.name.toLowerCase().includes(searchTerm.toLowerCase()) || (zone.values_.address && zone.values_.address.toLowerCase().includes(searchTerm.toLowerCase())) || (zone.values_.altnames && zone.values_.altnames.includes(searchTerm.toLowerCase())))
            setResults(resultZones)
        }
        else if (!searchTerm || (searchTerm && searchTerm.length < 2) || (searchTerm && bayfield.includes(searchTerm.toLowerCase()))) {
            setResults([])
        }
    }, [searchTerm])
    

    return(
        <div>
            <div className = {classes.searchBarDiv}>
                <input 
                    className = {classes.searchBar}
                    type="text"
                    placeholder="Search locations"
                    id="locationSearch"
                    onChange = {e=> {setSearchTerm(e.target.value)}}
                    autoComplete="off"
                />
            </div>
            <div className={classes.locationResultsContainer}>
                <div className={classes.locationResults}>
                    {found && found.values_.name.length > 0
                        ? <AddressResultBox zone={found} toggleZoneOverview={toggleZoneOverview} />
                        : results.map((zone) => {
                            return(
                                <>
                                    <AddressResultBox zone={zone} toggleZoneOverview={toggleZoneOverview} />
                                </>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default AddressSearch;