import {useEffect, useState} from 'react';
import { useSelector, useDispatch } from "react-redux"

import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { SliderRail, Handle, Track, Tick } from "./sliderComponents";

import {timeStartChange, timeEndChange} from '../../../store/actions/queryActions'

import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    sliderDiv: {
        marginTop: "4vh",
        width: "100%",
        height: "8vh",
        display: "flex",
        justifyContent: "center",
    },
    font: {
        color: "white",
        display: "flex",
        justifyContent: "center",
        fontSize: 14,
    },
    fontClick: {
        composes: "$font",
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    div: {
        display: "flex",
        justifyContent: "center",
    },
    timeInput: {
        width: '20%',
        height: "1.5rem",
        boxSizing: 'border-box',
        borderRadius: 8,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
    }
});


const TimeSlider = () => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const sliderStyle = {
        position: "relative",
        width: "90%",
    }

    const timeStart = useSelector((state) => state.query.timeStart)
    const timeEnd = useSelector((state) => state.query.timeEnd)

    const currentYear = new Date().getFullYear()

    const domain = [1830, currentYear];
    const [defaultValues, setDefaultValues] = useState([])

    const [defaultsSet, setDefaultsSet] = useState(false)

    useEffect(() => {
        if (!defaultsSet) {
            try {
                setDefaultValues([parseInt(timeStart.toString().substring(0,4)), parseInt(timeEnd.toString().substring(0,4))]);
                setDefaultsSet(true)
            }
            catch {
                setDefaultsSet(false)
                console.log("Caught!")
            }
        }
    }, [])

    const [timeStartDisplay, setTimeStartDisplay] = useState(parseInt(timeStart.toString().substring(0,4)))
    const [timeEndDisplay, setTimeEndDisplay] = useState(parseInt(timeEnd.toString().substring(0,4)))

    const onUpdate = (time) => {
        setTimeStartDisplay(time[0].toString())
        setTimeEndDisplay(time[1].toString())
    }

    const onChange = (years) => {
        console.log(years)
        dispatch(timeStartChange(parseInt(years[0].toString() + '0000')))
        dispatch(timeEndChange(parseInt(years[1].toString() + '9999')))
    }

    const [edit, setEdit] = useState(false)

    const onDoubleClick = (e) => {
        setEdit(true)
    }

    const onValueUpdate = (e) => {
        if (e.target.id === 'start') {
            setTimeStartDisplay(e.target.value)
        }
        else if (e.target.id === 'end') {
            setTimeEndDisplay(e.target.value)
        }
    }

    const onEnter = (e) => {
        if (e.keyCode === 13) {
            dispatch(timeStartChange(parseInt(timeStartDisplay.toString() + '0000')))
            dispatch(timeEndChange(parseInt(timeEndDisplay.toString() + '9999')))
            setDefaultValues([timeStartDisplay, timeEndDisplay])
            setEdit(false)
        }
    }

    return(
        <>
            {defaultValues ?
                <>
                    <div className={classes.sliderDiv}>
                        
                        <Slider
                        mode={2}
                        step={1}
                        domain={domain}
                        rootStyle={sliderStyle}
                        // onUpdate={onUpdate}
                        onChange={onChange}
                        onUpdate={onUpdate}
                        values={defaultValues}
                        >
                        <Rail>
                            {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
                        </Rail>
                        <Handles>
                            {({ handles, getHandleProps }) => (
                            <div className="slider-handles">
                                {handles.map((handle) => (
                                <Handle
                                    key={handle.id}
                                    handle={handle}
                                    domain={domain}
                                    getHandleProps={getHandleProps}
                                />
                                ))}
                            </div>
                            )}
                        </Handles>
                        <Tracks left={false} right={false}>
                            {({ tracks, getTrackProps }) => (
                            <div className="slider-tracks">
                                {tracks.map(({ id, source, target }) => (
                                <Track
                                    key={id}
                                    source={source}
                                    target={target}
                                    getTrackProps={getTrackProps}
                                />
                                ))}
                            </div>
                            )}
                        </Tracks>
                        <Ticks count={5}>
                            {({ ticks }) => (
                            <div className="slider-ticks">
                                {ticks.map((tick) => (
                                <Tick key={tick.id} tick={tick} count={ticks.length} />
                                ))}
                            </div>
                            )}
                        </Ticks>
                        </Slider>
                    </div>
                    <div className={classes.div}>
                        {edit ? 
                            <input id='start' className={classes.timeInput} onChange={onValueUpdate} onKeyDown={onEnter} value={timeStartDisplay} />
                            : <span onDoubleClick={onDoubleClick} className={classes.fontClick}>{timeStartDisplay}</span>
                        }
                        <span className={classes.font}>–</span>
                        {edit ? 
                            <input id='end' className={classes.timeInput} onChange={onValueUpdate} onKeyDown={onEnter} value={timeEndDisplay} />
                            : <span onDoubleClick={onDoubleClick} onKeyDown={onEnter} className={classes.fontClick}>{timeEndDisplay}</span>
                        }
                    </div>
                </>
                : null
            }
        </>
    )
}

export default TimeSlider