import {useState, useEffect, useCallback } from 'react';

import '../App.css';

import PublicHeader from '../headers/PublicHeader';
import SideBar from '../components/sidebar/Sidebar'
import MapWrapper from '../components/ol_mainmap/MapWrapper'

import GeoJSON from 'ol/format/GeoJSON'

import { createUseStyles } from 'react-jss';
import ImagePopup from '../components/imagePopup';
import ZoneOverviewPopup from '../components/zoneOverviewPopup';
import ItemOverviewPopup from '../components/itemOverviewPopup';
import InitialPopup from '../components/initialPopup';

const useStyles = createUseStyles({
    allDiv: {
        position: "absolute",
        width: "100%",
    },
})

const Map = () => {

    const classes = useStyles();

    const [ features, setFeatures ] = useState([])

    const [ loading, setLoading ] = useState(true)

    useEffect( () => {
        const domain = 'https://www.bayfieldhistoricalwebmap.ca'
        fetch(`${domain}/api/locations/`)
        .then(response => response.json())
        .then( (fetchedFeatures) => {

            // parse fetched geojson into OpenLayers features
            //  use options to convert feature from EPSG:4326 to EPSG:3857
            const wktOptions = {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
            }
            const parsedFeatures = new GeoJSON().readFeatures(fetchedFeatures, wktOptions)

            // set features into state (which will be passed into OpenLayers
            //  map component as props)
            setFeatures(parsedFeatures)
            setLoading(false)
        })
    }, [])

    const [popupImage, setPopupImage] = useState();

    const togglePopup = useCallback((e) => {
        setPopupImage(e.target.getAttribute('src'))
    })

    const onCloseClick = useCallback(() => {
        setPopupImage()
    })

    const [popupZone, setPopupZone] = useState();

    const toggleZoneOverview = useCallback((e) => {
        setPopupZone(e.target.id)
        console.log(e.target.id)
    })

    const toggleZoneOverviewMap = useCallback((selected) =>{
        setPopupZone(selected)
    })

    const onCloseZoneClick = useCallback(() => {
        setPopupZone()
    })

    const [popupItem, setPopupItem] = useState();

    const toggleItemOverview = useCallback((e) => {
        setPopupItem(e.target.id)
        console.log(e.target.id)
    })

    const onCloseItemClick = useCallback(() => {
        setPopupItem()
    })

    const [initialPopup, setInitialPopup] = useState(true);

    const onCloseInitialClick = useCallback(() => {
        setInitialPopup(false)
    })


    return (
        <div className={classes.allDiv}>
            {initialPopup ?
                <InitialPopup onCloseClick={onCloseInitialClick} />
                :null
            }
            { popupImage ?
                <ImagePopup popupImage={popupImage} onCloseClick={onCloseClick} />
                : null
            }
            { popupZone ?
                <ZoneOverviewPopup id={popupZone} onCloseClick={onCloseZoneClick} />
                : null
            }
            { popupItem ?
                <ItemOverviewPopup id={popupItem} onCloseClick={onCloseItemClick} />
                : null
            }
            <PublicHeader />
            <div style={{alignContent: "center", display: "grid", gridTemplateColumns: "30% 70%", justifyContent: "center"}}>
                {/* <div>
                    <p>Address Search</p>
                    { (loading===false) ?
                        <SearchBar features={features} /> :
                        <p>Loading...</p>
                    }
                    <p>Photo Viewer Here</p>
                </div> */}
                <SideBar loading={loading} features={features} togglePopup={togglePopup} toggleZoneOverview={toggleZoneOverview} toggleItemOverview={toggleItemOverview} />
                <MapWrapper features={features} loading={loading} toggleZoneOverview={toggleZoneOverviewMap} />
            </div>
        </div>
    );
};

export default Map;