import { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { toggleLayer, changeLayerOpacity } from '../../../store/actions/mapActions';

import ReactTooltip from 'react-tooltip';

import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    legendItemDiv: {
        marginBottom: 10,
    },
    itemHeadingDiv: {
        display: "flex",
        cursor: "pointer",
        paddingTop: 7,
    },
    itemHeading: {
        fontSize: 12,
        fontWeight: "150",
        color: "black",
        marginTop: 2,
        cursor: "pointer",
    },
    checkBox: {
        height: 12,
        width: 12,
        // "&::-webkit-appearance": "none",
        // "&::-moz-appearance": "none",
        // "&::-o-appearance": "none",
        // appearance: "none",
        // border: "none",
        // borderRadius: 3,
        // outline: "none",
        // transitionDuration: "0.3s",
        // backgroundColor: "whitesmoke",
        // marginRight: 5,
        // borderWidth: 0,
        // borderShadow: "none",
        // cursor: "pointer",
        // "&:hover": {
        //     backgroundColor: "rgb(83, 156, 176)",
        // },
        // "&:checked": {
        //     backgroundColor: "rgb(66, 132, 150)",
        // }
    },
    slider: {
        height: 7,
        width: 220,
    }
});

const MapItem = ({name, description}) => {

    const classes = useStyles();

    let initialCheckedState = false;

    const vis1862 = useSelector((state)=>(state.map.huronCounty1862MapVisibility));

    const vis1879 = useSelector((state)=>(state.map.belden1879MapVisibility));

    const vis1856 = useSelector((state)=>(state.map.bayfield1856MapVisibility));

    if (name === "Historical Map of Huron County - 1862") {
        initialCheckedState = vis1862
    }
    else if (name === "Belden Town Map - 1879") {
        initialCheckedState = vis1879
    }
    else if (name === "Bayfield Town Plan - 1856") {
        initialCheckedState = vis1856
    }

    const [checked, setChecked] = useState(initialCheckedState);



    let initialSliderValue = 1;

    const opa1862 = useSelector((state)=>(state.map.huronCounty1862MapOpacity));

    const opa1879 = useSelector((state)=>(state.map.belden1879MapOpacity));

    const opa1856 = useSelector((state)=>(state.map.bayfield1856MapOpacity));

    if (name === "Historical Map of Huron County - 1862") {
        initialSliderValue = opa1862
    }
    else if (name === "Belden Town Map - 1879") {
        initialSliderValue = opa1879
    }
    else if (name === "Bayfield Town Plan - 1856") {
        initialSliderValue = opa1856
    }

    const [sliderValue, setSliderValue] = useState(initialSliderValue);

    const dispatch = useDispatch();

    const onCheckedChange = (e) => {
        dispatch(toggleLayer(name, !checked));
        setChecked(!checked);
    }

    const onSlide = (e) => {
        dispatch(changeLayerOpacity(name, parseFloat(e.target.value)))
        setSliderValue(e.target.value)
    }

    return (
        <div className={classes.legendItemDiv}>
            <div className={classes.itemHeadingDiv}>
                <input className={classes.checkBox} type="checkbox" id={name} defaultChecked={checked} onChange={onCheckedChange} />
                <label htmlFor={name} data-tip={description} className={classes.itemHeading}>{name}</label>
            </div>
            <ReactTooltip multiline={true} />
            { checked
                ? <>
                    <input data-tip={"Toggle the opacity of this map"} className={classes.slider} type="range" min="0" max="1" step="0.01" value={sliderValue} onInput={onSlide} id={name + '-slider'}/>
                    <ReactTooltip multiline={true} />
                </>
                : null 
            }
        </div>
    )
}

export default MapItem