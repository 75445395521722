import SideBarButton from "./SideBarButton"

import { useSelector } from "react-redux";

import {createUseStyles} from 'react-jss';

import {SpinningCircles} from 'react-loading-icons';

const useStyles = createUseStyles({
    sideBar: {
        overflowY: "scroll",
        overflowX: "none",
        height: "83vh",
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 10,
        "&::-webkit-scrollbar": {
            width: 5,
        },
        "&::-webkit-scrollbar-thumb": {
            background: "rgba(200, 200, 200, 0.8)",
            borderRadius: "10px",
        },
    },
    loadingIconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
});

const SideBar = ({loading, features, togglePopup, toggleZoneOverview, toggleItemOverview}) => {

    const classes = useStyles();

    const legendOpen = useSelector(state => state.map.legendOpen)
    const collectionDisplayOpen = useSelector(state => state.map.collectionDisplayOpen)
    // const timeSliderOpen = useSelector(state => state.map.timeSliderOpen)
    const addressSearchOpen = useSelector(state => state.map.addressSearchOpen)
    const orgSearchOpen = useSelector(state => state.map.orgSearchOpen)

    return (
        <div className={classes.sideBar}>
            { loading === false ? 
                <div>
                    <SideBarButton open={legendOpen} name="Legend" component="Legend"/>
                    <SideBarButton open={collectionDisplayOpen} name="Images & Media" features={features} togglePopup={togglePopup} toggleItemOverview={toggleItemOverview} component="CollectionDisplay" />
                    {/* <SideBarButton open={timeSliderOpen} name="Time Slider" component="Time Slider" /> */}
                    <SideBarButton open={addressSearchOpen} name="Address Search" features={features} toggleZoneOverview={toggleZoneOverview} component="AddressSearch" />
                    <SideBarButton open={orgSearchOpen} name="Organization Search" component="Business Search" />
                </div>
                :
                <div className={classes.loadingIconContainer}>
                    <SpinningCircles fill="rgb(66, 132, 150)" />
                </div>
            }
        </div>
    )
}

export default SideBar