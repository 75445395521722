import {createUseStyles} from 'react-jss';

import { titleCase } from "title-case";

import { Link } from 'react-router-dom';

import { zoneUpdate } from '../../../store/actions/mapActions';

import { useDispatch } from 'react-redux';

const useStyles = createUseStyles({
    locationDiv: {
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 1)",
        marginRight: 0,
        marginLeft: 5,
        marginTop: 10,
        marginBottom: 10,
        padding: 12,
        borderRadius: 12,
    },
    resultHeading: {
        fontSize: 14,
        fontWeight: "150",
        color: "black",
        marginTop: 2,
    },
    resultText: {
        fontSize: 12,
        fontWeight: "150",
        color: "black",
        marginTop: 2,
    },
    addressSearchButton: {
        borderRadius: 5,
        background: "white",
        whiteSpace: "nowrap",
        color: "black",
        fontSize: 12,
        outline: "none",
        border: "none",
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        textDecoration: "none",
        height: "4vh",
        display: "flex",
        alignItems: "center",
        paddingLeft: 5,
        paddingRight: 5,
        "&:hover": {
            fontSize: 14,
            backgroundColor: "rgb(133, 133, 133)",
            color: "white",
        },
        
    },
    addressSearchButtonDiv: {
        display: "flex",
        justifyContent: "space-around"
    },
});

const AddressResultBox = ({zone, toggleZoneOverview}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const onClick = (e) => {
        dispatch(zoneUpdate(e.target.id))
    }

    return(
        <div className={classes.locationDiv} key={zone.values_.pk} id={zone.values_.pk}>
            <h3 className={classes.resultHeading}>{zone.values_.name}</h3>
            { zone.values_.altnames ? 
                JSON.parse(zone.values_.altnames).map((name) => {
                    // console.log(name)
                    return(<p className={classes.resultText}>&emsp;{titleCase(name)}</p>)
                }) 
                : null 
            }
            <div className={classes.addressSearchButtonDiv}>
                <button className={classes.addressSearchButton} onClick={onClick} id={zone.values_.name}>Zoom to Zone</button>
                <button className={classes.addressSearchButton} onClick={toggleZoneOverview} id={zone.values_.pk}>Zone Overview</button>
                {/* <Link className={classes.addressSearchButton} to={`/zone-overview/${zone.values_.pk}/`}>Zone Overview</Link> */}
            </div>
        </div>
    )
}

export default AddressResultBox