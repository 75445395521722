import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    popupModal: {
        position: "fixed",
        zIndex: 999,
        display: "block",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        overflow: "auto",
        backgroundColor: "rgba(0,0,0,0.4)",
    },
    popup: {
        backgroundColor: "#fefefe",
        borderRadius: 10,
        padding: 20,
        marginLeft: "15%",
        marginRight: "15%",
        marginTop: "5vh",
        marginBottom: "5vh",
        width: "65%",
        display: "flex",
        flexDirection: "column",
        alignItems: "top",
        justifyContent: "center",
    },
    closeButtonDivDiv: {
        display: "flex",
        justifyContent: "flex-end",
    },
    closeButtonDiv: {
        width: 25,
        height: 25,
        top: 10,
        right: 10,
        cursor: "pointer",
        "&:hover": {
            transform: "scale(1.5)",
            transition: "0.2s",
        },
    },
    font: {
        "& p,li,a": {
            fontFamily: 'Source Sans Pro',
        }
    },
    text: {
        marginBottom: 3,
        marginTop: 3,
    },
    heading: {
        marginBottom: 3,
        marginTop: 10,
    }
})

const InitialPopup = ({onCloseClick}) => {
    const classes = useStyles();

    return(
        <div className={classes.popupModal}>
            <div className={classes.popup}>
                <div className={classes.closeButtonDivDiv}>
                    <div onClick={onCloseClick} id="none" className={classes.closeButtonDiv}>
                        <svg id="none" onClick={onCloseClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="rgb(242, 73, 51)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line id="none" x1="18" y1="6" x2="6" y2="18"></line><line id="none" x1="6" y1="6" x2="18" y2="18"></line></svg>
                    </div>
                </div>
                <div className={classes.font}>
                    <h3 className={classes.heading}>Bayfield Historical Web Map</h3>
                    <p className={classes.text}>Welcome to the Bayfield Historical Web Map, maintained by the Bayfield Historical Society.</p>
                    <h4 className={classes.heading}>Features</h4>
                    <ul>
                        <li>Explore the Bayfield Historical Society's photograph and postcard collection by location (just click anywhere on the map within Bayfield to view images from that location);</li>
                        <li>View digital versions of historical maps and plans of Bayfield (see the "Legend" tab);</li>
                        <li>View information about specific properties in Bayfield (by double clicking on a location), including architectural heritage information, land ownership information, and information about organizations/businesses that were once present in that location;</li>
                        <li>Search our photograph and postcard collection using basic ("Google style") or advanced search options (see the "Collections" page);</li>
                        <li>Explore basic genealogical information; and</li>
                        <li>Add scans of your own historic photographs or postcards to our digital collection (advanced users).</li>
                    </ul>
                    <h4 className={classes.heading}>Tutorials</h4>
                    <p className={classes.text}>For more information on any of the components of this website, please visit the "Tutorials" page.</p>
                    <h4 className={classes.heading}>Support the Bayfield Historical Society</h4>
                    <p className={classes.text}>If you would like to help support this website and the ongoing efforts to digitize the Bayfield Historical Society's collections, please consider visiting the "Donate" page.</p>
                    <br />
                    <p className={classes.text}>Created by <a href="https://www.linkedin.com/in/ben-woodward-71a3931a0">Ben Woodward</a> for the <a href="http://www.bayfieldhistorical.ca" target="_blank">Bayfield Historical Society</a> in 2021.</p>
                </div>
            </div>
        </div>
    )
}

export default InitialPopup