import { useState, useCallback } from 'react';

import {createUseStyles} from 'react-jss';

import PeopleInputTag from './peopleInputTag';

const useStyles = createUseStyles({
    searchDiv: {
        position: "relative",
    },
    input: {
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "whitesmoke",
        marginTop: 3,
    },
    tagBox: {
        height: 60,
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        padding: "0.5rem",
        marginTop: 3,
        backgroundColor: "whitesmoke",
        display: "flex",
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
            width: 3,
            height: 3,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "whitesmoke",
            "&:hover": {
                backgroundColor: "rgb(207, 205, 204)",
            },
            borderRadius: "10px",
        },
    },
    errorMessage: {
        color: "red",
        fontSize: 10,
    },
    searchBox: {
        position: "absolute",
        backgroundColor: "white",
        boxShadow: "0px 0px 15px 1px grey",
        borderRadius: 6,
        width: "90%",
        zIndex: 999,
    },
    searchOptions: {
        position: "relative",
        display: "block",
        transition: "all 0.5s",
        padding: 10,
        fontSize: 14,
        borderRadius: 3,
        "&:hover": {
            backgroundColor: "grey",
            transition: "0.5s",
        },
        zIndex: 999,
        cursor: "pointer",
    },
});

const PeopleInput = ({peopleOptions, peopleLoading, getPeople, peopleInputLabel}) => {

    const classes = useStyles();

    const [people, setPeople] = useState([])

    const [peopleSearch, setPeopleSearch] = useState()

    const [errorMessage, setErrorMessage] = useState()

    const onChange = (e) => {
        setPeopleSearch(e.target.value)
        setErrorMessage(null)
        document.getElementById("peopleInput").style.backgroundColor = "whitesmoke";
    }

    const onClick = (e) => {
        let prevState = people
        if (e.target.innerHTML) {
            const trimmedInput = e.target.innerHTML.trim();

            let duplicate = false;
            // console.log(trimmedInput, e.target.id)

            if (trimmedInput.length) {
                for (let i=0; i<people.length; i++){
                    if (people[i].id === (e.target.id)) {
                        setErrorMessage("You have already added this person tag")
                        document.getElementById("peopleInput").style.backgroundColor = "rgb(245, 81, 66)";
                        duplicate = true
                        break;
                    }
                }
                if (!duplicate) {
                    prevState.push({name: trimmedInput, id: e.target.id})
                    peopleSet(prevState);
                    setPeopleSearch('');
                    document.getElementById("peopleInput").style.backgroundColor = "whitesmoke";
                }
            }
        }
    };

    const removePerson = useCallback((person) => {
        let array = people
        if (array.length > 1) {
            for (let x = 0; x < array.length; x++) {
                console.log(x)
                if (array[x].id === person) {
                    array.splice(x,1);
                }
            }
        }
        else {
            array = []
        }
        peopleSet(array);
    })

    const peopleSet = (people) => {
        setPeople(people);
        getPeople(people);
    }

    return (
        <div className={classes.searchDiv}>
            <label for="peopleInput">{peopleInputLabel}</label>
            <div className={classes.errorMessage}>{errorMessage}</div>
            <div className={classes.tagBox}>
                {people.map((people) => <PeopleInputTag id={people.id} name={people.name} removePerson={removePerson} />)}
            </div>

            <input className={classes.input} id="peopleInput" onChange={onChange} value={peopleSearch} autocomplete="off" />
            {!peopleLoading && peopleSearch && peopleOptions
                ? <div className={classes.searchBox}> 
                    {peopleOptions.filter((person) => {
                        const name = person.middle_name ? person.first_name.toLowerCase() + ' ' + person.middle_name.toLowerCase() + ' ' + person.surname.toLowerCase() : person.first_name.toLowerCase() + ' ' + person.surname.toLowerCase()
                        if (peopleSearch.length > 2 && name.toLowerCase().includes(peopleSearch.toLowerCase())) {
                            return person;
                        }
                        else if (peopleSearch.length > 2 && !name.toLowerCase().includes(peopleSearch.toLowerCase()) && peopleSearch.toLowerCase().includes(person.first_name.toLowerCase()) && peopleSearch.toLowerCase().includes(person.surname.toLowerCase())) {
                            return person;
                        }
                        return null;
                    }).map((person) =>
                        <span className={classes.searchOptions} onClick={onClick} id={person.id}>{person.middle_name ? person.first_name + ' ' + person.middle_name + ' ' + person.surname : person.first_name + ' ' + person.surname}</span>
                    )} 
                </div>
                : null
            }
        </div>
    )
}

export default PeopleInput

