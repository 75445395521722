import {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import {subjectsChange, collectionChange, searchTermChange} from '../../store/actions/queryActions';

import { Link } from 'react-router-dom';

import {createUseStyles} from 'react-jss';

import { titleCase } from "title-case";

import LinkDisplay from './linkDisplay';

import ImageDisplay from './imageDisplay';

// import PinchZoomPan from "react-image-zoom-pan";

const useStyles = createUseStyles({
    itemDiv: {
        display: "grid",
        gridTemplateColumns: "50% 45%",
        gridColumnGap: "5%",
        margin: 10,
        padding: 10,
        borderRadius: 5,
        backgroundColor: "rgba(240, 240, 240, 0.8)",
    },
    scanViewer: {
        display: "grid",
        gridTemplateColumns: "7% 86% 7%",
    },
    scanBoxBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    },
    scanBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    scan: {
        maxWidth: "100%",
        maxHeight: "35vh",
        borderRadius: 2,
        cursor: "pointer",
    },
    progression: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        transition: "all 0.2s ease-in-out",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgb(210, 210, 210)",
        }
    },
    progLeft: {
        composes: "$progression",
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
    },
    progRight: {
        composes: "$progression",
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
    },
    svgLeft: {
        fontSize: 15,
        transform: "rotate(180deg)",
    },
    svgRight: {
        fontSize: 20,
    },
    informationDiv: {
        // display: "flex",
        padding: 15,
    },
    font: {
        fontSize: 12,
        color: "black",
    },
    fontBold: {
        composes: "$font",
        fontWeight: 500,
    },
    fontSubject: {
        composes: "$font",
        marginRight: 5,
        cursor: "pointer",
        textDecoration: "underline",
        "&:hover": {
            fontSize: 14,
        },
    },
    tagDiv: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
    },
    table: {
        borderCollapse: "collapse",
        width: "100%",
        tableLayout: "fixed",
        "& > :nth-child(2n)": {
            backgroundColor: "rgb(230, 230, 230)",
        },
        "& > :nth-child(2n-1)": {
            backgroundColor: "rgb(210, 210, 210)",
        }
    },
    tableCells: {
        paddingRight: 5,
        paddingLeft: 5,
    },
    tableLeftCells: {
        composes: "$tableCells",
        width: 80,
    },
    video: {
        width: "100%",
        height: "100%",
        border: 0,
        minHeight: "35vh",
    },
    eventDiv: {
        margin: 10,
        padding: 10,
        borderRadius: 5,
        backgroundColor: "rgba(240, 240, 240, 0.8)",
        display: "flex",
        flexDirection: "column",
        minHeight: "30vh"
    },
    whiteFont: {
        composes: "$font",
        color: "white",
    },
    whiteFontTitle: {
        composes: "$whiteFont",
        fontSize: 18,
    },
})

const ItemDisplay = ({item, togglePopup, detectSubjectUpdate, id}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    //Fix the date format
    function isoDate(date) {
        let isoDate = ''
        let dateParts
        let returnDate = ""
        let months = ["January","February","March","April","May","June","July","August","September","October","November","December"]
        if (date) {
            let dateString = date.toString()
            for (let i=0; i<4; i++) {
                isoDate += dateString[i]
            }     
            if (!(dateString[5] === '0' && dateString[4] === '0')) {
                isoDate += '-'
                isoDate += dateString[4]
                isoDate += dateString[5]
                if (!(dateString[7] === '0' && dateString[6] === '0')) {
                    isoDate += '-'
                    isoDate += dateString[6]
                    isoDate += dateString[7]
                }
            }
            if (!(dateString[5] === '0' && dateString[4] === '0' && dateString[7] === '0' && dateString[6] === '0')) {
                dateParts = isoDate.split("-")
                returnDate = months[parseInt(dateParts[1])-1] + " " + dateParts[2].replace(/^0+/, '') + ", " + dateParts[0]
            }
            else {
                returnDate = isoDate
            }
            return returnDate
        }
        else {
            return null
        }
    }
    const startDate = isoDate(item.date_start)
    const endDate = isoDate(item.date_end)

    // const [scans, setScans] = useState([]);
    // const [gotten, setGotten] = useState(false);

    const domain = 'https://www.bayfieldhistoricalwebmap.ca'

    // useEffect(() => {
    //     setGotten(false)
    //     if (!gotten) {
    //         axios.get(`${domain}/api/scans/?search=${item.id}`)
    //             .then(res => {
    //                 if (res && res.status===200) {
    //                     setScans(res.data)
    //                     if (res.data.length > 1) {
    //                         setRightVisible(true)
    //                     }
    //                     setGotten(true)
    //                 }
    //             })
    //     }
    // }, [])

    const prevSubjects = useSelector(state => state.query.subjects)

    const onSubjectSet = (e) => {
        const prevIds = prevSubjects.map(subject => subject.id)
        if (detectSubjectUpdate) {
            if (prevIds.includes(e.target.id)) {
                console.log("Includes!")
            }
            else {
                prevSubjects.push({id: e.target.id, name: e.target.innerHTML})
                dispatch(subjectsChange(prevSubjects))
                dispatch(searchTermChange(''))
                detectSubjectUpdate()
            }
        }
    }

    const prevCollection = useSelector(state => state.query.collection)

    const onCollectionChange = (e) => {
        if (prevCollection === e.target.id) {
            console.log("Includes")
        }
        else {
            dispatch(collectionChange(e.target.id))
            dispatch(searchTermChange(''))
        }
    }

    //console.log(`${domain}/api/scan/image/${item.item_scans[scanNumber-1].id}/`)

    return(
        <>
        { item && item.type === 'event' ?
            <div className={classes.eventDiv} id={id} key={id}>
                <span className={classes.fontTitle}>{endDate ? 'Event: ' + item.title + '\u00A0\u00A0\u00A0\u00A0(' + startDate + '\u00A0\u00A0' + 'to' + '\u00A0\u00A0' + endDate + ')' : 'Event: ' + item.title + '\u00A0\u00A0\u00A0\u00A0(' + startDate + ')'}</span>
                <p className={classes.font}>{item.general_description}</p>
            </div>
        : item ?
            <div className={classes.itemDiv} id={id} key={id}>
                <div className={classes.informationDiv}>
                    <table className={classes.table}>
                        {item.title ?
                            <tr>
                                <td className={classes.tableLeftCells}>
                                    <div className={classes.tagDiv}>
                                        <p className={classes.fontBold}>Title:</p>
                                    </div>
                                </td>
                                <td className={classes.tableCells}>
                                    <div className={classes.tagDiv}>
                                        <p className={classes.font}>{item.title}</p>
                                    </div>
                                </td>
                            </tr>
                            : null
                        }
                        <tr>
                            <td className={classes.tableLeftCells}>
                                <div className={classes.tagDiv}>
                                    <p className={classes.fontBold}>Description: </p>
                                </div>
                            </td>
                            <td className={classes.tableCells}>
                                <div className={classes.tagDiv}>
                                    <p className={classes.font}>{item.general_description}</p>
                                </div>
                            </td>
                        </tr>
                        {startDate ?
                            <tr>
                                <td className={classes.tableLeftCells}>
                                    <div className={classes.tagDiv}>
                                        <p className={classes.fontBold}>Date:</p>
                                    </div>
                                </td>
                                <td className={classes.tableCells}>
                                    <div className={classes.tagDiv}>
                                        {startDate
                                            ? <p className={classes.font}>{endDate ? startDate + '\u00A0\u00A0' + 'to' + '\u00A0\u00A0' + endDate : startDate}</p>
                                            : null
                                        }  
                                    </div>
                                </td>
                            </tr>
                            : null
                        }
                        {item.location ?
                            <tr>
                                <td className={classes.tableLeftCells}>
                                    <div className={classes.tagDiv}>
                                        <p className={classes.fontBold}>Location: </p>
                                    </div>
                                </td>
                                <td className={classes.tableCells}>
                                    <div className={classes.tagDiv}>
                                        <LinkDisplay name={item.location.name} id={item.location.id} to={`/zone-overview/${item.location.id}/`} />
                                    </div>
                                </td>
                            </tr>
                            : null
                        }
                        <tr>
                            <td className={classes.tableLeftCells}>
                                <div className={classes.tagDiv}>
                                    <p className={classes.fontBold}>Type: </p>
                                </div>
                            </td>
                            <td className={classes.tableCells}>
                                <div className={classes.tagDiv}>
                                    <p className={classes.font}>{item.type ? titleCase(item.type) : null}</p>
                                </div>
                            </td>
                        </tr>
                        { item.people.length > 0 ?
                            <tr>
                                <td className={classes.tableLeftCells}>
                                    <div className={classes.tagDiv}>
                                        <p className={classes.fontBold}>People: </p>
                                    </div>
                                </td>
                                <td className={classes.tableCells}>
                                    <div className={classes.tagDiv}>
                                        {item.people.map((person) => {return(<LinkDisplay name={person.first_name + ' ' + person.surname} id={person.id} to={`/person-overview/${person.id}`} />)})}
                                    </div>
                                </td>
                            </tr>
                            : null
                        }
                        { item.subject.length > 0 ?
                            <tr>
                                <td className={classes.tableLeftCells}>
                                    <div className={classes.tagDiv}>
                                        <p className={classes.fontBold}>Subjects: </p>
                                    </div>
                                </td>
                                <td className={classes.tableCells}>
                                    <div className={classes.tagDiv}>
                                        {item.subject.map((subject) => {
                                            return(
                                                <div onClick={onSubjectSet} id={subject.id} className={classes.tagDiv}>
                                                    <span id={subject.id} className={classes.fontSubject}>
                                                        {subject.subject_name}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </td>
                            </tr>
                            : null
                        }
                        { item.organizations.length > 0 ?
                            <tr>
                                <td className={classes.tableLeftCells}>
                                    <div className={classes.tagDiv}>
                                        <p className={classes.fontBold}>Organizations: </p>
                                    </div>
                                </td>
                                <td className={classes.tableCells}>
                                    <div className={classes.tagDiv}>
                                        {item.organizations.map((organization) => {return(<LinkDisplay name={organization.name} id={organization.id} to={`/org-overview/${organization.id}`} />)})}
                                    </div>
                                </td>
                            </tr>
                            : null
                        }
                        {item.collection ?
                            <tr>
                                <td className={classes.tableLeftCells}>
                                    <div className={classes.tagDiv}>
                                        <p className={classes.fontBold}>Collection: </p>
                                    </div>
                                </td>
                                <td className={classes.tableCells}>
                                    <div className={classes.tagDiv}>   
                                        <div onClick={onCollectionChange} id={item.collection.id} className={classes.tagDiv}>
                                            <Link to='/collection' id={item.collection.id} className={classes.fontSubject}>
                                                {item.collection.collection_name}
                                            </Link>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            : null
                        }
                    </table>
                </div>
            { item.type === 'video' ?
                <div key={id}>
                    <iframe className={classes.video} src={item.externalRecordURL} allowFullScreen>
                    </iframe>
                </div>
                : null
            }
            { item.type!=='video' ?
                <ImageDisplay scans={item.item_scans} togglePopup={togglePopup} />
            : null}
        </div>
        : null}
        </>
    )
}

export default ItemDisplay