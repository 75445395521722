import * as itemAddActionTypes from '../actions/itemAddActionTypes';

const initialState = {
    type: 'none',
    description: null,
    people: [],
    subjects: [],
    dateStart: null,
    dateEnd: null,
    zonename: null,
    title: null,
    collection: null,
    archivalIdentifier: null,
    copyright: null,
    creator: null,
    contributors: [],
    images: [],
    organizations: [],
    errors: null,
    pending: false,
}

const itemAddReducer = (state=initialState, action) => {
    const { type, payload } = action;

    switch(type) {
        case itemAddActionTypes.TYPE_UPDATE:
            return {
                ...state,
                type: payload,
            }
        case itemAddActionTypes.DESCRIPTION_UPDATE:
            return {
                ...state,
                description: payload,
            }
        case itemAddActionTypes.PEOPLE_UPDATE:
            return {
                ...state,
                people: payload,
            }
        case itemAddActionTypes.SUBJECTS_UPDATE:
            return {
                ...state,
                subjects: payload,
            }
        case itemAddActionTypes.DATESTART_UPDATE:
            return {
                ...state,
                dateStart: payload,
            }
        case itemAddActionTypes.DATEEND_UPDATE:
            return {
                ...state,
                dateEnd: payload,
            }
        case itemAddActionTypes.ZONENAME_UPDATE:
            return {
                ...state,
                zonename: payload,
            }
        case itemAddActionTypes.TITLE_UPDATE:
            return {
                ...state,
                title: payload,
            }
        case itemAddActionTypes.COLLECTION_UPDATE:
            return {
                ...state,
                collection: payload,
            }
        case itemAddActionTypes.ARCHIVALIDENTIFIER_UPDATE:
            return {
                ...state,
                archivalIdentifier: payload,
            }
        case itemAddActionTypes.COPYRIGHT_UPDATE:
            return {
                ...state,
                copyright: payload,
            }
        case itemAddActionTypes.CREATOR_UPDATE:
            return {
                ...state,
                creator: payload,
            }
        case itemAddActionTypes.CONTRIBUTORS_UPDATE:
            return {
                ...state,
                contributors: payload,
            }
        case itemAddActionTypes.EXTERNALRECORDURL_UPDATE:
            return {
                ...state,
                externalRecordUrl: payload,
            }
        case itemAddActionTypes.IMAGES_UPDATE:
            return {
                ...state,
                images: payload,
            }
        case itemAddActionTypes.ORGANIZATION_UPDATE:
            return {
                ...state,
                organizations: payload,
            }
        case itemAddActionTypes.CLEAR_ITEM_STATE:
            return {
                ...state,
                type: null,
                description: null,
                people: [],
                subjects: [],
                dateStart: null,
                dateEnd: null,
                zonename: null,
                title: null,
                collection: null,
                archivalIdentifier: null,
                copyright: null,
                creator: null,
                contributors: [],
                images: [],
                organizations: [],
                errors: null
            }
        case itemAddActionTypes.ERROR_UPDATE:
            return {
                ...state,
                errors: payload,
            }
        case itemAddActionTypes.CHANGE_PENDING:
            return {
                ...state,
                pending: payload,
            }
        default:
            return state
    }
}

export default itemAddReducer;