import { useState, useEffect } from 'react';

import {useSelector, useDispatch} from 'react-redux';

import {subjectsUpdate} from '../../../store/actions/itemAddActions';

import {createUseStyles} from 'react-jss';

import Tag from '../tag'
import ReactTooltip from 'react-tooltip';

const formFieldColor = "white"

const useStyles = createUseStyles({
    searchDiv: {
        position: "relative",
    },
    input: {
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: formFieldColor,
        marginTop: 3,
    },
    tagBox: {
        height: 60,
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        padding: "0.5rem",
        marginTop: 3,
        backgroundColor: formFieldColor,
        display: "flex",
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
            width: 3,
            height: 3,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: formFieldColor,
            "&:hover": {
                backgroundColor: "rgb(207, 205, 204)",
            },
            borderRadius: "10px",
        },
    },
    errorMessage: {
        color: "red",
        fontSize: 10,
    },
    searchBox: {
        position: "absolute",
        backgroundColor: "white",
        boxShadow: "0px 0px 15px 1px grey",
        borderRadius: 6,
        width: "90%",
        zIndex: 7,

    },
    searchOptions: {
        position: "relative",
        display: "block",
        transition: "all 0.5s",
        padding: 10,
        fontSize: 14,
        borderRadius: 3,
        zIndex: 7,
        "&:hover": {
            backgroundColor: "grey",
            transition: "0.5s",
        },
        cursor: "pointer",
    },
});

const ItemSubjects = ({error, refresh}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const subjects = useSelector((state) => state.itemAdd.subjects)

    const [subjectSearch, setSubjectSearch] = useState()

    const [subjectOptions, setSubjectOptions] = useState([])

    const [subjectsLoading, setSubjectsLoading] = useState(true)

    const [errorMessage, setErrorMessage] = useState()

    const domain = 'https://www.bayfieldhistoricalwebmap.ca'

    useEffect( () => {
        if (refresh === 'subjectsUpdated') {
            fetch(`${domain}/api/subjects/`)
            .then(response => response.json())
            .then( (fetchedFeatures) => {
                setSubjectOptions(fetchedFeatures)
                setSubjectsLoading(false)
            })
        }
    },[refresh])

    useEffect( () => {
        fetch(`${domain}/api/subjects/`)
        .then(response => response.json())
        .then( (fetchedFeatures) => {
            setSubjectOptions(fetchedFeatures)
            setSubjectsLoading(false)
        })

    },[])

    const onChange = (e) => {
        setSubjectSearch(e.target.value)
        console.log(subjectSearch)
        setErrorMessage(null)
        document.getElementById("subjectInput").style.backgroundColor = formFieldColor;
    }

    const onClick = (e) => {
        let prevState = subjects
        if (e.target.innerHTML) {
            const trimmedInput = e.target.innerHTML.trim();

            let duplicate = false;
            // console.log(trimmedInput, e.target.id)

            if (trimmedInput.length) {
                for (let i=0; i<subjects.length; i++){
                    if (subjects[i].id === (e.target.id)) {
                        setErrorMessage("You have already added this subject tag")
                        document.getElementById("subjectInput").style.backgroundColor = "rgb(245, 81, 66)";
                        duplicate = true
                        break;
                    }
                }
                if (!duplicate) {
                    prevState.push({name: trimmedInput, id: e.target.id})
                    dispatch(subjectsUpdate(prevState));
                    setSubjectSearch('');
                    document.getElementById("subjectInput").style.backgroundColor = "white";
                }
            }
        }
    };



    return (
        <div className={classes.searchDiv}>
            <ReactTooltip multiline={true} />
            <label htmlFor="subjectInput" data-tip="Search for subjects using the search box and add them from the suggestions that appear. Add as many subjects as are applicable.">Subject Tags</label>
            { error
                ? <div className={classes.errorMessage}>{error}</div>
                : null
            }
            <div className={classes.errorMessage}>{errorMessage}</div>
            <div className={classes.tagBox}>
                {subjects.map((subject) => <Tag id={subject.id} name={subject.name} />)}
            </div>

            <input className={classes.input} id="subjectInput" onChange={onChange} value={subjectSearch ? subjectSearch : ''} autoComplete="off" placeholder="Search subjects..." />
            {!subjectsLoading && subjectSearch && subjectSearch.length > 2
                ? <div className={classes.searchBox}> {subjectOptions.filter((subject) => {
                        if (subject.subject_name.toLowerCase().includes(subjectSearch.toLowerCase()) || subject.subject_description.toLowerCase().includes(subjectSearch.toLowerCase())) {
                            return subject;                
                        }
                        return null;
                    }).map((subject) =>
                        <span className={classes.searchOptions} onClick={onClick} id={subject.id}>{subject.subject_name}</span>
                    )} 
                </div>
                : null
            }
        </div>
    )
}

export default ItemSubjects;