import {useSelector, useDispatch} from 'react-redux';

import {copyrightUpdate} from '../../../store/actions/itemAddActions';

import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    input: {
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "white",
        marginTop: 3,
    },
});

const ItemCopyrightInfo = () => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const copyright = useSelector((state) => state.itemAdd.copyright)

    const onCopyrightChange = (e) => {
        dispatch(copyrightUpdate(e.target.value))
    }

    return (
        <>
            <label htmlFor="copyrightInfo">Copyright Info</label>
            <select id="copyrightInfo" className={classes.input} value={copyright ? copyright : ''} onChange={onCopyrightChange}>
                <option value=''> </option>
                <option>Public Domain</option>
                <option>User Responsibility</option>
            </select>
        </>
    )
}

export default ItemCopyrightInfo