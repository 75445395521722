import {useState} from 'react'

import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    tag: {
        backgroundColor: "rgb(50, 133, 168)",
        borderRadius: 8,
        border: 0,
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 4,
        fontSize: 14,
        padding: 5,
        whiteSpace: "nowrap",
        cursor: "pointer",
    },
})

const PeopleInputTag = ({id, name, removePerson}) => {

    const classes = useStyles();

    const [visible, setVisible] = useState(true)

    const onClick = (e) => {
        removePerson(e.target.id)
        setVisible(false)
    }

    return (
        <>
        { visible
            ? <span className={classes.tag} id={id} onClick={onClick}>{name}</span>
            : null
        }
        </>
    )
}

export default PeopleInputTag;