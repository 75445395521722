import * as actionTypes from './queryActionTypes';

export const timeStartChange = time => dispatch => {
    dispatch({
        type: actionTypes.TIMESTART_CHANGE,
        payload: time,
    })
}

export const timeEndChange = time => dispatch => {
    dispatch({
        type: actionTypes.TIMEEND_CHANGE,
        payload: time,
    })
}

export const peopleChange = people => dispatch => {
    dispatch({
        type: actionTypes.PEOPLE_CHANGE,
        payload: people,
    })
}
export const organizationsChange = orgs => dispatch => {
    dispatch({
        type: actionTypes.ORGANIZATIONS_CHANGE,
        payload: orgs,
    })
}
export const collectionChange = collection => dispatch => {
    dispatch({
        type: actionTypes.COLLECTION_CHANGE,
        payload: collection,
    })
}
export const typeChange = type => dispatch => {
    dispatch({
        type: actionTypes.TYPE_CHANGE,
        payload: type,
    })
}
export const subjectsChange = subjects => dispatch => {
    dispatch({
        type: actionTypes.SUBJECTS_CHANGE,
        payload: subjects,
    })
}

export const searchTermChange = searchTerm => dispatch => {
    dispatch({
        type: actionTypes.SEARCHTERM_CHANGE,
        payload: searchTerm,
    })
}