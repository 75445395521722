import {useSelector, useDispatch} from 'react-redux';

import {titleUpdate} from '../../../store/actions/itemAddActions';

import {createUseStyles} from 'react-jss';
import ReactTooltip from 'react-tooltip';

const useStyles = createUseStyles({
    input: {
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "white",
        marginTop: 3,
    },
});

const ItemTitle = () => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const title = useSelector((state) => state.itemAdd.title)

    const onTitleChange = (e) => {
        dispatch(titleUpdate(e.target.value))
    }

    return (
        <>
            <ReactTooltip multiline={true} />
            <label htmlFor="title" data-tip="Only fill this out if the item has a formal title (e.g., 'Jack Pine' for Tom Thomson's famous painting).">Title</label>
            <input id="title" className={classes.input} value={title ? title : ''} onChange={onTitleChange} autoComplete="off"></input>
        </>
    )
}

export default ItemTitle