import React, { useState, useRef, useEffect } from 'react';

import { Map, View } from 'ol'
import { defaults } from 'ol/interaction'
import VectorLayer from 'ol/layer/Vector'
import TileLayer from 'ol/layer/Tile'
import VectorSource from 'ol/source/Vector'
import { XYZ, OSM, TileArcGISRest } from 'ol/source'
import { Attribution, defaults as defaultControls } from 'ol/control';
import { Fill, Stroke, Style } from 'ol/style';
import { extend } from 'ol/extent';
import { Rotate } from 'ol/control';

import { useSelector, useDispatch } from 'react-redux';

import { businessZoneUpdate, zoneUpdate } from '../../store/actions/mapActions';

import { toggleMenu } from '../../store/actions/mapActions';

function MapWrapper({ features, loading, toggleZoneOverview }) {
    const [map, setMap] = useState()
    const [featuresLayer, setFeaturesLayer] = useState()
    const [locationNameHover, setLocationNameHover] = useState('Hover over a location')

    let mapZone = useSelector((state) => state.map.zone)
    let businessZones = useSelector(state => state.map.businessZones)

    // townPlan1835Visibility: false,
    // townPlan1835Opacity: 1,
    // surroundingsPlan1835Visibility: false,
    // surroundingsPlan1835Opacity: 1,
    // cemeteryPlanVisibility: false,
    // cemeteryPlanOpacity: 1,

    let bayfield1856MapVisibility = useSelector((state) => state.map.bayfield1856MapVisibility)
    let belden1879MapVisibility = useSelector((state) => state.map.belden1879MapVisibility)
    let huronCounty1862MapVisibility = useSelector((state) => state.map.huronCounty1862MapVisibility)
    let townPlan1835Visibility = useSelector((state) => state.map.townPlan1835Visibility)
    let surroundingsPlan1835Visibility = useSelector((state) => state.map.surroundingsPlan1835Visibility)
    let cemeteryPlanVisibility = useSelector((state) => state.map.cemeteryPlanVisibility)

    let bayfield1856MapOpacity = useSelector((state) => state.map.bayfield1856MapOpacity)
    let belden1879MapOpacity = useSelector((state) => state.map.belden1879MapOpacity)
    let huronCounty1862MapOpacity = useSelector((state) => state.map.huronCounty1862MapOpacity)
    let townPlan1835Opacity = useSelector((state) => state.map.townPlan1835Opacity)
    let surroundingsPlan1835Opacity = useSelector((state) => state.map.surroundingsPlan1835Opacity)
    let cemeteryPlanOpacity = useSelector((state) => state.map.cemeteryPlanOpacity)

    let baseMap = useSelector((state) => state.map.baseMap)

    const dispatch = useDispatch();

    const mapElement = useRef()

    const mapRef = useRef()
    mapRef.current = map

    //Initialize map
    useEffect(() => {

        //Declare Layers
        // const huronCounty1862 = new TileLayer({
        //     source: new XYZ({
        //         url: 'https://tiles.arcgis.com/tiles/9NvE8jKNWWlDGsUJ/arcgis/rest/services/Huron_heather/MapServer/tile/{z}/{y}/{x}',
        //     }),
        //     visible: false,
        // })

        const domain = 'https://www.bayfieldhistoricalwebmap.ca'

        const bayfieldPlan1879 = new TileLayer({
            source: new XYZ({
                url: `${domain}/raster/algebra/{z}/{x}/{y}.png?layers=r:0=5,g:1=5,b:2=5&alpha=0`,
                // attributions: "From the collection of the Bayfield Historical Society"
            }),
            visible: false,
        })

        const bayfieldPlan1856 = new TileLayer({
            source: new XYZ({
                url: `${domain}/raster/algebra/{z}/{x}/{y}.png?layers=r:0=4,g:1=4,b:2=4&alpha=0`,
                // attributions: "From the collection of the Bayfield Historical Society"
            }),
            visible: false,
        })

        const townPlan1835 = new TileLayer({
            source: new XYZ({
                url: `${domain}/raster/algebra/{z}/{x}/{y}.png?layers=r:0=7,g:1=7,b:2=7&alpha=0`,
                // attributions: "From the collection of the Bayfield Historical Society"
            }),
            visible: false,
        })

        const surroundingsPlan1835 = new TileLayer({
            source: new XYZ({
                url: `${domain}/raster/algebra/{z}/{x}/{y}.png?layers=r:0=6,g:1=6,b:2=6&alpha=0`,
                // attributions: "From the collection of the Bayfield Historical Society"
            }),
            visible: false,
        })

        const cemeteryPlan = new TileLayer({
            source: new XYZ({
                url: `${domain}/raster/algebra/{z}/{x}/{y}.png?layers=r:0=8,g:1=8,b:2=8&alpha=0`,
                // attributions: "From the collection of the Bayfield Historical Society"
            }),
            visible: false,
        })

        const huronCountyImagery15 = new TileLayer({
            source: new TileArcGISRest({
                url: 'https://gis.huroncounty.ca/hurongis/rest/services/Imagery/Huron_Imagery_2015/ImageServer',
                attributions: "<span style='font-size: 10px;'>Aerial imagery from Huron County GIS Services</span>"
            }),
        })

        const huronCountyImagery10 = new TileLayer({
            source: new TileArcGISRest({
                url: 'https://gis.huroncounty.ca/hurongis/rest/services/Imagery/Huron_Imagery_2010/ImageServer',
                attributions: "<span style='font-size: 10px;'>Aerial imagery from Huron County GIS Services</span>"
            }),
            visible: false,
        })

        const huronCountyImagery06 = new TileLayer({
            source: new TileArcGISRest({
                url: 'https://gis.huroncounty.ca/hurongis/rest/services/Imagery/Huron_Imagery_2006/ImageServer',
                attributions: "<span style='font-size: 10px;'>Aerial imagery from Huron County GIS Services</span>"
            }),
            visible: false,
        })


        const openStreetMap = new TileLayer({
            source: new OSM({
                attributions: "<span style='font-size: 10px;'>Basemap from OpenStreetMap</span>"
            }),
            visible: false,
        })

        const initialFeaturesLayer = new VectorLayer({
            source: new VectorSource()
        })

        var attribution = new Attribution({
            collapsible: false,
        });

        var rotate = new Rotate({
            autoHide: false,
        })

        const initialMap = new Map({
            target: mapElement.current,
            layers: [
                openStreetMap,
                huronCountyImagery15,
                huronCountyImagery10,
                huronCountyImagery06,
                initialFeaturesLayer
            ],
            view: new View({
                projection: 'EPSG:3857',
                center: [-9094456.127456, 5397978.160185],
                zoom: 15,
                minZoom: 14,
            }),
            controls: defaultControls({ attribution: false, rotate: false}).extend([attribution]).extend([rotate]),
            interactions: new defaults({
                doubleClickZoom: false,
            })
        })
        // initialMap.addLayer(huronCounty1862)
        initialMap.addLayer(bayfieldPlan1879)
        initialMap.addLayer(bayfieldPlan1856)
        initialMap.addLayer(townPlan1835)
        initialMap.addLayer(surroundingsPlan1835)
        initialMap.addLayer(cemeteryPlan)

        setMap(initialMap)
        setFeaturesLayer(initialFeaturesLayer)
    }, [])


    //Hover and click behavior
    useEffect(() => {
        let style = new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0)',
            }),
            stroke: new Stroke({
                color: 'rgba(0, 0, 0, 0)',
                width: 0.5,
            }),
            // text: new Text({
            //     font: '12px Calibri,sans-serif',
            //     fill: new Fill({
            //         color: '#000',
            //     }),
            //     stroke: new Stroke({
            //         color: '#fff',
            //         width: 3,
            //     }),
            // }),
        });

        if (features.length) {
            featuresLayer.setSource(
                new VectorSource({
                    features: features
                })
            )

            featuresLayer.setStyle(
                // function (feature) {
                //     style.getText().setText(feature.get('name'))
                //     return style
                // }
                style
            )

            featuresLayer.setZIndex(999)

            let selected = null

            var highlightStyle = new Style({
                fill: new Fill({
                    color: 'rgba(10, 166, 12, 0.3)',
                }),
                stroke: new Stroke({
                    color: 'rgba(10, 140, 12, 0.8)',
                    width: 3,
                }),
            });

            mapRef.current.on('pointermove', function (e) {
                if (selected !== null) {
                    selected.setStyle(undefined);
                    selected = null;
                }

                mapRef.current.forEachFeatureAtPixel(e.pixel, function (f) {
                    selected = f;
                    f.setStyle(highlightStyle);
                    return true;
                });

                if (selected) {
                    setLocationNameHover(selected.get('name'));
                } else {
                    setLocationNameHover('Hover over a location');
                }
            });

            mapRef.current.on('click', function (e) {
                if (selected !== null) {
                    selected.setStyle(undefined);
                    selected = null;
                }

                mapRef.current.forEachFeatureAtPixel(e.pixel, function (f) {
                    selected = f;

                    if (selected) {
                        let mapZoneSelected = selected.get('name')
                        if (mapZoneSelected !== mapZone) {
                            dispatch(zoneUpdate(mapZoneSelected));
                            dispatch(toggleMenu("Legend", false))
                            dispatch(toggleMenu("CollectionDisplay", true))
                        }
                        else if (mapZoneSelected === mapZone && mapZone != null) {
                            dispatch(zoneUpdate(null))
                            dispatch(toggleMenu("CollectionDisplay", false))
                            dispatch(toggleMenu("Legend", true))
                        }
                        if (businessZones.length > 0) {
                            console.log("ping")
                            dispatch(businessZoneUpdate([]))
                        }
                    } else {
                        return;
                    }
                })
            });
        }
    }, [features, mapZone, featuresLayer, dispatch])


    //Open zone overview if double clicked
    const [doubleClicked, setDoubleClicked] = useState(false)

    useEffect(() => {
        if (features.length) {
            let selected = null;
            if (!doubleClicked) {
                mapRef.current.on('dblclick', function(e) {
                    mapRef.current.forEachFeatureAtPixel(e.pixel, function (f) {
                        selected = f;
                        if (selected) {
                            toggleZoneOverview(selected.get('pk'))
                        }
                    })
                })
                setDoubleClicked(true)
            }
        }
    }, [features])


    //Address search
    useEffect(() => {
        const searchStyle = new Style({
            fill: new Fill({
                color: 'rgba(245, 24, 0, 0.3)',
            }),
            stroke: new Stroke({
                color: 'rgba(245, 24, 0, 0.8)',
                width: 3,
            }),
        });

        const style = new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0)',
            }),
            stroke: new Stroke({
                color: 'rgba(0, 0, 0, 0)',
                width: 0.5,
            }),
        });

        if (mapZone && map && features && features.length > 0) {
            let targetZone = features.find((zone) => zone.values_.name === mapZone)
            // console.log(targetZone);

            mapRef.current.getView().fit(targetZone.getGeometry(), { padding: [300, 300, 300, 300] })

            featuresLayer.setStyle(function (feature) {
                const name = feature.get('name');
                return name === targetZone.values_.name ? searchStyle : style;
            })
        }
    }, [mapZone, features, featuresLayer, map])

    //Highlight business locations
    useEffect(() => {
        const highlightStyle = new Style({
            fill: new Fill({
                color: 'rgba(240, 192, 60, 0.3)',
            }),
            stroke: new Stroke({
                color: 'rgba(240, 192, 60, 0.8)',
                width: 3,
            }),
        });

        const style = new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0)',
            }),
            stroke: new Stroke({
                color: 'rgba(0, 0, 0, 0)',
                width: 0.5,
            }),
        });

        if (businessZones && map && features && features.length > 0) {
            let targetZones = []
            let targetZone
            let extent = []
            for (let i=0; i<businessZones.length; i++) {
                targetZone = features.find(zone => zone.values_.pk == businessZones[i])
                console.log(i, targetZone)
                if (extent.length > 0) {
                    extent = extend(extent, targetZone.getGeometry().extent_)
                }
                else {
                    extent = targetZone.getGeometry().extent_
                }
                targetZones.push(targetZone.values_.name)
            }
            console.log(extent)

            if (targetZones.length > 0 && extent) {
                mapRef.current.getView().fit(extent, {padding: [100, 100, 100, 100]})
                // mapRef.current.getView().setZoom(15)

                featuresLayer.setStyle(function(feature) {
                    const name = feature.get('name');
                    return targetZones.includes(name) ? highlightStyle : style;
                })
            }
        }

    }, [businessZones, features, featuresLayer, map])

    //Layer toggle
    useEffect(() => {
        if (map) {
            let layersOnMap = mapRef.current.getLayers().array_
            // layersOnMap[5].setVisible(huronCounty1862MapVisibility)
            layersOnMap[5].setVisible(belden1879MapVisibility)
            layersOnMap[6].setVisible(bayfield1856MapVisibility)
            layersOnMap[7].setVisible(townPlan1835Visibility)
            layersOnMap[8].setVisible(surroundingsPlan1835Visibility)
            layersOnMap[9].setVisible(cemeteryPlanVisibility)
        }
    }, [bayfield1856MapVisibility, belden1879MapVisibility, huronCounty1862MapVisibility, map, townPlan1835Visibility, surroundingsPlan1835Visibility, cemeteryPlanVisibility])

    //Layer opacity
    useEffect(() => {
        if (map) {
            let layersOnMap = mapRef.current.getLayers().array_
            // layersOnMap[5].setOpacity(huronCounty1862MapOpacity)
            layersOnMap[5].setOpacity(belden1879MapOpacity)
            layersOnMap[6].setOpacity(bayfield1856MapOpacity)
            layersOnMap[7].setOpacity(townPlan1835Opacity)
            layersOnMap[8].setOpacity(surroundingsPlan1835Opacity)
            layersOnMap[9].setOpacity(cemeteryPlanOpacity)
        }

    }, [bayfield1856MapOpacity, belden1879MapOpacity, huronCounty1862MapOpacity, townPlan1835Opacity, surroundingsPlan1835Opacity, cemeteryPlanOpacity, map])

    //Basemap toggle
    useEffect(() => {
        if (map) {
            let layersBaseMap = mapRef.current.getLayers().array_
            if (baseMap === "OpenStreetMap") {
                layersBaseMap[0].setVisible(true)
                layersBaseMap[1].setVisible(false)
                layersBaseMap[2].setVisible(false)
                layersBaseMap[3].setVisible(false)
            }
            else if (baseMap === "Huron County Imagery - 2015") {
                layersBaseMap[0].setVisible(false)
                layersBaseMap[1].setVisible(true)
                layersBaseMap[2].setVisible(false)
                layersBaseMap[3].setVisible(false)
            }
            else if (baseMap === "Huron County Imagery - 2010") {
                layersBaseMap[0].setVisible(false)
                layersBaseMap[1].setVisible(false)
                layersBaseMap[2].setVisible(true)
                layersBaseMap[3].setVisible(false)
            }
            else if (baseMap === "Huron County Imagery - 2006") {
                layersBaseMap[0].setVisible(false)
                layersBaseMap[1].setVisible(false)
                layersBaseMap[2].setVisible(false)
                layersBaseMap[3].setVisible(true)
            }
        }
    }, [baseMap, map])

    return (
        <div>
            <div ref={mapElement} className="map-container map" id="map"></div>
            <div className="location-label">
                <p>{loading ? "Loading..." : locationNameHover}</p>
            </div>
        </div>
    )
}

export default MapWrapper