import { useState } from 'react';

import {useSelector, useDispatch} from 'react-redux';

import { dateStartUpdate, dateEndUpdate } from '../../../store/actions/itemAddActions';

import {createUseStyles} from 'react-jss';


const useStyles = createUseStyles({
    input: {
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "white",
        marginTop: 3,
    },
    errorMessage: {
        color: "red",
        fontSize: 10,
    },
    inputDiv: {
        display: "flex",
        flexDirection: "column",
        alignItems: "top",
    },
    centeredText: {
        display: "flex",
        justifyContent: "center",
        width: "90%",
    },
    checkBoxDiv: {
        display: "flex",
    },
});

const ItemDate = () => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const [range, setRange] = useState("no")
    const [completeness, setCompleteness] = useState("number")
    const [endCompleteness, setEndCompleteness] = useState("number")

    const dateStart = useSelector((state) => state.itemAdd.dateStart)
    const dateEnd = useSelector((state) => state.itemAdd.dateEnd)

    const [errorMessage, setErrorMessage] = useState()

    const onDateStartChange = (e) => {
        if (!dateEnd || parseInt(e.target.value.replace('-','').replace('-','')) < parseInt(dateEnd.replace('-','').replace('-',''))) {
            dispatch(dateStartUpdate(e.target.value))
            setErrorMessage(null)
            try{
                document.getElementById("dateStartInput").style.backgroundColor = "white";
            }
            catch{
                return null;
            }
        }
        else if (completeness === "number") {
            dispatch(dateEndUpdate(e.target.value))
            setErrorMessage("End of date range must be later than the start date.")
            
            try {        
                document.getElementById("dateStartInput").style.backgroundColor = "rgb(245, 81, 66)";
            }
            catch{
                return null;
            }
        }
        else {
            setErrorMessage("Start of date range must be earlier than the end date.")

            try {        
                document.getElementById("dateStartInput").style.backgroundColor = "rgb(245, 81, 66)";
            }
            catch{
                return null;
            }
        }
    }

    const onDateEndChange = (e) => {
        if (!dateStart || parseInt(e.target.value.replace('-','').replace('-','')) > parseInt(dateStart.replace('-','').replace('-',''))) {
            dispatch(dateEndUpdate(e.target.value))
            setErrorMessage(null)
            try{
                document.getElementById("dateEndInput").style.backgroundColor = "white";
            }
            catch{
                return null;
            }
        } 
        else if (completeness === "number") {
            dispatch(dateEndUpdate(e.target.value))
            setErrorMessage("End of date range must be later than the start date.")
            try {        
                document.getElementById("dateEndInput").style.backgroundColor = "rgb(245, 81, 66)";
            }
            catch{
                return null;
            }
            
        }
        else {
            setErrorMessage("End of date range must be later than the start date.")
            try {        
                document.getElementById("dateEndInput").style.backgroundColor = "rgb(245, 81, 66)";
            }
            catch{
                return null;
            }
            
        }
    }

    const toggleRange = (e) => {
        setRange(e.target.value)
        if (e.target.value === "no") {
            dispatch(dateEndUpdate(null))
        }
    }

    const toggleDateCompleteness = (e) => {
        dispatch(dateStartUpdate(null))
        setCompleteness(e.target.value)
    }

    const toggleEndDateCompleteness = e => {
        dispatch(dateEndUpdate(null))
        setEndCompleteness(e.target.value)
    }

    return(
        <div className={classes.inputDiv}>
            <label htmlFor="rangeToggler">Date Range</label>
            {/* <input id="rangeToggler" type="checkbox" onClick={toggleRange}></input> */}
            <select className={classes.input} id="rangeToggler" onClick={toggleRange}>
                <option value="no">No</option>
                <option value="yes">Yes</option>
            </select>
            <br />
            {range === "yes" ?
                <>
                    <label htmlFor="dateType">Start Date Specificity</label>
                    <select className={classes.input} id="dateType" onClick={toggleDateCompleteness}>
                        <option value="number">Year</option>
                        <option value="month">Month</option>
                        <option value="date">Date</option>
                    </select>
                    <br />
                    <label htmlFor="dateType">End Date Specificity</label>
                    <select className={classes.input} id="dateType" onClick={toggleEndDateCompleteness}>
                        <option value="number">Year</option>
                        <option value="month">Month</option>
                        <option value="date">Date</option>
                    </select>
                    <br />
                </>
                : <>
                    <label htmlFor="dateType">Date Specificity</label>
                    <select className={classes.input} id="dateType" onClick={toggleDateCompleteness}>
                        <option value="number">Year</option>
                        <option value="month">Month</option>
                        <option value="date">Date</option>
                    </select>
                    <br />
                </>
            }
            <span>Date</span>
            <div className={classes.errorMessage}>{errorMessage}</div>
            {range==="no"
                ?
                    <input className={classes.input} type={completeness} placeholder="Year..." value={dateStart ? dateStart : ''} onChange={onDateStartChange}></input>
                :
                    <>
                        <input id="dateStartInput" className={classes.input} placeholder="Start year..." type={completeness} value={dateStart ? dateStart : ''} onChange={onDateStartChange}></input>
                        <span className={classes.centeredText}>to</span>
                        <input id="dateEndInput" className={classes.input} placeholder="End year..." type={endCompleteness} value={dateEnd ? dateEnd : ''} onChange={onDateEndChange}></input>
                    </>
            }
        </div>
    )
}

export default ItemDate