import * as queryActionTypes from '../actions/queryActionTypes';

const currentYearString = new Date().getFullYear().toString() + '0000'

const currentYearInt = parseInt(currentYearString)

const initialState = {
    timeStart: 18300000,
    timeEnd: currentYearInt,
    people: [],
    organizations: [],
    collection: '',
    type: '',
    subjects: [],
    searchTerm: '',
}

const queryReducer = (state=initialState, action) => {
    const { type, payload } = action;

    switch(type) {
        case queryActionTypes.TIMESTART_CHANGE:
            return {
                ...state,
                timeStart: payload,
            }
        case queryActionTypes.TIMEEND_CHANGE:
            return {
                ...state,
                timeEnd: payload,
            }
        case queryActionTypes.PEOPLE_CHANGE:
            return {
                ...state,
                people: payload,
            }
        case queryActionTypes.ORGANIZATIONS_CHANGE:
            return {
                ...state,
                organizations: payload,
            }
        case queryActionTypes.COLLECTION_CHANGE:
            return {
                ...state,
                collection: payload,
            }
        case queryActionTypes.TYPE_CHANGE:
            return {
                ...state,
                type: payload,
            }
        case queryActionTypes.SUBJECTS_CHANGE:
            return {
                ...state,
                subjects: payload,
            }
        case queryActionTypes.SEARCHTERM_CHANGE:
            return {
                ...state,
                searchTerm: payload,
            }
        default:
            return state
    }
}

export default queryReducer;