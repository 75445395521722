import * as actionTypes from './authActionTypes';
// import axiosInstance from '../../axios';
import axios from 'axios'

const domain = 'https://www.bayfieldhistoricalwebmap.ca'

export const checkAuthenticated = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }; 

        const body = JSON.stringify({ token: localStorage.getItem('access') });

        axios.post(`${domain}/auth/jwt/verify/`, body, config)
            .then(res => {
                console.log(res.data)
                if (res.data.code !== 'token_not_valid') {
                    dispatch({
                        type: actionTypes.AUTHENTICATED_SUCCESS
                    });

                    dispatch(load_user());
                }
            })
            .catch(() => {
                const body = JSON.stringify({ 'refresh': localStorage.getItem('refresh') })

                axios.post(`${domain}/auth/jwt/refresh/`, body, config)
                    .then(response => {
                        localStorage.setItem('access', response.data.access);
                        // localStorage.setItem('refresh', response.data.refresh);

                        const config_1 = {
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json',
                                'Authorization': axios.defaults.headers['Authorization'] =
                                    'JWT ' + response.data.access,
                            }
                        };

                        const body_1 = JSON.stringify({ 'token': response.data.access });

                        axios.post(`${domain}/auth/jwt/verify/`, body_1, config_1)
                            .then(res => {
                                if (res.status === 200) {
                                    dispatch({
                                        type: actionTypes.AUTHENTICATED_SUCCESS
                                    });

                                    dispatch(load_user());
                                }
                                else {
                                    dispatch({
                                        type: actionTypes.AUTHENTICATED_FAIL
                                    });
                                }
                            }).catch(err => {
                                console.log(err);
                                dispatch({
                                    type: actionTypes.AUTHENTICATED_FAIL
                                });
                            })
                        })
                    .catch(err => {
                        console.log(err);
                        dispatch({
                            type: actionTypes.AUTHENTICATED_FAIL
                        });
                    });
            })

    } else {
        dispatch({
            type: actionTypes.AUTHENTICATED_FAIL
        });
    }
}


export const load_user = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json',
            }
        };

        try {
            const res = await axios.get(`${domain}/auth/users/me/`, config)

            dispatch ({
                type: actionTypes.USER_LOADED_SUCCESS,
                payload: res.data,
            })
        } catch {
            dispatch({
                type: actionTypes.USER_LOADED_FAIL,
            })
        }
    } else {
        dispatch({
            type: actionTypes.USER_LOADED_FAIL,
        })
    }
};

export const login = (email, password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ email, password })

    try {
        const res = await axios.post(`${domain}/auth/jwt/create/`, body, config);

        dispatch ({
            type: actionTypes.LOGIN_SUCCESS,
            payload: res.data,
        });

        dispatch(load_user());
    } catch {
        dispatch({
            type: actionTypes.LOGIN_FAIL,
        })
    }
};

export const register = (email, first_name, last_name, password, re_password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ email, first_name, last_name, password, re_password });

    axios.post(`${domain}/auth/users/`, body, config)
        .then(res => {
            dispatch ({
                type: actionTypes.REGISTER_SUCCESS,
                payload: res.data,
            });
            return true;
        })
        .catch(err => {
            console.log(err.response.data)
            dispatch({
                type: actionTypes.REGISTER_FAIL,
                payload: err.response.data,
            })
            return false;
        })
}

export const setRegisteredSuccess = (pending) => async dispatch => {
    dispatch({
        type: actionTypes.SET_PENDING,
        payload: pending,
    })
}

export const verify = (uid, token) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ uid, token });

    try {
        await axios.post(`${domain}/auth/users/activation/`, body, config);

        dispatch ({
            type: actionTypes.ACTIVATION_SUCCESS,
        })
    } catch {
        dispatch({
            type: actionTypes.ACTIVATION_FAIL,
        })
    }

}

export const reset_password = (email) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({email});

    try {
        await axios.post(`${domain}/auth/users/reset_password/`, body, config);

        dispatch({
            type: actionTypes.PASSWORD_RESET_SUCCESS
        })
    } catch (err) {
        dispatch({
            type: actionTypes.PASSWORD_RESET_FAIL
        })
    }
}

export const reset_password_confirm = (uid, token, new_password, re_new_password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ uid, token, new_password, re_new_password });

    // console.log(body)

    try {
        await axios.post(`${domain}/auth/users/reset_password_confirm/`, body, config);

        dispatch({
            type: actionTypes.PASSWORD_RESET_CONFIRM_SUCCESS
        })
    } catch (err) {
        dispatch({
            type: actionTypes.PASSWORD_RESET_CONFIRM_FAIL
        })
    } 
};


export const logout = () => dispatch => {
    dispatch({
        type: actionTypes.LOGOUT
    })
}