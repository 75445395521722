import { useState } from 'react';

import {createUseStyles} from 'react-jss';


const useStyles = createUseStyles({
    input: {
        width: '90%',
        boxSizing: 'border-box',
        borderRadius: 8,
        height: 40,
        border: 0,
        padding: "0.5rem",
        backgroundColor: "whitesmoke",
        marginTop: 3,
    },
    errorMessage: {
        color: "red",
        fontSize: 10,
    },
    inputDiv: {
        display: "flex",
        flexDirection: "column",
        alignItems: "top",
    },
});

const DateInput = ({includeRange, getStartDate, getEndDate, startDateLabel, endDateLabel}) => {

    const classes = useStyles();

    const [range, setRange] = useState(includeRange ? false : true)
    const [completeness, setCompleteness] = useState("number")

    const [dateStart, setDateStart] = useState()
    const [dateEnd, setDateEnd] = useState()

    const [errorMessage, setErrorMessage] = useState()

    const dateStartSet = (dateStart) => {
        setDateStart(dateStart)
        getStartDate(dateStart)
    }

    const dateEndSet = (dateEnd) => {
        setDateEnd(dateEnd)
        getEndDate(dateEnd)
    }

    const onDateStartChange = (e) => {
        if (!dateEnd || parseInt(e.target.value.replace('-','').replace('-','')) < parseInt(dateEnd.replace('-','').replace('-',''))) {
            dateStartSet(e.target.value)
            setErrorMessage(null)
            try{
                document.getElementById("dateStartInput").style.backgroundColor = "whitesmoke";
            }
            catch{
                return null;
            }
        }
        else if (completeness === "number") {
            dateStartSet(e.target.value)
            setErrorMessage("End of date range must be later than the start date.")
            
            try {        
                document.getElementById("dateStartInput").style.backgroundColor = "rgb(245, 81, 66)";
            }
            catch{
                return null;
            }
        }
        else {
            setErrorMessage("Start of date range must be earlier than the end date.")

            try {        
                document.getElementById("dateStartInput").style.backgroundColor = "rgb(245, 81, 66)";
            }
            catch{
                return null;
            }
        }
    }

    const onDateEndChange = (e) => {
        if (!dateStart || parseInt(e.target.value.replace('-','').replace('-','')) > parseInt(dateStart.replace('-','').replace('-',''))) {
            dateEndSet(e.target.value)
            setErrorMessage(null)
            try{
                document.getElementById("dateEndInput").style.backgroundColor = "whitesmoke";
            }
            catch{
                return null;
            }
        } 
        else if (completeness === "number") {
            dateEndSet(e.target.value)
            setErrorMessage("End of date range must be later than the start date.")
            try {        
                document.getElementById("dateEndInput").style.backgroundColor = "rgb(245, 81, 66)";
            }
            catch{
                return null;
            }
            
        }
        else {
            setErrorMessage("End of date range must be later than the start date.")
            try {        
                document.getElementById("dateEndInput").style.backgroundColor = "rgb(245, 81, 66)";
            }
            catch{
                return null;
            }
            
        }
    }

    const toggleRange = () => {
        if (range === true && includeRange === true) {
            dateEndSet(null)
        }
        setRange(!range)
    }

    const toggleDateCompleteness = (e) => {
        dateEndSet(null)
        dateEndSet(null)
        setCompleteness(e.target.value)
    }

    return(
        <div className={classes.inputDiv}>
            <label for="dateType">Date Type</label>
            <select className={classes.input} id="dateType" onClick={toggleDateCompleteness}>
                <option value="number">Year</option>
                <option value="month">Month</option>
                <option value="date">Date</option>
            </select>
            { includeRange
                ? <>
                    <label for="rangeToggler">Date Range</label>
                    <input id="rangeToggler" type="checkbox" onClick={toggleRange}></input>
                </>
                : null
            }
            
            <div className={classes.errorMessage}>{errorMessage}</div>
            {range===false
                ?
                    <>
                        <label for="dateStartInput">{startDateLabel}</label>
                        <input id="dateStartInput" className={classes.input} type={completeness} value={dateStart} onChange={onDateStartChange}></input>
                    </>
                :
                    <>
                        <label for="dateStartInput">{startDateLabel}</label>
                        <input id="dateStartInput" className={classes.input} type={completeness} value={dateStart} onChange={onDateStartChange}></input>
                        <label for="dateEndInput">{endDateLabel}</label>
                        <input id="dateEndInput" className={classes.input} type={completeness} value={dateEnd} onChange={onDateEndChange}></input>
                    </>
            }
        </div>
    )
}

export default DateInput